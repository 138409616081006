import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { updateBankingStatus } from "../../../../api/services";
import DrawerButtonAction from "../../../../components/buttons/DrawerButtonAction";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import Status from "../../../../components/status/Status";
import { stripHTML } from "../../../../utils/utils";
import BankDetails from "./BankDetails";
import BankingForm from "./BankingForm";

const BankingInformation = ({ data, status, reqDocIds }: any) => {
  const mainData = data?.data;
  const bankingInformation = data?.data?.bankingInformation;
  const entityStatus = data?.data?.entityStatus;
  const queryClient = useQueryClient();
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showReasonInput, setShowReasonInput] = useState(false);

  const { mutate } = useMutation(
    ({ id, body }: { id: string; body: any }) => updateBankingStatus(id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("entity");
      },
      onError: (error) => {
        console.error("Failed to update status:", error);
      },
    }
  );

  // Open modal and configure for accept or reject
  const handleOpenModal = (bankData: any, actionType: "Accept" | "Reject") => {
    setSelectedBankId(bankData.id);
    setModalText(
      actionType === "Accept"
        ? "Please confirm to accept the banking information"
        : "Please provide the reason to reject the banking information"
    );
    setModalTitle(
      actionType === "Accept" ? "Confirm Acceptance" : "Confirm Rejection"
    );
    setShowReasonInput(actionType === "Reject");
    setModalOpen(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedBankId(null);
  };

  // Handle confirmation
  const handleConfirm = (status: string, reason?: string) => {
    if (selectedBankId) {
      const body = {
        status,
        reason: reason || "",
      };
      mutate({ id: selectedBankId, body });
    }
  };

  return (
    <Box sx={{ marginLeft: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h4">Banking Information</Typography>

        <DrawerButtonAction
          subtype={`Add Banking Information`}
          title={`Add Banking Information`}
          anchor={({ open }) => (
            <Button onClick={open} variant="contained">
              Add Banking Details
            </Button>
          )}
          content={({ close }) => (
            <BankingForm close={close} data={data} formType="add" />
          )}
        />
      </Box>

      {bankingInformation?.map((bankData: any, index: number) => (
        <Box key={index} sx={{ marginBottom: "20px" }}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography
              variant="h6"
              sx={{ marginBottom: "10px" }}
            >{`Bank Account ${index + 1}`}</Typography>
            <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
              {entityStatus !== "Kyc Submitted" &&
                (bankData.status === "In Progress" ? (
                  <Status variant="PENDING" />
                ) : bankData.status === "Verified" ? (
                  <Status variant="VERIFIED" />
                ) : bankData.status === "Rejected" ? (
                  <Status
                    variant="REJECTED"
                    showTooltip={true}
                    tooltipText={stripHTML(bankData.reason)}
                  />
                ) : null)}
              <DrawerButtonAction
                subtype={`Edit Banking Information`}
                title={`Edit Banking Information`}
                anchor={({ open }) => (
                  <Tooltip title="Edit Bank">
                    <IconButton aria-label="edit" onClick={open}>
                      <EditNoteIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                )}
                content={({ close }) => (
                  <BankingForm
                    close={close}
                    data={data}
                    formType="edit"
                    bankingData={bankData}
                  />
                )}
              />
            </Stack>
          </Stack>

          <BankDetails
            bankData={bankData}
            mainData={mainData}
            status={status}
            reqDocIds={reqDocIds}
          />

          {bankData.status === "In Progress" && (
            <Stack direction={"row"} justifyContent={"flex-end"} gap={"5px"}>
              <Button
                variant="outlined"
                sx={{
                  width: "100px",
                  color: "#FF5722",
                  borderColor: "#FF57228A",
                  "&:hover": {
                    backgroundColor: "#FF57221A",
                    color: "#FF5722",
                    borderColor: "#FF57228A",
                  },
                }}
                onClick={() => handleOpenModal(bankData, "Reject")}
              >
                Reject
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: "100px",
                  color: "#8BC34A",
                  borderColor: "#8BC34A8A",
                  "&:hover": {
                    backgroundColor: "#8BC34A1A",
                    color: "#8BC34A",
                    borderColor: "#8BC34A8A",
                  },
                }}
                onClick={() => handleOpenModal(bankData, "Accept")}
              >
                Accept
              </Button>
            </Stack>
          )}
          {index !== bankingInformation.length - 1 && (
            <Divider sx={{ marginY: 2 }} />
          )}
        </Box>
      ))}

      {/* Modal for confirmation */}
      <ConfirmationModal
        open={modalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        title={modalTitle}
        text={modalText}
        showReasonInput={showReasonInput}
      />
    </Box>
  );
};

export default BankingInformation;
