import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SignaturePending({ data, secondUser }: any) {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleOpen2 = () => setOpen2(true);

  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);

  const navigate = useNavigate();

  function ViewDocument (){
    navigate(`/deals/${data?.code}/documentation` );
  }

  return (
    <Box sx={{ px: "20px", py: "20px" }}>
      <Stack gap={"20px"} direction={"column"}>
      <Typography variant="h2" sx={{ fontWeight: "500" }}>
      Document Published
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          The {secondUser?.userType} has signed the deal document, and we’re now waiting for the remaining signees to complete the signing process.
           Click the “Notify Signees” button to send a reminder to the outstanding signees.
        </Typography>
      </Stack>
    

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          textAlign: "left",
          gap: "20px",
          mt: 2,
        }}
      >
        
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen2}
        >
            <Typography variant="h5" sx={{color: "white"}}       onClick={ViewDocument} >
                View Document 
            </Typography>
        </Button>
      </Box>
    </Box>
  );
}
