import { Box, FormControlLabel, Typography } from "@mui/material";
import BpCheckbox from "../../../components/customInput/CustomCheckbox";

interface Document {
  id: string;
  subtype: string;
}

interface BankData {
  id: string;
  name: string;
  beneficiaryName: string;
  relatedDocs: Document[];
}

interface Director {
  id: string;
  name: string;
  documents: Document[];
}

interface EntityResponse {
  data: {
    firmDocuments: Document[];
    bankingInformation: BankData[];
    directors: Director[];
  };
}

interface DocumentCheckListProps {
  label: string;
  documents: Document[];
  onParentChange: (relatedDocs: Document[]) => void;
  onChildChange: (docId: string, checked: boolean) => void;
  selectedDocs: string[];
}

const DocumentCheckList = ({
  label,
  documents,
  onParentChange,
  onChildChange,
  selectedDocs,
}: DocumentCheckListProps) => {
  const areAllChildrenSelected = documents.every((doc) =>
    selectedDocs.includes(doc.id)
  );
  const isSomeChildrenSelected = documents.some((doc) =>
    selectedDocs.includes(doc.id)
  );

  return (
    <Box ml={3} mb={2}>
      <FormControlLabel
        // label={label}
        label={<Typography variant="body1">{label}</Typography>}
        control={
          <BpCheckbox
            checked={areAllChildrenSelected}
            indeterminate={isSomeChildrenSelected && !areAllChildrenSelected}
            onChange={(e) => onParentChange(documents)}
          />
        }
      />
      <Box
        sx={{ display: "flex", flexDirection: "row", ml: 3, flexWrap: "wrap" }}
      >
        {documents.map((doc) => (
          <FormControlLabel
            key={doc.id}
            // label={doc.subtype}
            label={<Typography variant="caption">{doc.subtype}</Typography>}
            control={
              <BpCheckbox
                checked={selectedDocs.includes(doc.id)}
                onChange={(e) => onChildChange(doc.id, e.target.checked)}
              />
            }
          />
        ))}
      </Box>
    </Box>
  );
};

export default DocumentCheckList;
