import React, { useEffect, useState } from "react";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DrawerButtonAction from "../../../../components/buttons/DrawerButtonAction";
import SignatoryAuthorityForm from "./SignatoryAuthorityForm";
import SignatureAuthority from "./SignatoryAuthority";

interface SignatoriesProps {
  mainData: any;
  signatoryDirectors: any[];
}
interface Director {
  din_number: string;
  director_name: string;
  isnew: boolean;
  id?: any;
}
const Signatories: React.FC<SignatoriesProps> = ({
  mainData,
  signatoryDirectors,
}) => {
  const [Directors, setDirectors] = useState<Director[]>([]);
  const [isdataSaved, setisDataSaved] = useState(true);
  const EntityId = mainData?.data?.id;
  const EntityType = mainData?.data?.companyType;
  function reorderDirectorsData(directorsData: any) {
    const nullEmailVerified: any = [];
    const nonNullEmailVerified: any = [];

    directorsData.forEach((obj: any) => {
      if (obj.isEmailVerified == null || obj.isEmailVerified === undefined) {
        nullEmailVerified.push(obj);
      } else {
        nonNullEmailVerified.push(obj);
      }
    });

    return nonNullEmailVerified.concat(nullEmailVerified);
  }

  function checkArrayForNull(array: any) {
    for (let i = 0; i < array.length; i++) {
      if (array[i] === null) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (signatoryDirectors) {
      const directorsData = signatoryDirectors?.map((director: any) => {
        const dataArray = [
          director?.name,
          director?.id,
          director?.aadharNumber,
          director?.panNumber,
          director?.address,
          director?.isAadharMismatch,
          director?.isPanMisMatch,
          director?.designation,
          director?.mobileNumber,
          director?.emailAddress,
          director?.isMobileVerified,
          director?.isEmailVerified,
          director?.isNewDirector,
        ];

        return {
          din_number: director?.dpin || "",
          director_name: director?.name || "",
          id: director?.id || "",
          aadharNumber: director?.aadharNumber || "",
          panNumber: director?.panNumber,
          address: director?.address,
          dateOfBirth: director?.dateOfBirth,
          gender: director?.gender,
          isAadharMismatch: director?.isAadharMismatch,
          isPanMisMatch: director?.isPanMisMatch,
          isSignatoryAuthority: director?.isSignatoryAuthority,
          designation: director?.designation,
          mobileNumber: director?.mobileNumber,
          emailAddress: director?.emailAddress,
          isMobileVerified: director?.isMobileVerified,
          isEmailVerified: director?.isEmailVerified,
          isnew: director?.isNewDirector,
          isDataSaved: checkArrayForNull(dataArray),
        };
      });
      setDirectors(reorderDirectorsData(directorsData));
    }
    const areAllVerified = signatoryDirectors?.every(
      (director: any) => director.isEmailVerified && director.isMobileVerified
    );
    setisDataSaved(!areAllVerified);
  }, [signatoryDirectors]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 2,
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: "20px" }}>
          Signatory Authorities Information
        </Typography>
        <DrawerButtonAction
          subtype={`Add Signatory Authority`}
          title={`Add Signatory Authority`}
          contentStyles={{
            paddingTop: "0px",
          }}
          anchor={({ open }) => (
            <Button onClick={open} variant="contained">
              Add Signatory Authority
            </Button>
          )}
          content={({ close }) => (
            <>
              <SignatoryAuthorityForm
                key={Directors.length - 1}
                entityType={EntityType}
                entityId={EntityId}
                isnew={true}
                id={Directors[Directors.length - 1]?.id}
                Dname={""}
                Dpin={""}
                aadharNumber={""}
                panNumber={""}
                address={""}
                Signatory={false}
                index={Directors.length}
                directorData={""}
                isAadharMismatch={false}
                isPanMisMatch={false}
                mobileNumber={""}
                emailAddress={""}
                isMobileVerified={false}
                isEmailVerified={false}
                designation={""}
                isDataSaved={false}
                SignatoryAuthoritydata={signatoryDirectors || []}
                close={close}
              />
            </>
          )}
        />
      </Box>
      {signatoryDirectors?.map((director, index) => (
        <React.Fragment key={index}>
          <SignatureAuthority
            data={director}
            entityStatus = {mainData?.data?.entityStatus}
            currentDirectorCount={index + 1}
          />
          {index !== signatoryDirectors.length - 1 && (
            <Divider sx={{ marginY: 2 }} />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default Signatories;
