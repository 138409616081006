import {
  Box,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Switch,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  marginLeft: 4,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

interface CustomSwitchProps {
  value: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  name: string;
  id: string;
  label: string;
}

const CustomSwitchComponent: React.FC<CustomSwitchProps> = ({
  value,
  onChange,
  name,
  id,
  label,
}) => (
  <Box my={"14px"}>
    <InputLabel
      shrink
      htmlFor={id}
      sx={{
        fontSize: "0px",
        fontWeight: 300,
        "&.Mui-focused": {
          color: "#00000099",
        },
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontSize: "16px",
          // my: "0.5vh",
          mt: "1vh",
        }}
      >
        {label}
        <span style={{ color: "red", marginLeft: "2px" }}>*</span>
      </Typography>
    </InputLabel>
    <FormGroup
      sx={{
        mt: "-10px",
        transform: "scale(1.2)",
        marginLeft: "1%",
        width: "fit-content",
      }}
    >
      <FormControlLabel
        control={
          <CustomSwitch
            checked={value}
            onChange={onChange}
            name={name}
            id={id}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label=""
      />
    </FormGroup>
  </Box>
);

export default CustomSwitchComponent;
