import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import HowtoAddfundsmodel from "./HowtoAddfundsmodel";

export default function EscrowAccountDetails({ data, profiledata }: any) {
  const [openModel, setOpenModel] = useState(false); // Corrected typo: setOpenModel
  const isUserBuyer =
    data?.dealUsers?.filter(
      (user: any) => user?.userId === profiledata?.data?.id
    )[0]?.userType === "Buyer";
  const navigate = useNavigate();

  const handleOpenModel = () => {
    setOpenModel(true);
  };

  return (
    <Box p={3}>
      <Typography variant="h2" sx={{ fontWeight: "500" }}>
        Truupe Account Details
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row", mt: 2, gap: "50px" }}>
        <Grid>
          <Typography variant="subtitle1" sx={{ color: "grey" }}>
            Account Number
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "5px" }}>
            {data?.bankingDetails?.accountNumber || "Not Registered"}
          </Typography>
        </Grid>

        <Grid sx={{ ml: 5 }}>
          <Typography variant="subtitle1" sx={{ color: "grey" }}>
            IFSC Code
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "5px" }}>
            {data?.bankingDetails?.ifscCode || "Not Registered"}
          </Typography>
        </Grid>
        {/* {isUserBuyer && (
          <Button
            sx={{ marginTop: "1.5vh" }}
            onClick={handleOpenModel} // Corrected function name
            variant="contained"
          >
            How To Add Funds
          </Button>
        )} */}
      </Box>
      {/* <HowtoAddfundsmodel openModel={openModel} setOpenModel={setOpenModel} data={data} /> */}
    </Box>
  );
}
