import Cookies from "js-cookie";
import { createBrowserRouter, Navigate } from "react-router-dom";
import AdminUsers from "../pages/adminUsers";
import AddCoupons from "../pages/coupons/AddCoupons";
import Coupons from "../pages/coupons/Coupons";
import ViewCoupon from "../pages/coupons/ViewCoupon";
import EscrowTransactions from "../pages/deals/deal-details/deal-amount/EscrowTransactions";
import ManageFunds from "../pages/deals/deal-details/manage-funds/ManageFunds";
import RequestReceived from "../pages/deals/deal-details/payment-requests/RequestReceived";
import Esign from "../pages/esign/Esign";
import {
  Dashboard,
  DealDetails,
  DealDocumentation,
  DealDocumentationSignee,
  Deals,
  Entities,
  EntityDetails,
  EntitySuccess,
  Layout,
  Login,
  NotFound,
  ReApproval,
  RejectionModal,
  Test,
  TransactionsDetails,
  Trustees,
  Users,
} from "../pages/exports/Exports";
import UserReport from "../pages/reports/UserReport";
import Transactions from "../pages/transactions/Transactions";

const isAuthenticated = () => {
  const accessToken = Cookies.get("access_token");
  return accessToken ? true : false;
};

const isAdmin = () => {
  return Cookies.get("isAdmin") === "true";
};

const Role = localStorage.getItem("Role");

const ProtectedRoute = ({ component, componentType }: any) => {
  const isAuth = isAuthenticated();
  const userIsAdmin = isAdmin();

  const adminRoutes = [
    "admin_dashboard",
    "admin_deals",
    "admin_entities",
    "admin_trustees",
    "admin_users",
    "admin_entity_success",
    "admin_entity_reapproval",
    "admin_entity_details",
    "admin_deal_details",
    "admin_deal_documentation",
    "admin_deal_documentation_signee",
    "admin_pay_service",
    "admin_deals_escrow_account",
    "admin_deals_manage_funds",
    "admin_requestReceived",
    "admin_adminUsers",
    "admin_reports",
  ];

  const trusteeRoutes = [
    "trustee_dashboard",
    "trustee_deals",
    "trustee_deal_details",
    "trustee_deal_documentation",
    "trustee_deal_documentation_signee",
    "trustee_transactions",
    "trustee_transaction_details",
    "trustee_requestReceived",
  ];

  if (isAuth && Role !== "User") {
    if (componentType === "login") {
      return <Navigate to={userIsAdmin ? "/dashboard" : "/deals"} replace />;
    }

    if (userIsAdmin && trusteeRoutes.includes(componentType)) {
      return <Navigate to={userIsAdmin ? "/dashboard" : "/deals"} replace />;
    }

    if (!userIsAdmin && adminRoutes.includes(componentType)) {
      if (componentType === "admin_deals_escrow_account") {
        return component;
      } else {
        return <Navigate to={userIsAdmin ? "/dashboard" : "/deals"} replace />;
      }
    }
  } else {
    Cookies.remove("access_token");
    localStorage.clear();
    if (componentType === "login") {
      return component;
    } else {
      return <Navigate to="/login" replace />;
    }
  }

  return component;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/login" replace />,
    errorElement: <NotFound />,
  },
  {
    path: "/login",
    element: <ProtectedRoute componentType={"login"} component={<Login />} />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: "/dashboard",
        element: (
          <ProtectedRoute
            componentType={"dashboard"}
            component={<Dashboard />}
          />
        ),
      },
      {
        path: "/deals",
        element: (
          <ProtectedRoute componentType={"deals"} component={<Deals />} />
        ),
      },
      {
        path: "/coupons",
        element: (
          <ProtectedRoute componentType={"coupons"} component={<Coupons />} />
        ),
      },
      {
        path: "/coupons/create",
        element: (
          <ProtectedRoute componentType={"add"} component={<AddCoupons />} />
        ),
      },
      {
        path: "/coupons/edit/:couponId",
        element: (
          <ProtectedRoute componentType={"edit"} component={<AddCoupons />} />
        ),
      },
      {
        path: "/coupons/view/:couponId",
        element: (
          <ProtectedRoute componentType={"view"} component={<ViewCoupon />} />
        ),
      },
      {
        path: "/transactions",
        element: (
          <ProtectedRoute
            componentType={"trustee_transactions"}
            component={<Transactions />}
          />
        ),
      },
      {
        path: "/transactions/:ledgerid",
        element: (
          <ProtectedRoute
            componentType={"trustee_transaction_details"}
            component={<TransactionsDetails />}
          />
        ),
      },
      {
        path: "/entities",
        element: (
          <ProtectedRoute
            componentType={"admin_entities"}
            component={<Entities />}
          />
        ),
      },
      {
        path: "/entities/:id/rejection",
        element: (
          <ProtectedRoute
            componentType={"admin_entities"}
            component={<RejectionModal />}
          />
        ),
      },
      {
        path: "/trustees",
        element: (
          <ProtectedRoute
            componentType={"admin_trustees"}
            component={<Trustees />}
          />
        ),
      },
      {
        path: "/admins",
        element: (
          <ProtectedRoute
            componentType={"admin_adminUsers"}
            component={<AdminUsers />}
          />
        ),
      },
      {
        path: "/reports",
        element: (
          <ProtectedRoute
            componentType={"admin_reports"}
            component={<UserReport />}
          />
        ),
      },
      {
        path: "/users",
        element: (
          <ProtectedRoute componentType={"admin_users"} component={<Users />} />
        ),
      },
      {
        path: "/entities/entity-success/:id",
        element: (
          <ProtectedRoute
            componentType={"admin_entity_success"}
            component={<EntitySuccess />}
          />
        ),
      },
      {
        path: "/entities/re-approval/:id",
        element: (
          <ProtectedRoute
            componentType={"admin_entity_reapproval"}
            component={<ReApproval />}
          />
        ),
      },
      {
        path: "/entities/:id",

        element: (
          <ProtectedRoute
            componentType={"admin_entity_details"}
            component={<EntityDetails />}
          />
        ),
      },
      {
        path: "/deals/:code",
        element: (
          <ProtectedRoute
            componentType={"deal_details"}
            component={<DealDetails />}
          />
        ),
      },
      {
        path: "/deals/:code/documentation",
        element: (
          <ProtectedRoute
            componentType={"deal_documentation"}
            component={<DealDocumentation />}
          />
        ),
      },
      {
        path: "/deals/:code/documentationSignee",
        element: (
          // <RestrictDealDocumentSignature>
          <ProtectedRoute
            componentType={"deal_documentation_signee"}
            component={<DealDocumentationSignee />}
          />
          // </RestrictDealDocumentSignature>
        ),
      },
      {
        path: "/deals/:code/truupe-account",
        element: (
          // <RestrictDealEscrowAccount>
          <ProtectedRoute
            componentType={"admin_deals_escrow_account"}
            component={<EscrowTransactions />}
          />
          // </RestrictDealEscrowAccount>
        ),
      },
      {
        path: "/deals/:code/manage-funds",
        element: (
          // <RestrictDealEscrowAccount>
          <ProtectedRoute
            componentType={"admin_deals_manage_funds"}
            component={<ManageFunds />}
          />
          // </RestrictDealEscrowAccount>
        ),
      },
      {
        path: "deals/:dealId/request-received",
        element: (
          // <RestrictRequestReceived>
          <ProtectedRoute
            componentType={"requestReceived"}
            component={<RequestReceived />}
          />
          // </RestrictRequestReceived>
        ),
      },
    ],
  },
  {
    path: "/test",
    element: <Test />,
  },
  {
    path: "/esign-success",
    element: <Esign />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
