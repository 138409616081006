import { Box, Button, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { approveRejectTransaction } from "../../api/services";
import RejectionModal from "./RejectModal";
import TransactionSuccess from "./TransactionSuccess";
import { CustomLoadingButton } from "../../components/buttons/SubmitLoaderButton";

const ApprovalBar = ({ seller, buyer, transactionLedger }: any) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { ledgerid } = useParams();
  // const navigate = useNavigate();
  const [successOpen, setSuccessOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);

  const { mutate: approveDeal, isLoading } = useMutation(
    approveRejectTransaction,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("transactions-by-id");
        setSuccessOpen(true);
        toast.success("Transaction Approved");
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleApprove = () => {
    approveDeal({
      body: {
        isVerified: true,
      },
      id: ledgerid,
    });
  };

  const handleReject = () => {
    setRejectOpen(true);
  };

  const handleCloseSuccess = () => {
    setSuccessOpen(false);
  };

  const handleCloseReject = () => {
    setRejectOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          bottom: 0,
          display: "flex",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          "@media (max-width: 600px)": {
            justifyContent: "center",
          },
        }}
      >
        <Stack direction={"row"} width={"300px"} justifyContent={"end"}>
          {!isLoading && (
            <CustomLoadingButton
              isReject={true}
              title={"Reject"}
              onClick={handleReject}
            />
          )}

          <CustomLoadingButton
            title={"Approve"}
            onClick={handleApprove}
            submitLoader={isLoading}
          />
        </Stack>
      </Box>

      <TransactionSuccess
        seller={seller}
        buyer={buyer}
        open={successOpen}
        handleClose={handleCloseSuccess}
        transactionLedger={transactionLedger}
      />
      <RejectionModal
        open={rejectOpen}
        onClose={handleCloseReject}
        // data={data}
      />
    </>
  );
};

export default ApprovalBar;
