import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteComapanyDetailsById,
  updateEntityStatus,
} from "../../../api/services";
import { CustomLoadingButton } from "../../../components/buttons/SubmitLoaderButton";
import ConfirmDeleteDialog from "../../../components/modals/ConfirmDeleteDialog";
interface ApprovalBarProps {
  entityId: string;
  status: string;
  reason?: string;
}

const ApprovalBar: React.FC<ApprovalBarProps> = ({
  entityId,
  status,
  reason,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { mutate: entityStatus, isLoading } = useMutation(updateEntityStatus, {
    onSuccess: (res: any) => {
      toast.success("Entity approved successfully");
      navigate(`/entities/entity-success/${entityId}`, { replace: true });
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const { mutate: deleteEntity, isLoading: isDeleting } = useMutation(
    deleteComapanyDetailsById,
    {
      onSuccess: (res: any) => {
        toast.success("Entity deleted successfully");
        navigate("/entities", { replace: true }); // Navigate to a relevant page after deletion
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message || "Failed to delete entity"
        );
      },
    }
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = () => {
    entityStatus({
      id: entityId,
      status: "Verified",
      reason: "",
    });
  };

  const handleReject = () => {
    if (status?.toLowerCase() === "rejected") {
      toast.error("Entity Already Rejected");
    } else {
      navigate(`/entities/${entityId}/rejection`, { replace: true });
    }
  };

  const handleDelete = () => {
    setIsDialogOpen(true); // Open confirmation dialog
  };

  const confirmDeleteEntity = () => {
    deleteEntity(entityId); // Trigger the mutation to delete the entity
    setIsDialogOpen(false); // Close the dialog after confirmation
  };
  // const handleReApprove = async () => {
  //   try {
  //     await updateEntityStatus({
  //       id: entityId,
  //       status: "Verified",
  //       reason: "",
  //     });
  //     toast.success("Entity re-approved successfully");
  //     navigate(`/entities/entity-success/${entityId}`, { replace: true });
  //   } catch (error) {
  //     console.error("Failed to re-approve entity", error);
  //   }
  // };

  const handleShowReason = () => {
    setOpen(true);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reason for Rejection</DialogTitle>
        <DialogContent>
          <DialogContentText
            component="div"
            dangerouslySetInnerHTML={{ __html: reason || "" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginLeft: "-20px",
          backgroundColor: "#FFFFFF",
          padding: "20px",
          "@media (max-width: 600px)": {
            justifyContent: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: 4,
            "@media (max-width: 600px)": {
              justifyContent: "center",
              width: "100%",
            },
          }}
        >
          <CustomLoadingButton
            title={"Delete Entity"}
            isReject={true}
            submitLoader={isDeleting}
            onClick={handleDelete}
            sx={{
              margin: "0px",
              padding: "10px 40px",
            }}
          />

          {!isLoading && status?.toLowerCase() !== "rejected" && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleReject}
              sx={{
                marginRight: 2,
                padding: "10px 40px",
              }}
            >
              Reject
            </Button>
          )}

          {status?.toLowerCase() === "rejected" && (
            <>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleShowReason}
                sx={{
                  marginRight: 2,
                  padding: "10px 40px",
                }}
              >
                Show Reason
              </Button>

              <CustomLoadingButton
                title={"Re-Approve"}
                submitLoader={isLoading}
                onClick={handleApprove}
              />

              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleReApprove}
                sx={{
                  marginRight: 2,
                  padding: "10px 40px",
                }}
              >
                Re-Approve
              </Button> */}
            </>
          )}
          {status?.toLowerCase() !== "rejected" && (
            <CustomLoadingButton
              title={"Approve"}
              submitLoader={isLoading}
              onClick={handleApprove}
            />

            // <Button
            //   variant="contained"
            //   color="primary"
            //   onClick={handleApprove}
            //   sx={{
            //     marginRight: 2,
            //     padding: "10px 40px",
            //   }}
            // >
            //   Approve
            // </Button>
          )}
        </Box>
      </Box>

      <ConfirmDeleteDialog
        open={isDialogOpen}
        closeDialog={() => setIsDialogOpen(false)}
        confirmDelete={confirmDeleteEntity}
        role="entity"
      />
    </>
  );
};

export default ApprovalBar;
