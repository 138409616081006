import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

const EntityInformation = ({ data }: any) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));

  const entity = data?.data;
  function maskAadharNumber(aadharNumber: any) {
    if (!aadharNumber) return null;

    const maskedLength = aadharNumber?.length - 4;
    const maskedPart = "*".repeat(maskedLength);
    const lastFourDigits = aadharNumber?.slice(-4);

    return maskedPart + lastFourDigits;
  }

  function maskedPanNumber(panNumber: any) {
    if (!panNumber || panNumber.length < 5) return panNumber || "Not Available";

    const maskedLength = panNumber?.length - 4;
    const maskedPart = "X".repeat(maskedLength);
    const visiblePart = panNumber.substring(maskedLength);

    return maskedPart + visiblePart;
  }

  return (
    <>
      <Box marginBottom="30px" marginLeft="20px">
        <Typography variant="h4" sx={{ marginBottom: "30px" }}>
          {entity?.companyType === "INDIVIDUAL" ? (
            <>Individual Information</>
          ) : (
            <>Entity Information - {entity?.companyType?.replace(/_/g, " ")}</>
          )}
        </Typography>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="30px"
          flexDirection={isTabletOrMobile ? "column" : "row"}
        >
          {entity?.companyType === "INDIVIDUAL" ? (
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="caption">Individual Name</Typography>
                <Typography variant="subtitle2">
                  {entity?.directors[0]?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="caption">Aadhar Number</Typography>
                <Typography variant="subtitle2">
                  {maskAadharNumber(entity?.directors[0]?.aadharNumber) ||
                    "Not Available"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="caption">Aadhar Status</Typography>
                <Typography variant="subtitle2">
                  {entity?.directors[0]?.isAadharMismatch ? (
                    <Typography color={"red"}> Mismatch </Typography>
                  ) : (
                    <Typography color={"green"}>Verified</Typography>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="caption">PAN Number</Typography>
                <Typography variant="subtitle2">
                  {maskedPanNumber(entity?.directors[0]?.panNumber)}
                </Typography>
              </Grid>
            </Grid>
          ) : entity?.companyType === "OTHER" ? (
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="caption">Firm Name</Typography>
                <Typography variant="subtitle2">
                  {entity?.companyName}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="caption">Business Category</Typography>
                <Typography variant="subtitle2">{entity?.category}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="caption">Firm Name</Typography>
                <Typography variant="subtitle2">
                  {entity?.companyName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="caption">GST Number</Typography>
                <Typography variant="subtitle2">{entity?.gstNumber}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="caption">PAN Number</Typography>
                <Typography variant="subtitle2">{entity?.panNumber}</Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default EntityInformation;
