import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
// import { rejectDeal } from "../../../api/services/deals";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { cancelDeal } from "../../../../api/services/deals";
import { CustomLoadingButton } from "../../../../components/buttons/SubmitLoaderButton";
import Quill from "../../../../components/quill";

interface RejectionModalProps {
  open: boolean;
  onClose: () => void;
  data?: any;
  secondUser?: any;
}

const RejectionModal: React.FC<RejectionModalProps> = ({
  open,
  onClose,
  data,
  secondUser,
}) => {
  const [comment, setComment] = useState("");

  const { mutate: reject, isLoading } = useMutation(cancelDeal, {
    onSuccess: (res: any) => {
      toast.success("Deal cancelled successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleReject = () => {
    reject({ data: { comment }, id: data?.code });
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 570,
            // height: 400,
            background: "#FFFFFF",
            border: "1px solid #1212121A",
            borderRadius: "15px",
            opacity: 1,
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "-40px",
              right: "0px",
              zIndex: 1,
              backgroundColor: "#FFFFFF",
              borderRadius: "50%",
              height: "25px",
              width: "25px",
            }}
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Mundial",
              lineHeight: "35px",
              letterSpacing: 0,
              color: "#111111",
              opacity: 1,
            }}
          >
            Reason for Rejection
          </Typography>
          <Typography
            variant="h6"
            sx={{
              textAlign: "left",
              fontFamily: "Mundial",
              // lineHeight: "25px",
              letterSpacing: 0,
              color: "#1D1D1D",
              opacity: 0.8,
            }}
          >
            Add reason
          </Typography>
          {/* <TextField
            id="reasonInput"
            placeholder="Enter your text here…"
            multiline
            rows={4}
            value={comment} // Bind the value of the comment
            onChange={(e) => setComment(e.target.value)} // Update the comment state
            sx={{
              width: 500,
              height: 150,
              border: "1px solid #33333333",
              borderRadius: 3,
              opacity: 1,
            }}
          /> */}
          <Box>
            <Quill
              data={comment}
              handleChange={setComment}
              pageWidth="pageWidth"
            />
          </Box>
          <CustomLoadingButton
            submitLoader={isLoading}
            onClick={handleReject}
            title={"Submit"}
          />

          {/* <Button
            variant="contained"
            color="primary"
            sx={{
              width: 250,
              height: 50,
              background: "#7643EB",
              boxShadow: "0px 3px 6px #00000029",
              opacity: 1,
            }}
            onClick={handleReject}
          >
            Submit
          </Button> */}
        </Box>
      </>
    </Modal>
  );
};

export default RejectionModal;
