//images
import alert from "./icons/alert.png";
import arrow_down from "./icons/arrow-down.svg";
import business from "./icons/business.png";
import businessHi from "./icons/bussines-hilighted.png";
import check from "./icons/check.png";
import {
  default as checked,
  default as checkedblue,
  default as checkedimage,
} from "./icons/checked.png";
import greenCheck from "./icons/greenCheck.png";
import clock from "./icons/clock.png";
import deleteicon from "./icons/delete-icon.png";
import document from "./icons/document.png";
import download from "./icons/download.png";
import editPlain from "./icons/edit-plain.png";
import edit from "./icons/edit.png";
import email from "./icons/email.png";
import firmDocuments from "./icons/firmdocuments.svg";
import google from "./icons/googlelogo.png";
import greencheck from "./icons/green-check.png";
import individualHi from "./icons/individual-hilighted.png";
import individual from "./icons/Individual.png";
import noramlcheck from "./icons/normalcheck.png";
import notificaiton from "./icons/notification.svg";
import PaymentReceived from "./icons/payment-received.png";
import Paymnetsent from "./icons/payment-sent.png";
import pdf from "./icons/pdf.png";
import pdfIcon from "./icons/pdficon.png";
import settings from "./icons/setting-2.svg";
import timelineIcon from "./icons/timelinemessage.png";
import truupe from "./icons/truupelogo.png";
import verified from "./icons/verifiedlogo.png";
import view from "./icons/view.png";
import dwagreement from "./images/agreement.png";
import authimage from "./images/authimage.png";
import goodshi from "./images/boxes-hi.png";
import goods from "./images/boxes.png";
import buyerHi from "./images/buyer-hi.png";
import buyer from "./images/buyer.png";
import calender from "./images/calendar.png";
import clockimage from "./images/clock@2x.png";
import dealAmount from "./images/deal-amount.png";
import dealcreate from "./images/deal-create.png";
import dealReject from "./images/deal-reject.jpg";
import dwDeposit from "./images/deposit.png";
import EscrowCard from "./images/escrow-card.png";
import dwfunds from "./images/execute-funds.png";
import rupee from "./images/indian-rupee.png";
import dwInitailfundsTransfer from "./images/initiate-fund-release.png";
import dwinviteParties from "./images/invite-parties.png";
import logo from "./images/logo.png";
import newDeal from "./images/new-deal.png";
import pagenotfound from "./images/page-not-found404.png";
import dwSecuredEscrow from "./images/secure-escrow.png";
import user from "./icons/user.svg";
import userTick from "./icons/user-tick.svg";
import dealCard from "./icons/deal-card.svg";
import dealCardTick from "./icons/deal-card-tick.svg";
import dealCardPending from "./icons/deal-card-pending.svg";
import dealCardTopTick from "./icons/deal-card-top-tick.svg";
import superUser from "./icons/super-user.svg";
import walletPending from "./icons/wallet-pending.svg";
import walletTick from "./icons/wallet-tick.svg";
import walletAdd from "./icons/wallet-add.svg";
import verificationPending from "./icons/verification-pending.svg";
import paymentProgress from "./icons/payment-progress.svg";

import {
  default as sellerhi,
  default as serviceshi,
} from "./images/seller-hi.png";
import { default as seller, default as services } from "./images/seller.png";
import trustees from "./images/trustees.png";

export {
  authimage,
  buyer,
  buyerHi,
  calender,
  checked,
  clockimage,
  dealAmount,
  dealcreate,
  dealReject,
  EscrowCard,
  firmDocuments,
  goods,
  goodshi,
  logo,
  newDeal,
  rupee,
  seller,
  sellerhi,
  services,
  serviceshi,
};

export const Icons = {
  PaymentReceived,
  Paymnetsent,
  timelineIcon,
  dwDeposit,
  dwInitailfundsTransfer,
  dwagreement,
  dwfunds,
  dwinviteParties,
  dwSecuredEscrow,
  checked,
  alert,
  pdf,
  pdfIcon,
  google,
  notificaiton,
  document,
  settings,
  truupe,
  email,
  businessHi,
  business,
  individual,
  individualHi,
  greencheck,
  noramlcheck,
  verified,
  pagenotfound,
  view,
  download,
  clock,
  arrow_down,
  trustees,
  edit,
  editPlain,
  deleteicon,
  checkedimage,
  check,
  checkedblue,
  greenCheck,
  user,
  userTick,
  dealCard,
  dealCardTick,
  dealCardPending,
  dealCardTopTick,
  superUser,
  walletPending,
  walletTick,
  walletAdd,
  verificationPending,
  paymentProgress,
};
