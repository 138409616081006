import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import * as yup from "yup";
import { getUserProfile } from "../../../../api/services";
import InputField from "../../../../components/shared/InputField";
import FileUploadContainer from "../../../../components/shared/FileUploadContainer";
import { getFirmDocumentData } from "../../../../utils/utils";

const stylesBefore = {
  width: "230px",
  height: "70px",
  padding: "15px 0px",
  border: `2px dashed #3333334D`,
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  userSelect: "none",
};
const stylesAfter = {
  px: "1vw",
  width: "200px",
  height: "70px",
  padding: "10px",
  border: "1px solid #1212121A",
  borderRadius: "10px",
  opacity: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#FBFBFB",
};

type Info = {
  date: string;
  dealTitle?: string;
  dealType?: string;
  dealStartDate?: string;
  dealDuration?: string;

  sellerName?: string;
  sellerEmail?: string;
  sellerEntityName?: string;
  sellerAddress?: string;

  buyerName?: string;
  buyerEmail?: string;
  buyerEntityName?: string;
  buyerAddress?: string;

  secondPersonIfsc?: string;
  secondPersonAccNum?: string;
  secondPersonBranch?: string;

  firstPersonIfsc?: string;
  firstPersonAccNum?: string;
  firstPersonBranch?: string;
};

const schema = yup.object().shape({
  date: yup.string().required("Director Name is required"),
});

const DealInfo = ({ data, accountOwner, doc, submit }: any) => {
  const { data: profiledata }: any = useQuery("getuser-id", getUserProfile);
  const queryClient = useQueryClient();
  const firstUser = data?.dealUsers?.find(
    (user: any) => user?.userId === data?.createdById
  );

  const secondUser = data?.dealUsers?.find(
    (user: any) => user?.userId !== data?.createdById
  );

  const duration = data?.duration > 1 ? "Months" : "Month";

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<Info>({
    resolver: yupResolver(schema),
    defaultValues: {
      dealTitle: data?.title || "NA",
      dealType: data?.type || "NA",
      dealStartDate: data?.createdAt || "NA",
      dealDuration: data?.duration || "NA",
      sellerName: secondUser?.user?.name || "Not Registered",
      sellerEmail:
        secondUser?.user?.emailAddress ||
        localStorage.getItem("dealEmailAddress") ||
        "Not Registered",
      sellerEntityName: secondUser?.entity?.companyName || "Not Registered",
      sellerAddress: secondUser?.entity?.registeredAddress || "Not Registered",

      buyerName: firstUser?.user?.name || "Not Registered",
      buyerEmail:
        firstUser?.user?.emailAddress ||
        // localStorage.getItem("dealEmailAddress") ||
        "Not Registered",
      buyerEntityName: firstUser?.entity?.companyName || "Not Registered",
      buyerAddress: firstUser?.entity?.registeredAddress || "Not Registered",

      secondPersonIfsc:
        secondUser?.entity?.bankingInformation[0]?.ifscCode || "Not Registered",
      secondPersonAccNum:
        secondUser?.entity?.bankingInformation[0]?.accountNumber ||
        "Not Registered",
      secondPersonBranch:
        secondUser?.entity?.bankingInformation[0]?.branch || "Not Registered",

      firstPersonIfsc:
        firstUser?.entity?.bankingInformation[0]?.ifscCode || "NA",
      firstPersonAccNum:
        firstUser?.entity?.bankingInformation[0]?.accountNumber || "NA",
      firstPersonBranch:
        firstUser?.entity?.bankingInformation[0]?.branch || "NA",

      date: data?.date || "",
    },
  });

  useEffect(() => {
    if (data) {
      setValue("dealTitle", data?.title || "NA");
      setValue("dealType", data?.type || "NA");
      setValue(
        "dealStartDate",
        moment(data?.createdAt).format("DD/MM/YYYY") || "NA"
      );
      setValue("dealDuration", `${data?.duration} ${duration}` || "NA");

      setValue("sellerName", secondUser?.user?.name || "Not Registered");
      setValue(
        "sellerEmail",
        secondUser?.user?.emailAddress ||
          localStorage.getItem("dealEmailAddress") ||
          "Not Registered"
      );
      setValue(
        "sellerEntityName",
        secondUser?.entity?.companyName || "Not Registered"
      );
      setValue(
        "sellerAddress",
        secondUser?.entity?.registeredAddress || "Not Registered"
      );

      setValue("buyerName", firstUser?.user?.name || "Not Registered");
      setValue(
        "buyerEmail",
        firstUser?.user?.emailAddress ||
          // localStorage.getItem("dealEmailAddress") ||
          "Not Registered"
      );
      setValue(
        "buyerEntityName",
        firstUser?.entity?.companyName || "Not Registered"
      );
      setValue(
        "buyerAddress",
        firstUser?.entity?.registeredAddress || "Not Registered"
      );

      setValue(
        "secondPersonIfsc",
        secondUser?.entity?.bankingInformation[0]?.ifscCode || "Not Registered"
      );
      setValue(
        "secondPersonAccNum",
        secondUser?.entity?.bankingInformation[0]?.accountNumber ||
          "Not Registered"
      );
      setValue(
        "secondPersonBranch",
        secondUser?.entity?.bankingInformation[0]?.branch || "Not Registered"
      );

      setValue(
        "firstPersonIfsc",
        firstUser?.entity?.bankingInformation[0]?.ifscCode || "Not Registered"
      );
      setValue(
        "firstPersonAccNum",
        firstUser?.entity?.bankingInformation[0]?.accountNumber ||
          "Not Registered"
      );
      setValue(
        "firstPersonBranch",
        firstUser?.entity?.bankingInformation[0]?.branch || "Not Registered"
      );

      setValue("date", data?.date || "");
    }
  }, [data, setValue, duration, secondUser, firstUser]);

  const handleDateChange = (newDate: any) => {
    setValue("date", newDate);
    submit(newDate);
  };

  const onFormSubmit = (value: any) => {};

  return doc ? (
    <Box sx={{ padding: "35px 20px" }}>
      <Box sx={{ ml: 2, mb: 1 }}>
        <InputLabel
          shrink
          sx={{
            fontSize: "17px",
            fontWeight: 300,
            mb: -1,
            "&.Mui-focused": {
              color: "#00000099",
            },
          }}
        >
          <Typography variant="body1">Date</Typography>
        </InputLabel>
        {/* <FormDate name="date" control={control} onChange={handleDateChange} />{" "} */}
      </Box>
      <Accordion
        defaultExpanded
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="body2">Deal Information</Typography>
        </AccordionSummary>
        <Divider sx={{ mt: -1 }} />
        <AccordionDetails>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Controller
              name="dealTitle"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"dealTitle"}
                  name={"Deal Title"}
                  errors={errors}
                />
              )}
            />

            <Controller
              name="dealType"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"dealType"}
                  name={"Deal Type"}
                  errors={errors}
                />
              )}
            />

            <Controller
              name="dealStartDate"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"dealStartDate"}
                  name={"Deal Start Date"}
                  errors={errors}
                />
              )}
            />

            <Controller
              name="dealDuration"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"dealDuration"}
                  name={"Deal Duration"}
                  errors={errors}
                />
              )}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="body2">
            Deal {secondUser?.userType} Info
          </Typography>
        </AccordionSummary>
        <Divider sx={{ mt: -1 }} />
        <AccordionDetails>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Controller
              name="sellerName"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"sellerName"}
                  name={`${secondUser?.userType} Name`}
                  errors={errors}
                />
              )}
            />

            <Controller
              name="sellerEmail"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"sellerEmail"}
                  name={`${secondUser?.userType} Email Address`}
                  errors={errors}
                />
              )}
            />

            <Controller
              name="sellerEntityName"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"sellerEntityName"}
                  name={`${secondUser?.userType} Entity Name`}
                  errors={errors}
                />
              )}
            />

            <Controller
              name="sellerAddress"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"sellerAddress"}
                  name={`${secondUser?.userType} Address`}
                  errors={errors}
                />
              )}
            />

            {/* <Grid>
              <Typography variant="caption" >
                {secondUser?.userType} Bank IFSC CODE
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {secondUser?.entity?.bankingInformation[0]?.ifscCode ||
                  "Not Registered"}
              </Typography>
            </Grid> */}
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="body2">
            Deal {firstUser?.userType} Info
          </Typography>
        </AccordionSummary>
        <Divider sx={{ mt: -1 }} />
        <AccordionDetails>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Controller
              name="buyerName"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"buyerName"}
                  name={`${firstUser?.userType} Name`}
                  errors={errors}
                />
              )}
            />

            <Controller
              name="buyerEmail"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"buyerEmail"}
                  name={`${firstUser?.userType} Email Address`}
                  errors={errors}
                />
              )}
            />

            <Controller
              name="buyerEntityName"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"buyerEntityName"}
                  name={`${firstUser?.userType} Entity Name`}
                  errors={errors}
                />
              )}
            />

            <Controller
              name="buyerAddress"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"buyerAddress"}
                  name={`${firstUser?.userType} Address`}
                  errors={errors}
                />
              )}
            />

            {/* <Grid>
              <Typography variant="caption" >
                {secondUser?.userType} Bank IFSC CODE
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {secondUser?.entity?.bankingInformation[0]?.ifscCode ||
                  "Not Registered"}
              </Typography>
            </Grid> */}
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="body2">
            {secondUser?.userType} Banking Details
          </Typography>
        </AccordionSummary>
        <Divider sx={{ mt: -1 }} />
        <AccordionDetails>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Controller
              name="secondPersonIfsc"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"secondPersonIfsc"}
                  name={`${secondUser?.userType} Bank IFSC Code`}
                  errors={errors}
                />
              )}
            />

            <Controller
              name="secondPersonAccNum"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"secondPersonAccNum"}
                  name={`${secondUser?.userType} Bank Account Number`}
                  errors={errors}
                />
              )}
            />
            <Controller
              name="secondPersonBranch"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"secondPersonBranch"}
                  name={`${secondUser?.userType} Bank Branch`}
                  errors={errors}
                />
              )}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="body2">
            {firstUser?.userType} Banking Details
          </Typography>
        </AccordionSummary>
        <Divider sx={{ mt: -1 }} />
        <AccordionDetails>
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Controller
              name="firstPersonIfsc"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"firstPersonIfsc"}
                  name={`${firstUser?.userType} Bank IFSC Code`}
                  errors={errors}
                />
              )}
            />

            <Controller
              name="firstPersonAccNum"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"firstPersonAccNum"}
                  name={`${firstUser?.userType} Bank Account Number`}
                  errors={errors}
                />
              )}
            />
            <Controller
              name="firstPersonBranch"
              control={control}
              render={({ field }) => (
                <InputField
                  isdisabled
                  field={field}
                  width={"17vw"}
                  iD={"firstPersonBranch"}
                  name={`${firstUser?.userType} Bank Branch`}
                  errors={errors}
                />
              )}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  ) : (
    <Box>
      <Accordion
        defaultExpanded
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="body2">Deal Information</Typography>
        </AccordionSummary>
        <Divider sx={{ mt: -1 }} />
        <AccordionDetails>
          <Stack gap={1} sx={{ width: "100%" }}>
            <Grid>
              <Typography variant="caption">Deal Title</Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {data?.title}
              </Typography>
            </Grid>

            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography variant="caption">Deal Type</Typography>
                <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                  {data?.type}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">Deal Start Date</Typography>
                <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                  {moment(data?.createdAt).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">Deal Duration</Typography>
                <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                  {data?.duration} {duration}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">Expected Closure Date</Typography>
                <Typography variant="body2" sx={{ marginBottom: "0px" }}>
                  {moment(data?.closureDate).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">Deal Description</Typography>
                <Typography
                  variant="body2"
                  sx={{ marginBottom: "0px" }}
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                />
              </Grid>
              {/* <Grid item xs={6}>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">Supporting Docs (Individual - Seller)</Typography>
                {Array.isArray(data?.dealSupportingDocuments) &&
                data?.dealSupportingDocuments.length > 0 ? (
                  <FileUploadContainer
                    typeId={data?.id}
                    actions={<></>}
                    data={"INDIVIDUAL_SELLER"}
                    // subType={"INDIVIDUAL_SELLER"}
                    typeData={"DEAL"}
                    uploaded={true}
                    shrinked={true}
                    stylesBefore={stylesBefore}
                    stylesAfter={stylesAfter}
                    showIcons={false}
                    name={getFirmDocumentData(
                      data?.dealSupportingDocuments,
                      "INDIVIDUAL_SELLER",
                      "originalFileName"
                    )}
                    url={getFirmDocumentData(
                      data?.dealSupportingDocuments,
                      "INDIVIDUAL_SELLER",
                      "url"
                    )}
                    onFileChange={() => {
                      queryClient.invalidateQueries("deal");
                    }}
                  />
                ) : (
                  <Typography variant="body2">
                    No supporting documents available
                  </Typography>
                )}
              </Grid> */}
            </Grid>
            <Typography variant="caption">Supporting Docs (Individual - Seller)</Typography>
                {Array.isArray(data?.dealSupportingDocuments) &&
                data?.dealSupportingDocuments.length > 0 ? (
                  <FileUploadContainer
                    typeId={data?.id}
                    actions={<></>}
                    data={"INDIVIDUAL_SELLER"}
                    // subType={"INDIVIDUAL_SELLER"}
                    typeData={"DEAL"}
                    uploaded={true}
                    shrinked={true}
                    stylesBefore={stylesBefore}
                    stylesAfter={stylesAfter}
                    showIcons={false}
                    name={getFirmDocumentData(
                      data?.dealSupportingDocuments,
                      "INDIVIDUAL_SELLER",
                      "originalFileName"
                    )}
                    url={getFirmDocumentData(
                      data?.dealSupportingDocuments,
                      "INDIVIDUAL_SELLER",
                      "url"
                    )}
                    onFileChange={() => {
                      queryClient.invalidateQueries("deal");
                    }}
                  />
                ) : (
                  <Typography variant="body2">
                    No supporting documents available
                  </Typography>
                )}
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="body2">
            Deal {secondUser?.userType} Information
          </Typography>
        </AccordionSummary>
        <Divider sx={{ mt: -1 }} />
        <AccordionDetails>
          <Stack gap={1} sx={{ width: "100%" }}>
            <Grid>
              <Typography variant="caption">
                {secondUser?.userType} Name
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {secondUser?.user?.name || "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">
                {secondUser?.userType} Email Address
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {secondUser?.user?.emailAddress || "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">
                {secondUser?.userType} Entity Name
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {secondUser?.entity?.companyName || "Not Registered"}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption">
                {secondUser?.userType} Address
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {secondUser?.entity?.registeredAddress || "Not Registered"}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption">
                {secondUser?.userType} Beneficiary Name
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {secondUser?.entity?.bankingInformation[0]?.beneficiaryName ||
                  "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">
                {secondUser?.userType} Bank IFSC CODE
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {secondUser?.entity?.bankingInformation[0]?.ifscCode ||
                  "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">
                {secondUser?.userType} Bank Branch Name
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {secondUser?.entity?.bankingInformation[0]?.branch ||
                  "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">
                {secondUser?.userType} Bank Account Number
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {secondUser?.entity?.bankingInformation[0]?.accountNumber ||
                  "Not Registered"}
              </Typography>
            </Grid>
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="body2">
            Deal {firstUser?.userType} Information
          </Typography>
        </AccordionSummary>
        <Divider sx={{ mt: -1 }} />
        <AccordionDetails>
          <Stack gap={1} sx={{ width: "100%" }}>
            <Grid>
              <Typography variant="caption">
                {firstUser?.userType} Name
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {firstUser?.user?.name || "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">
                {firstUser?.userType} Email Address
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {firstUser?.user?.emailAddress || "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">
                {firstUser?.userType} Entity Name
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {firstUser?.entity?.companyName || "Not Registered"}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption">
                {firstUser?.userType} Address
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {firstUser?.entity?.registeredAddress || "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">
                {firstUser?.userType} Beneficiary Name
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {firstUser?.entity?.bankingInformation[0]?.beneficiaryName ||
                  "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">
                {firstUser?.userType} Bank IFSC CODE
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {firstUser?.entity?.bankingInformation[0]?.ifscCode ||
                  "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">
                {firstUser?.userType} Bank Branch Name
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {firstUser?.entity?.bankingInformation[0]?.branch ||
                  "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">
                {firstUser?.userType} Bank Account Number
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {firstUser?.entity?.bankingInformation[0]?.accountNumber ||
                  "Not Registered"}
              </Typography>
            </Grid>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default DealInfo;
