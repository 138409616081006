import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import { getReport } from "../../api/services";
import { CustomLoadingButton } from "../../components/buttons/SubmitLoaderButton";
import DateField from "../../components/form-fields/DateField";
import SelectDropdown from "../../components/form-fields/FormInputSelect";

export interface ReportQuery {
  type: string;
  startDate: string;
  endDate: string;
}
const types = [
  {
    value: "Kyc Not Started",
    label: "User registered, but haven't started the KYC Process",
    headingForDate: `Select date range for "Creation of User"`,
  },
  {
    value: "Kyc In Progress",
    label: "User started the KYC process but did not finish it.",
    headingForDate: `Select date range for "Creation of Entity"`,
  },
  {
    value: "Kyc Completed",
    label: "User KYC completed but no Deals have been created",
    headingForDate: `Select date range for "Creation of Entity"`,
  },
  {
    value: "Deal Created",
    label: "User created a deal but there is no progress",
    headingForDate: `Select date range for "Creation of Deal"`,
  },
];

const validationSchema = yup.object().shape({
  startDate: yup.string().required("Start date is required"),
  endDate: yup
    .string()
    .required("End date is required")
    .test(
      "is-after-start-date",
      "End date must be after or equal to start date",
      function (value) {
        return moment(value).isSameOrAfter(moment(this.parent.startDate));
      }
    ),
  type: yup.string().required("Please select a type"),
});

export default function UserReport() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      type: "",
      startDate: "",
      endDate: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const maxDate = moment().format("YYYY-MM-DD");
  const type = watch("type");
  const startDate = watch("startDate");

  const { mutate: fetchData } = useMutation(getReport, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res?.data);
      const blob = new Blob([arr], {
        type: "text/csv;charset=utf-8;",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = file;
      link.download = "User Report.csv";
      link.click();
      toast.success("Report Downloaded Successfully");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const onSubmit = (formData: ReportQuery) => {
    fetchData(formData);
  };

  return (
    <Card sx={{ mt: 2, p: 4 }}>
      <Box>
        <Typography variant="h6">USER REPORTS</Typography>
      </Box>
      <Divider sx={{ mb: 2, mt: 1 }} />

      <Box>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <SelectDropdown
              placeholder="Select the type"
              field={field}
              options={types.map(({ value, label }) => ({ value, label }))}
              errors={errors}
              title="Type"
              iD="type"
              onChange={(e: any) => {
                setValue("type", e.target.value);
              }}
            />
          )}
        />
      </Box>

      <>
        <Box sx={{ mt: 4, mb: -1 }}>
          <Typography variant="caption">
            {type
              ? types.find((s) => s.value === type)?.headingForDate
              : "Select date"}
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>
        </Box>

        <Stack direction="row" gap={2}>
          <DateField
            control={control}
            name="startDate"
            title="Start Date"
            errors={errors}
            getMinDate="2020-01-01"
            getMaxDate={maxDate}
            width="300px"
          />
          <DateField
            control={control}
            name="endDate"
            title="End Date"
            errors={errors}
            getMinDate={startDate || "2020-01-01"}
            getMaxDate={maxDate}
            width="300px"
          />
        </Stack>
      </>

      <Box>
        <CustomLoadingButton
          // submitLoader={isLoading}
          onClick={handleSubmit(onSubmit)}
          title="Submit"
        />
      </Box>
    </Card>
  );
}
