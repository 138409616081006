import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Icons } from "../../assets";

export default function NoCouponsFound() {
  const handleSearch = (e: any) => {};
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "83vh",
        }}
      >
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <img
            src={Icons.trustees}
            alt="Empty State"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Typography variant="h2" sx={{ my: "15px", fontWeight: "400" }}>
            It looks like you haven't added any coupons yet.
          </Typography>
          <Button
            variant="contained"
            sx={{
              marginTop: "20px",
              padding: "10px 40px",
              borderRadius: "5px",
              backgroundColor: "#7643EB",
              color: "white",
            }}
            onClick={() => {
              navigate("/coupons/create");
            }}
          >
            Add Coupon
          </Button>
        </Box>
      </Box>

      {/* <CreateUser open={drawerOpen} onClose={handleDrawerClose} /> */}
    </>
  );
}
