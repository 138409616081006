import DeleteSweepSharpIcon from "@mui/icons-material/DeleteSweepSharp";
import EditNoteIcon from "@mui/icons-material/EditNote";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Icons } from "../../assets/index";
import DocumentsForm from "../../pages/entities/entity-details-new/DocumentsForm";
import DrawerButtonAction from "../buttons/DrawerButtonAction";

interface FileUploadContainerProps {
  onFileChange: (file: any) => void;
  typeData: string;
  data: string;
  uploaded?: boolean;
  url?: string;
  name?: string;
  filetype?: string;
  actions: any;
  status?: any;
  typeId?: any;
  isSupporting?: string;
  isDeleted?: any;
  showIcons?: boolean;
  subType?: any;
  stylesBefore?: object;
  stylesAfter?: object;
  shrinked?: boolean;
  isEditable?: boolean;
  isRejected?: boolean;
  docStatus?: string;
}

const FileUploadContainer: React.FC<FileUploadContainerProps> = ({
  onFileChange,
  data,
  uploaded,
  typeData,
  url = "",
  name,
  filetype = null,
  actions,
  status,
  typeId = null,
  isSupporting = "false",
  isDeleted,
  showIcons = true,
  subType,
  stylesBefore,
  stylesAfter,
  shrinked,
  isEditable = false,
  docStatus,
}) => {
  const [uri, setUri] = useState("");

  const [uploded, setUploded] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [loading, serloading] = useState(false);

  useEffect(() => {
    if (uploaded) {
      setUploded(true);
    }
  }, [uploaded]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles: File[]) => {
      const fileSizeInMB = acceptedFiles[0].size / (1024 * 1024);
      if (fileSizeInMB < 50) {
        setFile(acceptedFiles[0]);
        const formData: any = new FormData();
        formData.append("file", acceptedFiles[0]);
        formData.append("type", typeData);
        formData.append("subType", data);
        formData.append("filetype", filetype);
        formData.append("typeId", typeId);
        formData.append("isSupporting", isSupporting);
        serloading(true);
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/media-object/file-upload`,
            formData
          )
          .then((res) => {
            setUploded(true);
            onFileChange(res?.data?.key);
            serloading(false);
            setUri(res?.data?.storageDetails?.location);
            if (typeof isDeleted === "function") {
              isDeleted(true);
            }
          })
          .catch((err) => {
            serloading(false);
            toast.error("File Upload Failed");
          });
      } else {
        toast.error("Upload file size should be less than 50MB");
      }
    },
  });

  const deleteFile = () => {
    setUploded(false);
    setFile(null);
    if (typeof isDeleted === "function") {
      isDeleted(false);
    }
    onFileChange("");
  };

  const formatDate = () => {
    const date = new Date();
    return date.toLocaleDateString("en-US");
  };

  const handleFileLinkClick = () => {
    if (url && url.length > 0) {
      window.open(url, "_blank");
    }
  };

  const truncateText = (text: any, maxLength: any) => {
    if (!text) return "";
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  return (
    <>
      {uploded ? (
        <>
          {subType ? (
            <Typography
              variant="caption"
              sx={{
                color: "#000000",
                opacity: 0.7,
                marginBottom: "5px",
                marginLeft: "5px",
              }}
            >
              {subType?.replace(/_/g, " ") +
                (isSupporting === "true" ? " - SUPPORTING" : "")}
              {docStatus != "" && (
                <span style={{ color: "#4D00FF" }}> - {docStatus}</span>
              )}
            </Typography>
          ) : (
            <></>
          )}
          <Box
            sx={
              stylesAfter || {
                px: "1vw",
                width: {
                  xs: "200px",
                  sm: "350px",
                  lg: "350px",
                  md: "350px",
                  xl: "350px",
                },
                padding: "20px",
                border: "1px solid #1212121A",
                borderRadius: "10px",
                opacity: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#FBFBFB",
              }
            }
          >
            <Box display={"flex"} alignItems={"center"} gap={"10px"}>
              {/* {!shrinked && ( */}
              <Box onClick={handleFileLinkClick}>
                <img
                  height={shrinked ? "30px" : "45px"}
                  src={Icons.pdf}
                  alt="loading....."
                  style={{ cursor: "pointer" }}
                />
              </Box>
              {/* )} */}

              <Box>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {truncateText(file?.name || name, shrinked ? 10 : 20)}{" "}
                </Typography>

                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    marginTop: "5px",
                    fontFamily: "mundial",
                    fontWeight: "100",
                  }}
                >
                  Added on {formatDate()}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                background: "#F2F2F2",
                borderRadius: "5px",
                opacity: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Tooltip title="View">
                <IconButton
                  onClick={() => {
                    if (uri === "") {
                      window.open(url, "_blank");
                    } else {
                      window.open(uri, "_blank");
                    }
                  }}
                >
                  <RemoveRedEyeIcon />
                </IconButton>
              </Tooltip>
              {showIcons ? (
                isEditable ? (
                  <DrawerButtonAction
                    subtype={subType?.replace(/_/g, " ")}
                    title={`Edit ${subType?.replace(/_/g, " ")} Document`}
                    anchor={({ open }) => (
                      <Tooltip title="Edit">
                        <IconButton
                          disabled={status}
                          aria-label="edit"
                          onClick={open}
                        >
                          <EditNoteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    content={({ close }) => (
                      <DocumentsForm
                        typeId={typeId}
                        subType={subType?.replace(/_/g, " ")}
                        close={close}
                        data={data}
                        typeData={typeData}
                      />
                    )}
                  />
                ) : (
                  <Tooltip title="Delete">
                    <IconButton
                      disabled={status}
                      aria-label="delete"
                      onClick={deleteFile}
                    >
                      <DeleteSweepSharpIcon />
                    </IconButton>
                  </Tooltip>
                )
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Box
          {...getRootProps()}
          sx={
            stylesBefore || {
              width: {
                xs: "250px",
                sm: "350px",
                lg: "350px",
                md: "350px",
                xl: "350px",
              },
              height: "110px",
              padding: "25px 0px",
              border: `2px dashed ${isDragActive ? "#2196f3" : "#3333334D"}`,
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
              userSelect: "none",
            }
          }
        >
          {loading ? (
            <>
              {" "}
              <CircularProgress
                sx={{
                  marginTop: "17px",
                }}
                size={"2rem"}
              />
            </>
          ) : (
            <>
              <input accept=".pdf,.doc,.png,.jpg" {...getInputProps()} />
              <img
                src={Icons.document}
                alt="Document"
                style={{
                  width: "40px",
                  height: "40px",
                  background: "transparent",
                  opacity: 1,
                  pointerEvents: "none",
                }}
              />
              {shrinked ? (
                <Typography
                  variant="body1"
                  gutterBottom
                  color="primary"
                  sx={{ marginTop: "10px" }}
                >
                  Choose File
                </Typography>
              ) : (
                <Typography
                  variant="body1"
                  gutterBottom
                  color="primary"
                  sx={{ marginTop: "10px" }}
                >
                  Drag and Drop file here or <u>Choose File</u>
                </Typography>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default FileUploadContainer;
