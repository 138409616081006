import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Card, Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { EscrowCard } from "../assets";

const AccountCard = ({ Bankdetails, dealData }: any) => {
  const [showAccountBalance, setShowAccountBalance] = useState(false);

  const toggleAccountBalanceVisibility = () => {
    setShowAccountBalance(!showAccountBalance);
  };

  function formatNumberToIndianSystem(number: number) {
    return new Intl.NumberFormat("en-IN").format(number);
  }

  // console.log(dealData?.data?.overview?.balance);
  return (
    <>
      <Card
        sx={{
          backgroundImage: `url(${EscrowCard})`,
          backgroundRepeat: "no-repeat",
          padding: "22px",
          backgroundSize: "100% 100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "450px",
          marginTop: "20px",
        }}
      >
        <Typography variant="body1" sx={{ opacity: 0.8, color: "#FFFFFF" }}>
          Account Number
        </Typography>
        <Typography
          variant="h2"
          sx={{ fontWeight: "500", opacity: 1, color: "#FFFFFF" }}
        >
          {Bankdetails?.accountNumber}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            opacity: 0.8,
            marginTop: "50px",
            color: "#FFFFFF",
          }}
        >
          IFSC Code
        </Typography>
        <Typography
          variant="h2"
          sx={{ fontWeight: "500", opacity: 1, color: "#FFFFFF" }}
        >
          {Bankdetails?.ifscCode}
        </Typography>

        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={5}>
            <Typography variant="body1" sx={{ opacity: 0.8, color: "#FFFFFF" }}>
              Account Balance
            </Typography>
            <Typography
              variant="h1"
              sx={{ fontWeight: "500", opacity: 1, color: "#FFFFFF" }}
            >
              {showAccountBalance ? (
                <>
                  ₹{" "}
                  {formatNumberToIndianSystem(
                    dealData?.data?.overview?.balance
                  )}
                </>
              ) : (
                "**** ****"
              )}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={toggleAccountBalanceVisibility}
              sx={{ color: "#fff" }}
            >
              {showAccountBalance ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AccountCard;
