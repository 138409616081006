import {
  Box,
  Table as MuiTable,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import { newDeal } from "../../assets";

export interface ColumnProps {
  dataIndex: string;
  key: string;
  title: any;
  render?: (cellData: any, row: any) => any;
  textColor?: string;
  onCellClick?: (cellData: any, row: any) => void;
}

interface TableProps {
  columns: Array<ColumnProps>;
  rowKey?: string;
  dataSource?: any[];
  loading?: boolean;
  headAlign?: any;
  tableAlign?: any;
  width?: any;
  onRowClick?: (row: any) => void;
  role?: any;
  pagination?: {
    page: number;
    count: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => void;
    rowsPerPage: number;
    onRowsPerPageChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
  };
}

export default function NewTable({
  columns,
  dataSource,
  onRowClick,
  pagination,
  loading,
  width,
  headAlign,
  tableAlign,
  role,
}: TableProps) {
  const theme = useTheme();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "12px 10px",
    backgroundColor: "#f3f3f3",
    textWrap: "nowrap",
  }));

  return (
    <>
      <TableContainer
        sx={{
          borderRadius: "10px",
          border: "1px solid #1212121A",
          borderCollapse: "collapse",
          boxShadow: "0px 5px 15px #22222214",
          width: { width },
        }}
      >
        <>
          <MuiTable sx={{ border: "none", borderCollapse: "collapse" }}>
            <TableHead
              sx={{ backgroundColor: "#f3f3f3", fontFamily: "Mundial" }}
            >
              <TableRow>
                {columns.map((col, index) => (
                  <StyledTableCell align={"left"} key={index}>
                    <Typography color="black" sx={{ marginLeft: "20px" }}>
                      {col.title}
                    </Typography>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <Box sx={{ width: "70vw" }}>{/* <renderSkeleton/> */}</Box>
            ) : (
              <>
                {dataSource?.length !== 0 ? (
                  <TableBody>
                    {dataSource?.map((row, index) => (
                      <StyledTableRow
                        canRowClick={!!onRowClick}
                        hover={!!onRowClick}
                        key={index}
                      >
                        {columns.map((col, colIndex) => (
                          <TableCell
                            align={"left"}
                            sx={{
                              textAlign: "left",
                              cursor: "pointer",
                              padding: "10px",
                            }}
                            key={colIndex}
                            onClick={(e) => {
                              col.onCellClick?.(row[col.dataIndex], row);
                            }}
                          >
                            {col?.render ? (
                              <Typography
                                sx={{
                                  marginLeft: "20px",
                                  "&:hover": {
                                    color: theme.palette.primary.main,
                                    textDecoration: "underline",
                                  },
                                }}
                              >
                                {col.render(row[col.dataIndex], row)}
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  marginLeft: "20px",
                                  "&:hover": {
                                    color: theme.palette.primary.main,
                                    textDecoration: "underline",
                                  },
                                }}
                              >
                                {_.get(row, col.dataIndex)}
                              </Typography>
                            )}
                          </TableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                ) : (
                  <Box display={"contents"}>
                    <TableCell colSpan={7} align="center">
                      <img width={"30%"} src={newDeal} alt="loading...." />
                      <Typography variant="h5">No data available...</Typography>
                    </TableCell>
                  </Box>
                )}
              </>
            )}
          </MuiTable>
        </>
      </TableContainer>

      {pagination && (
        <TablePagination
          component="div"
          count={pagination.count}
          page={pagination.page}
          onPageChange={pagination.onPageChange}
          rowsPerPage={pagination.rowsPerPage}
          onRowsPerPageChange={pagination.onRowsPerPageChange}
          rowsPerPageOptions={[25, 50, 100, 150, 200, 250]}
          sx={{
            "& .MuiPaginationItem-root": {
              color: theme.palette.primary.main,
              fontSize: "14px",
              fontWeight: "bold",
              margin: "0 4px",
            },
            "& .MuiPaginationItem-page.Mui-selected": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
          }}
        />
      )}
    </>
  );
}

const StyledTableRow = styled(TableRow)<{ canRowClick: boolean }>(
  ({ theme, canRowClick }) => ({
    ...(canRowClick && {
      padding: "5px",
      "&.MuiTableRow-hover:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    }),
  })
);
