import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { updateStatus } from "../../../api/services";
import FileUploadContainer from "../../../components/shared/FileUploadContainer";

const DocumentsForm = ({ data, close, subType, typeId, typeData }: any) => {
  const queryClient = useQueryClient();

  const [fileData, setFileData] = useState<{ [key: string]: string }>({});
  const { mutate: postcompanydetails, isLoading: submitloader } = useMutation(
    updateStatus,
    {
      onSuccess: (res: any) => {
        toast.success("Information updated Successfully");
        queryClient.invalidateQueries("entity");
        queryClient.invalidateQueries("status");
        queryClient.invalidateQueries("getuser-id");
        close(true);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleFileChange = (key: string, file: string) => {
    setFileData({
      ...fileData,
      [key]: file,
    });
  };

  const onSubmit = () => {
    const keys = Object.values(fileData);
    const finalData = {
      entityId: data?.id,
      keys,
    };

    postcompanydetails(finalData);
  };

  const allFilesUploaded = () => {
    const requiredFiles = ["ORIGINAL", "SUPPORTING"];
    return requiredFiles.every((file) => fileData[file]);
  };

  return (
    <Box>
      <Typography variant="subtitle2" gutterBottom>
        Original Document
      </Typography>

      <FileUploadContainer
        status={data?.documents}
        actions={<></>}
        data={subType.replace(/\s+/g, "_")}
        typeData={typeData}
        isSupporting={"false"}
        typeId={typeId}
        uploaded={
          !!data?.firmDocuments?.filter((i: any) => i?.subtype === "ORIGINAL")
            .length
        }
        name={data?.firmDocuments
          ?.filter((i: any) => i?.subtype === "ORIGINAL")
          .map((i: any) => i?.originalFileName)}
        url={data?.firmDocuments
          ?.filter((i: any) => i?.subtype === "ORIGINAL")
          .map((i: any) => i?.url)}
        onFileChange={(file: any) => handleFileChange("ORIGINAL", file)}
      />

      <Typography variant="subtitle2" gutterBottom sx={{ marginTop: "20px" }}>
        Supporting Document
      </Typography>

      <FileUploadContainer
        status={data?.documents}
        actions={<></>}
        data={subType.replace(/\s+/g, "_")}
        typeId={typeId}
        isSupporting={"true"}
        typeData={typeData}
        uploaded={
          !!data?.firmDocuments?.filter((i: any) => i?.subtype === "SUPPORTING")
            .length
        }
        name={data?.firmDocuments
          ?.filter((i: any) => i?.subtype === "SUPPORTING")
          .map((i: any) => i?.SUPPORTINGFileName)}
        url={data?.firmDocuments
          ?.filter((i: any) => i?.subtype === "SUPPORTING")
          .map((i: any) => i?.url)}
        onFileChange={(file: any) => handleFileChange("SUPPORTING", file)}
      />

      <Box
        sx={{
          paddingY: 2,
          textAlign: "start",
        }}
      >

        {submitloader ? (
          <>
            <LoadingButton
              loading
              loadingPosition="center"
              startIcon={<SaveIcon />}
              variant="outlined"
              sx={{
                border: "1px solid black",
                "& .MuiCircularProgress-root": {
                  color: "#7643EB",
                },
                "&.MuiButton-outlined": {
                  border: "2px solid #7643EB",
                },
              }}
            ></LoadingButton>
          </>
        ) : (
          <Button
            type="submit"
            onClick={onSubmit}
            variant="contained"
            disabled={!allFilesUploaded()}
            sx={{
              borderRadius: "5px",
            }}
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DocumentsForm;
