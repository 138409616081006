import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TrusteeTabs = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/deals")) {
      setValue(0);
    } else if (location.pathname.startsWith("/transactions")) {
      setValue(1);
    } else {
      setValue(0);
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/deals");
        break;
      case 1:
        navigate("/transactions");
        break;
      default:
        navigate("/deals");
        break;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          bgcolor: "background.paper",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{
            backgroundColor: "#7643EB",
            ".MuiTab-root": {
              fontSize: "0.8rem",
              minWidth: "auto",
              marginLeft: "40px",
              color: "#C6B2F6",
              "&:hover": {
                color: "white",
              },
              "&.Mui-selected": {
                color: "white",
              },
            },
            ".MuiTabs-indicator": {
              backgroundColor: "white",
            },
          }}
        >
          {/* <Tab label="Dashboard" {...a11yProps(0)} /> */}
          <Tab
            label="Deals"
            {...a11yProps(0)}
            onClick={() => {
              navigate("/deals");
            }}
          />
          <Tab
            label="Transactions"
            {...a11yProps(1)}
            onClick={() => {
              navigate("/transactions");
            }}
          />
        </Tabs>
      </Box>
    </Box>
  );
};

export default TrusteeTabs;
