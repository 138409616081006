import { Box, Stack, Typography } from "@mui/material";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { postNotify } from "../../../../api/services/deals";

export default function FeePending({ data, secondUser }: any) {
  const { mutate: notify, isLoading: userLoader } = useMutation(postNotify, {
    onSuccess: (res: any) => {
      toast.success("Notification sent successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleNotifySeller = () => {
    notify({ dealCode: data?.code });
  };

  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Buyer to Pay Platform Fee
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          Buyer needs to pay Platform fee to proceed further with the creation
          of accounts
        </Typography>
      </Stack>
      {/* <Button
        variant="contained"
        onClick={handleNotifySeller}
        sx={{ width: "auto", mt: 2 }}
      >
        view 
      </Button> */}

      {/* <DealSignStatus></DealSignStatus> */}
    </Box>
  );
}
