import { Box, Grid, Typography } from "@mui/material";
import { Deal } from "../../../../interfaces/deal";

interface ApproveBeneficiaryProps {
  dealData: Deal;
}

export default function ApproveBeneficiary({
  dealData,
}: ApproveBeneficiaryProps) {
  const buyerBank = dealData?.dealUsers?.find(
    (dealUser) => dealUser?.userType === "Buyer"
  )?.banking;

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2, color: "#444" }}>
        The buyer has not yet been approved as a beneficiary in the Axis portal.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, color: "#777" }}>
        To proceed with the refund, please approve the buyer's bank account as a
        beneficiary. If the approval has already been completed, please allow a
        few minutes for the update to reflect in our system.
      </Typography>

      <Box my={3}>
        <Typography variant="h6" sx={{ mb: 2, color: "#444" }}>
          Buyer bank account details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="caption">Beneficiary Name</Typography>
            <Typography variant="subtitle2">
              {buyerBank?.beneficiaryName}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="caption">Bank Name</Typography>
            <Typography variant="subtitle2">{buyerBank?.name}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="caption">Bank Account Number</Typography>
            <Typography variant="subtitle2">
              {buyerBank?.accountNumber}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="caption">IFSC Code</Typography>
            <Typography variant="subtitle2">{buyerBank?.ifscCode}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
