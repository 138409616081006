import React from "react";
import { useQuery } from "react-query";
import { Outlet, useNavigate } from "react-router-dom";
import { getUserProfile } from "../api/services";
import Header from "../components/header/Header";
import DashboardTabs from "../components/tabs/DashboardTabs";

import "./Layout.css";

const Layout = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const { data: profiledata } = useQuery("getuser-id", getUserProfile);
  localStorage.setItem("Role", profiledata?.data?.role?.name);

  return (
    <div className="layout-container">
      <div className="header">
        <Header toggleDrawer={toggleDrawer} profiledata={profiledata} />
      </div>
      <div className="dashboard-tabs">
        <DashboardTabs />
      </div>
      <div className="content-container">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
