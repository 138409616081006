import React from 'react';
import { useQuery } from 'react-query';
import { getTrustees } from '../../api/services';
import TrusteeTable from './TrusteeTable';
import NoTrustee from './NoTrusteeFound';
import LinearProgress from '@mui/material/LinearProgress';

const Trustees = () => {
    const { data: trusteeData, isLoading, refetch } = useQuery("getTrusteesData", getTrustees);
    const data = trusteeData?.data?.data || [];

    return (
        <>
            {isLoading ? (
                <LinearProgress />
            ) : (
                <>
                    {data.length === 0 ? (
                        <NoTrustee onCreate={refetch}/>
                    ) : (
                        <TrusteeTable data={data} onDelete={refetch} onCreate={refetch} />
                    )}
                </>
            )}
        </>
    );
};

export default Trustees;
