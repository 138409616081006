import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SignaturePending({ data, secondUser }: any) {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleOpen2 = () => setOpen2(true);

  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);

  const navigate = useNavigate();

  // const viewDocument = () => {
  //   navigate(`/deals/${data?.code}/documentationSignee`);
  // };

  const OpenDocument = () => {
    navigate(`/deals/${data?.code}/documentationSignee`);
  };

  // const renderDocumentButton = () => {
  //   if (
  //     data?.dealSignatures[1]?.status === "Completed" ||
  //     data?.dealSignatures[2]?.status === "Completed"
  //   ) {
  //     return (
  //       <Button variant="contained" color="primary" onClick={viewDocument}>
  //         Sign Document
  //       </Button>
  //     );
  //   } else {
  //     return (
  //       <Button
  //         variant="contained"
  //         color="primary"
  //         onClick={OpenDocument}
  //         // sx={{
  //         //   width: "auto",
  //         //   height: 40,
  //         //   boxShadow: "0px 3px 6px #00000029",
  //         //   textWrap: "nowrap",
  //         // }}
  //       >
  //         View Documentation & sign
  //       </Button>
  //     );
  //   }
  // };

  // const renderSignatureStatus = () => {
  //   if (
  //     data?.dealSignatures[0]?.status === "Pending" ||
  //     data?.dealSignatures[1]?.status === "Pending"
  //   ) {
  //     return `The buyer has published the deal document. and we're now waiting for the remaining signees to complete the signing process.`;
  //     // return `The ${
  //     //   data?.dealSignatures[0]?.postSignResponse?.displayName ||
  //     //   data?.dealSignatures[1]?.postSignResponse?.displayName
  //     // } has not signed the deal document yet.`;
  //   } else if (
  //     data?.dealSignatures[1]?.status === "Completed" ||
  //     data?.dealSignatures[2]?.status === "Completed"
  //   ) {
  //     return `Sign the deal document to complete the signing process.`;
  //   }
  // };

  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Needs other Signatures
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          The Buyer has published the Deal Document. and we're now waiting for
          the remaining signees to complete the signing process.
          {/* The {secondUser?.userType} has unfortunately rejected the business
          deal invitation you sent. You can view the reason provided by the
          {secondUser?.userType} for rejecting the invitation. This will allow
          you to understand their decision and determine if you would like to
          revise your offer or pursue other opportunities. */}
        </Typography>
      </Stack>
      <Button
        variant="contained"
        sx={{ width: "auto", my: "10px" }}
        onClick={OpenDocument}
      >
        View Documentation
      </Button>
    </Box>
  );
}
