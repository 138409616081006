import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  marginRight: 2,
  width: "110px",
  border: "1px solid black",
  "& .MuiCircularProgress-root": {
    color: "#7643EB",
  },
  "&.MuiButton-outlined": {
    border: "2px solid #7643EB",
  },
}));

export const CustomLoadingButton = ({
  submitLoader,
  onClick,
  isDisabled,
  title,
  isReject = false,
  width = "auto",
}: any) => {
  return submitLoader ? (
    <StyledLoadingButton
      loading
      loadingPosition="center"
      startIcon={<SaveIcon />}
      variant="outlined"
      sx={{ width: width }}
    />
  ) : (
    <Button
      disabled={isDisabled}
      type="submit"
      onClick={onClick}
      variant={isReject ? "outlined" : "contained"}
      sx={{ marginRight: 2 , width: width, textWrap: "nowrap" }}
    >
      {title}
    </Button>
  );
};
