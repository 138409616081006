import { Box } from "@mui/material";
import InvitationIssued from "./DealInvitationIssued";
import InvitationRecieved from "./DealInvitationRecieved";
import DealVerificationPending from "./DealVerificationPending";
import DocumentPublished from "./DocumentPublished";
import FeePending from "./FeePending";
import FundsAwaited from "./FundsAwaited";
import InvitaionSent from "./InvitaionSent";
import DealCancelled from "./DealCancelled";
import SignaturePending from "./SignaturePending";
import DealRejected from "./DealRejected";
import FundsReceived from "./FundsReceived";
import VerifyPayment from "./VerifyPayment";
import UnderVerification from "./UnderVerification";
import DealCompleted from "./DealCompleted";

export default function DealinviteSteps({ data, accountOwner }: any) {
  const secondUser = data?.dealUsers?.find(
    (user: any) => user?.userId !== data?.createdById
  );

  const Role = localStorage.getItem("Role");

  const dealSignatures = data?.dealSignatures;

  const Trustee = dealSignatures.find(
    (obj: any) =>
      obj.preSignResponse.displayName === "MITCON Trustee Escrow Agent"
  );

  const isTrusteeSigned = Trustee?.status === "Completed" ? true : false;

  const Admin = dealSignatures.find(
    (obj: any) =>
      obj.preSignResponse.displayName === "TruuPe Admin Confirming Party"
  );

  const isAdminSigned = Admin?.status === "Completed" ? true : false;

  const renderComponent = () => {
    switch (data?.status) {
      case "Invitation Issued":
        return <InvitationIssued data={data} secondUser={secondUser} />;

      case "Deal Rejected":
        return <DealRejected data={data} secondUser={secondUser} />;

      case "Deal Cancelled":
        return <DealCancelled data={data} secondUser={secondUser} />;

      case "Document Published":
        return <DocumentPublished data={data} secondUser={secondUser} />;

      case "Deal Accepted":

      case "Signature Pending":
        return <SignaturePending data={data} secondUser={secondUser} />;

      case "Deal Created":
        return <InvitaionSent data={data} secondUser={secondUser} />;

      case "Verification Pending":
        return (
          <DealVerificationPending
            isTrusteeSigned={isTrusteeSigned}
            Role={Role}
            data={data}
            secondUser={secondUser}
          />
        );

      case "Deal Received":
        return <InvitationRecieved data={data} secondUser={secondUser} />;
      case "Fee Pending":
        return <FeePending data={data} secondUser={secondUser} />;

      case "Payment Verification":
        if (Role === "Admin") {
          return <UnderVerification data={data} secondUser={secondUser} />;
        } else {
          return <VerifyPayment data={data} secondUser={secondUser} />;
        }

      case "Service Payment":
      case "Funds Awaited":
        return <FundsAwaited data={data} secondUser={secondUser} />;

      case "Funds Received":
        return <FundsReceived data={data} secondUser={secondUser} />;

      case "Deal Completed":
        return <DealCompleted />;

      default:
        return null;
    }
  };

  return <Box sx={{ mx: "20px", my: "20px" }}>{renderComponent()}</Box>;
}
