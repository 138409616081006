import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  AddbankingInformation,
  VerifyIfscCode,
  updateBankingInformation,
  verifyBankDetails,
} from "../../../../api/services";
import { Icons } from "../../../../assets";
import FieldEditButton from "../../../../components/buttons/FieldEditButton";
import { CustomLoadingButton } from "../../../../components/buttons/SubmitLoaderButton";
import MultiInputField, {
  Formate,
} from "../../../../components/customInput/MultiInputField";
import FileUploadContainer from "../../../../components/shared/FileUploadContainer";

type Info = {
  ifscCode: string;
  bankAccountNumber: string;
  bankName: string;
  branchName: string;
  BeneficiaryName: string;
};
type BankingFormProps = {
  data: any;
  close: any;
  formType: string;
  bankingData?: any;
};
const BankingForm = ({
  data,
  close,
  formType,
  bankingData,
}: BankingFormProps) => {
  const EntityId = data?.data?.id;
  const [disabled, setdisabled] = useState(false);
  const [bankName, setBankName] = useState(false);
  // const [fileData, setFileData] = useState<{ [key: string]: string }>({});
  const [verifyDetails, setVerifyDetails] = useState(true);
  const [bankGreenTick, setbankGreenTick] = useState(false);
  const queryClient = useQueryClient();
  const [showname, setshowname] = useState(false);
  const [bankDetails, setBankDetails] = useState<any>();
  const [bankingStatus, setBankingStatus] = useState(false);
  const [chequeKey, setChequeKey] = useState("");
  const [supportingKey, setSupportingKey] = useState("");
  const [showSupportingDocUpload, setShowSupportingDocUpload] = useState(false);
  // const [isFileDeleted, setIsFileDeleted] = useState(true);
  const supportingDocExists = bankingData?.relatedDocs?.some(
    (document: any) => document.isSupporting === true
  );
  const [isFileDeleted, setIsFileDeleted] = useState(
    formType === "edit" ? false : true
  );

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm<Info>();

  const CompanyDetails = data;

  const statusDetails = {
    data: {
      banking: bankingStatus,
    },
  };

  useEffect(() => {
    if (formType === "edit" && bankingData) {
      setValue("bankAccountNumber", bankingData?.accountNumber);
      setValue("ifscCode", bankingData?.ifscCode);
      setValue("bankName", bankingData?.name);
      setValue("branchName", bankingData?.branch);
      setValue("BeneficiaryName", bankingData?.beneficiaryName);
      setbankGreenTick(true); // Assume the bank has already been verified
    }
    setBankDetails({
      entityId: bankingData?.entityId,
      name: bankingData?.name,
      accountNumber: bankingData?.accountNumber,
      ifscCode: bankingData?.ifscCode,
      branch: bankingData?.branch,
      beneficiaryName: bankingData?.beneficiaryName,
    });
  }, [formType, bankingData, setValue]);

  useEffect(() => {
    if (CompanyDetails && EntityId) {
      setdisabled(true);
      setBankName(true);
      setVerifyDetails(false);
    }
  }, [CompanyDetails, EntityId]);

  const { mutate: BankDetails, isLoading: BankDetailLoading } = useMutation(
    VerifyIfscCode,
    {
      onSuccess: (BankDetails: any) => {
        setValue("bankName", BankDetails?.data?.BANK);
        setValue("branchName", BankDetails?.data?.BRANCH);
        verifyBank({
          id_number: getValues("bankAccountNumber"),
          ifsc: getValues("ifscCode"),
        });
      },
      onError: (error: any) => {
        if (
          error?.response?.data?.message ===
          "The browser (or proxy) sent a request that this server could not understand."
        ) {
          toast.error("Please enter Aadhar OTP");
        } else {
          toast.error(error?.response?.data?.message);
        }
      },
    }
  );

  const { mutate: verifyBank, isLoading: addBankLoader } = useMutation(
    verifyBankDetails,
    {
      onSuccess: (res: any) => {
        setbankGreenTick(true);
        setValue("BeneficiaryName", res?.data?.data?.full_name);
        setshowname(true);

        const finalData = {
          entityId: EntityId,
          name: getValues("bankName"),
          accountNumber: getValues("bankAccountNumber"),
          ifscCode: getValues("ifscCode"),
          branch: getValues("bankName"),
          beneficiaryName: getValues("BeneficiaryName"),
        };
        setBankDetails(finalData);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
        if (
          error?.response?.data?.message ===
          "The browser (or proxy) sent a request that this server could not understand."
        ) {
        }
      },
    }
  );

  const { mutate: postbankingdetails, isLoading: submitloader } = useMutation(
    AddbankingInformation,
    {
      onSuccess: (res: any) => {
        toast.success("Information Updated Successfully");
        queryClient.invalidateQueries("status-data");
        queryClient.invalidateQueries("banking");
        queryClient.invalidateQueries("getuser-id");
        queryClient.invalidateQueries("entity");
        close(true);
        reset();
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: updateBankingDetails, isLoading: updateLoader } = useMutation(
    (params: { id: string; data: any }) =>
      updateBankingInformation(params.id, params.data), // Modify to accept id and data
    {
      onSuccess: (res: any) => {
        toast.success("Banking Information Updated Successfully");
        queryClient.invalidateQueries("status-data");
        queryClient.invalidateQueries("banking");
        queryClient.invalidateQueries("getuser-id");
        queryClient.invalidateQueries("entity");
        close(true);
        reset();
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = () => {
    // const bankingKeys = [chequeKey];

    // const updatedBankDetails = {
    //   ...bankDetails,
    //   bankingKeys,
    // };
    // postbankingdetails(updatedBankDetails);
    if (formType === "edit") {
      const currentBankingId = bankingData?.id;
      const updatedBankDetails = {
        ...bankDetails,
      };

      if (currentBankingId) {
        updateBankingDetails({
          id: currentBankingId,
          data: updatedBankDetails,
        });
      } else {
        console.error("Banking ID not found.");
      }
    } else {
      const bankingKeys = [chequeKey, supportingKey];

      const updatedBankDetails = {
        ...bankDetails,
        bankingKeys,
      };
      postbankingdetails(updatedBankDetails);
    }
  };

  // const handleFileChange = (fileKey: any) => {
  //   setChequeKey(fileKey);
  // };
  // Handle file change for cheque
  const handleChequeFileChange = (fileKey: any) => {
    setChequeKey(fileKey);
  };

  // Handle file change for supporting document
  const handleSupportingFileChange = (fileKey: any) => {
    setSupportingKey(fileKey);
  };

  const resetData = () => {
    reset({
      bankAccountNumber: "",
      ifscCode: "",
      branchName: "",
      bankName: "",
      BeneficiaryName: "",
    });
  };

  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Banking Information
        </Typography>
        <form>
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <MultiInputField
              control={control}
              label={"Bank Account Number"}
              name={"bankAccountNumber"}
              key="bankAccountNumber"
              variant={Formate.OnlyNumbers}
              disabled={statusDetails?.data?.banking || bankGreenTick}
              type="text"
              errors={errors}
              sx={{ width: "300px" }}
            />

            {(statusDetails?.data?.banking || bankGreenTick) && (
              <>
                <Box
                  sx={{
                    marginTop: "12px",
                    display: " flex",
                    alignItems: "center",
                    marginLeft: "15px",
                  }}
                >
                  <Box marginTop={"2.4vh"}>
                    <img
                      height={"25px"}
                      src={Icons.greenCheck}
                      alt="loading...."
                    />
                  </Box>
                </Box>

                {!statusDetails?.data?.banking && (
                  <>
                    <Box marginTop={"3vh"}>
                      <FieldEditButton
                        onclick={() => {
                          setbankGreenTick(false);
                          resetData();
                        }}
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
            }}
          >
            <MultiInputField
              control={control}
              label={"IFSC Code"}
              name={"ifscCode"}
              key="ifscCode"
              variant={Formate.StrictUpperCase}
              disabled={statusDetails?.data?.banking || bankGreenTick}
              type="text"
              errors={errors}
              sx={{ width: "300px" }}
            />

            {BankDetailLoading || addBankLoader ? (
              <>
                <CircularProgress
                  sx={{
                    marginLeft: "25px",
                    marginTop: "15px",
                  }}
                  size={"2rem"}
                  color="primary"
                />
              </>
            ) : (
              <>
                {bankGreenTick || statusDetails?.data?.banking ? (
                  <Box display={"flex"} alignItems={"center"}>
                    <Box
                      sx={{
                        marginTop: "12px",
                        display: " flex",
                        alignItems: "center",
                      }}
                    >
                      <Box marginTop={"2.4vh"}>
                        <img
                          height={"25px"}
                          src={Icons.greenCheck}
                          alt="loading...."
                        />
                      </Box>

                      {!statusDetails?.data?.banking && (
                        <>
                          <Box marginTop={"1.5vh"}>
                            <FieldEditButton
                              onclick={() => {
                                setbankGreenTick(false);
                                resetData();
                              }}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => {
                        const IfscCode = getValues().ifscCode;
                        if (IfscCode === undefined) {
                          toast.error("Please Enter IFSC Code ");
                        } else {
                          BankDetails(IfscCode);
                        }
                      }}
                      sx={{
                        marginTop: "3vh",
                        padding: 0,
                        fontSize: "12px",
                        height: "30px",
                      }}
                    >
                      Verify
                    </Button>
                  </>
                )}
              </>
            )}
          </Box>

          {bankName && (
            <>
              <MultiInputField
                control={control}
                label={"Bank Name"}
                name={"bankName"}
                key="bankName"
                variant={Formate.AlphabetsOnly}
                disabled={statusDetails?.data?.banking || bankGreenTick || true}
                type="text"
                errors={errors}
                sx={{ width: "300px" }}
              />

              <MultiInputField
                control={control}
                label={"Branch Name"}
                name={"branchName"}
                key="branchName"
                variant={Formate.AlphabetsOnly}
                disabled={statusDetails?.data?.banking || bankGreenTick || true}
                type="text"
                errors={errors}
                sx={{ width: "300px" }}
              />

              {/* {(showname || statusDetails?.data?.banking) && ( */}
              <MultiInputField
                control={control}
                label={"Beneficiary Name"}
                name={"BeneficiaryName"}
                key="BeneficiaryName"
                variant={Formate.AlphabetsOnly}
                disabled={statusDetails?.data?.banking || bankGreenTick || true}
                type="text"
                errors={errors}
                sx={{ width: "300px" }}
              />
              {/* )} */}
            </>
          )}
        </form>
        <Stack my={"10px"}>
          <Box>
            {formType === "edit" ? (
              <>
                {bankingData?.relatedDocs.map(
                  (document: any, index: number) => (
                    <Box mb={2} key={index}>
                      <FileUploadContainer
                        typeId={bankingData?.id}
                        actions={<></>}
                        data={document.subtype}
                        typeData={document.type}
                        name={document.originalFileName}
                        url={document.url}
                        subType={document.subtype}
                        onFileChange={(key) => {
                          if (key === "") {
                            setIsFileDeleted(true);
                          } else {
                            setIsFileDeleted(false);
                          }
                        }}
                        isSupporting={document.isSupporting ? "true" : "false"}
                        showIcons={true}
                        uploaded={true}
                      />
                    </Box>
                  )
                )}
                <Box>
                  {/* Add Supporting Doc Button */}
                  {!showSupportingDocUpload && !supportingDocExists && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setShowSupportingDocUpload(true)}
                      size="small"
                    >
                      Add Supporting Doc
                    </Button>
                  )}

                  {/* Show new FileUploadContainer for the supporting document when button is clicked */}
                  {showSupportingDocUpload && (
                    <Box>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography variant="subtitle2">
                          Supporting Document
                        </Typography>
                        <Tooltip title="Remove document">
                          <IconButton
                            aria-label="edit"
                            onClick={() => setShowSupportingDocUpload(false)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      <FileUploadContainer
                        typeId={bankingData?.id}
                        actions={<></>}
                        data={"CANCELLED_CHEQUE"}
                        subType={"CANCELLED_CHEQUE"}
                        isSupporting="true"
                        typeData={"BANKING"}
                        onFileChange={(key) => {
                          if (key === "") {
                            setIsFileDeleted(true);
                          } else {
                            setIsFileDeleted(false);
                          }
                        }}
                        showIcons={true}
                        uploaded={false} // Mark as not uploaded initially
                      />
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <Stack direction={"column"} gap={1}>
                <Typography variant="subtitle2">Cancelled Cheque</Typography>
                <FileUploadContainer
                  typeId={null}
                  actions={<></>}
                  data={"CANCELLED_CHEQUE"}
                  typeData={"BANKING"}
                  onFileChange={(key) => {
                    if (key === "") {
                      setIsFileDeleted(true);
                    } else {
                      setIsFileDeleted(false);
                      // handleFileChange(key);
                      handleChequeFileChange(key);
                    }
                  }}
                />
                <Typography variant="subtitle2">Supporting Document</Typography>
                <FileUploadContainer
                  typeId={null}
                  actions={<></>}
                  data={"CANCELLED_CHEQUE"}
                  isSupporting="true"
                  typeData={"BANKING"}
                  onFileChange={(key) => {
                    if (key === "") {
                      setIsFileDeleted(true);
                    } else {
                      setIsFileDeleted(false);
                      // handleFileChange(key);
                      handleSupportingFileChange(key);
                    }
                  }}
                />
              </Stack>
            )}
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          paddingY: 2,
          marginX: 0,
          alignContent: "center",
          justifySelf: "flex-end",
        }}
      >
        <CustomLoadingButton
          submitLoader={submitloader}
          onClick={onSubmit}
          isDisabled={
            !getValues("bankAccountNumber") ||
            !getValues("ifscCode") ||
            isFileDeleted
          }
          title={formType === "edit" ? "Update" : "Submit"}
        />
      </Box>
    </>
  );
};

export default BankingForm;
