import { Box, Button, Card, CardContent } from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Lottie from "lottie-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import animationData from "./Lottie.json";

interface GoBackProps {
  open: boolean;
  onClose: () => void;
  data?: any;
  onSuccess?: any;
  formDate?: any;
  dealId?: any;
}

const GoBack: React.FC<GoBackProps> = ({
  formDate,
  dealId,
  open,
  onClose,
  onSuccess,
}: any) => {
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          p: 5,
          maxWidth: "800px",
        }}
      >
        {/* Increased maxWidth */}
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {/* Center aligning content */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "#FFFFFF",
              borderRadius: "8px",
              textAlign: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginBottom: 2,
              }}
            >
              <Lottie
                animationData={animationData}
                style={{ height: 150, width: 150 }}
              />
            </Box>
            <Typography variant="h3" id="modal-title" sx={{ mb: 2 }}>
              {/* Adjusted margin */}
              Deal Document Published Successfully!
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", opacity: 0.4, mb: 2 }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Typography>
            <Button variant="contained" color="primary" onClick={onSuccess}>
              Go Back
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default GoBack;
