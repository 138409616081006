import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  Breadcrumbs,
  Card,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getDealsDataById } from "../../../../api/services";
import Status from "../../../../components/status/Status";
import { Deal } from "../../../../interfaces/deal";
import DealTabs from "../deal-tabs/DealTabs";
import AddBeneficiary from "./AddBeneficiary";
import ApproveBeneficiary from "./ApproveBeneficiary";
import FundsSummary from "./FundsSummary";
import RefundForm from "./refunds/RefundForm";

export default function ManageFunds() {
  const { code }: any = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useQuery(
    ["deal", code],
    getDealsDataById,
    {
      onError: () => {
        navigate("/deals");
      },
    }
  );
  const dealData: Deal = data?.data || {};
  localStorage.setItem("DealCode", dealData?.code);

  const buyerBank = dealData?.dealUsers?.find(
    (dealUser) => dealUser?.userType === "Buyer"
  )?.banking;

  if (isLoading) return <LinearProgress />;
  if (isError) return <p>Error fetching data...</p>;

  return (
    <Box
      sx={{
        padding: "0px 20px",
        width: "98vw",
        minHeight: "83vh",
        marginTop: "20px",
      }}
    >
      <Card
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "8px",
          minHeight: "83vh",
          py: "3vh",
          px: "1.5vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "60px",
          }}
        >
          <Box display="flex" alignItems="center" color={"#7643EB"}>
            <IconButton
              size="small"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon fontSize="small" sx={{ color: "#7643EB" }} />
            </IconButton>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ color: "#7643EB", fontSize: "16px" }}
            >
              <Link underline="hover" color="#7643EB" href="/deals">
                Deals
              </Link>
              <Link underline="hover" color="#7643EB" href={`/deals/${code}`}>
                {code}
              </Link>
              <Link
                underline="hover"
                color="#7643EB"
                href={`/deals/${code}/documentationSignee`}
              >
                Manage Funds
              </Link>
            </Breadcrumbs>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h5" my="10px" ml={2}>
            {code}
          </Typography>
          <Status variant={dealData?.status} />
        </Box>
        <Box sx={{ ml: 2 }}>
          <DealTabs data={dealData} />
        </Box>
        <Divider sx={{ width: "100%", opacity: "0.5" }} />
        <FundsSummary dealData={dealData} />
        <Box sx={{ p: 2 }}>
          <Typography variant="h4" align="left" sx={{}}>
            Initiate Refund to Buyer
          </Typography>
        </Box>
        <Card sx={{ backgroundColor: "#F9F9F9", p: 2 }}>
          {buyerBank?.code == null ? (
            <AddBeneficiary dealData={dealData} />
          ) : buyerBank?.postBeneficiaryResponse == null ? (
            <ApproveBeneficiary dealData={dealData} />
          ) : (
            <RefundForm dealData={dealData} />
          )}
        </Card>
        {/* <Card sx={{ backgroundColor: "#F9F9F9", p: 2 }}>
          <RefundForm dealData={dealData} />
        </Card> */}
      </Card>
    </Box>
  );
}
