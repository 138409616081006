import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import * as React from "react";

type AadharAlertModalProps = {
  username: string;
  openDialogue: boolean;
  aadharNumber: string;
  redirectUrl: string;
  closeModel: (value: boolean) => void;
};
export default function AadharAlertModal({
  username,
  openDialogue,
  aadharNumber,
  redirectUrl,
  closeModel,
}: AadharAlertModalProps) {
  const handleClose = () => {
    closeModel(false);
  };

  return (
    // <React.Fragment>
    <Dialog
      open={openDialogue}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          boxShadow: "none",
        },
        "& .MuiPaper-root": {
          boxShadow: "none",
        },
      }}
    >
      <DialogTitle color={"red"} id="alert-dialog-title">
        {"Alert!"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please make sure to sign with the Aadhar number{" "}
          {aadharNumber ? (
            <>
              <strong>**** **** {aadharNumber.slice(-4)}</strong> belonging to{" "}
              <strong>{username}</strong>
            </>
          ) : (
            <>
              belonging to <strong>{username}</strong>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            window.open(redirectUrl);
            handleClose();
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
    // </React.Fragment>
  );
}
