import { Box } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authimage } from "../../assets";
import Truupeheader from "../../layout/Truupeheader";
import LoginForm from "./LoginForm";

export default function Login() {
  const [formdata, setformdata] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("access_token");
    const isAdmin = Cookies.get("isAdmin") === "true";
    if (token) {
      navigate(isAdmin ? "/dashboard" : "/deals");
    }
  }, [navigate]);

  return (
    <Box height="100vh">
      <Truupeheader />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "calc(100% - 100px)",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          width={{ xs: "100%", md: "60%" }}
          sx={{
            padding: "20px",
            borderRight: { md: "2px solid #33333333" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img
              height="auto"
              width="70%"
              src={authimage}
              alt="Authentication"
            />
          </Box>
        </Box>
        <Box
          width={{ xs: "100%", md: "40%" }}
          sx={{
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoginForm setformdata={setformdata} />
        </Box>
      </Box>
    </Box>
  );
}
