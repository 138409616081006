import { LinearProgress } from "@mui/material";
import { useQuery } from "react-query";
import { getCoupons } from "../../api/services/deals";
import CouponsTable from "./CouponsTable";
import NoCouponsFound from "./NoCouponsFound";

export default function Coupons() {
  const { data, isLoading, refetch } = useQuery("get-Coupons", getCoupons);
  const CouponsData = data?.data?.data || [];

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          {CouponsData.length === 0 ? (
            <NoCouponsFound />
          ) : (
            <CouponsTable data={CouponsData} onDelete={refetch} />
          )}
        </>
      )}
    </>
  );
}
