// AnimatedBox.tsx
import { motion } from "framer-motion";
import { Box } from "@mui/material";

export const AnimatedBox: React.FC<{
  children: React.ReactNode;
  delay?: number;
}> = ({ children, delay = 0 }) => (
  <motion.div
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay, duration: 0.5 }}
  >
    <Box>{children}</Box>
  </motion.div>
);
