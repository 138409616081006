import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Breadcrumbs, Button, IconButton, Link } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Status from "../../../../components/status/Status";
import TimeLineModal from "../../entity-components/TimeLineModal";

const EntityTop = ({ data }: any) => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleEntityClick = () => {
    navigate(`/entities`);
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Box
          display="flex"
          alignItems="center"
          color={"#7643EB"}
          onClick={handleEntityClick}
          style={{ cursor: "pointer" }}
        >
          <IconButton
            size="medium"
            onClick={() => {
              navigate("/entities", { replace: true });
            }}
          >
            <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
          </IconButton>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: "#7643EB", fontSize: "18px" }}
          >
            <Link underline="hover" color="#7643EB" href="/entities">
              Entities
            </Link>
            <Link
              underline="hover"
              color="#7643EB"
              href={`/entities/${data?.data?.id}`}
            >
              TRPE000{data?.data?.id}
            </Link>
          </Breadcrumbs>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          marginTop="20px"
          marginRight="20px"
        >
          <Button
            variant="outlined"
            sx={{
              borderRadius: "5px",
              marginRight: "20px",
              border: "1px solid #7643EB",
              height: "35px",
            }}
            onClick={handleModalOpen}
          >
            Show Timeline
          </Button>
          <Status variant={data?.data?.entityStatus} showTooltip={true} />
        </Box>
      </Box>
      <TimeLineModal
        open={modalOpen}
        handleClose={handleModalClose}
        data={data?.data}
      />
    </>
  );
};

export default EntityTop;
