import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { dealtransaction } from "../../../../api/services/deals";
import TransactionTableSortable from "../../../transactions/TransactionTableSortable";
import DealAmountCard from "../deal-amount/DealTransactionsCards";
import EscrowAccountDetails from "../deal-amount/EscrowDetails-InfoCard";
import DealTimeline from "../deal-timeline/DealTimeline";
import DealinviteSteps from "./DealinviteSteps";
// import DealSignStatus from "./DealSignStatus";

export default function DealDetails({ data, accountOwner }: any) {
  const secondUser = data?.dealUsers?.find(
    (user: any) => user?.userId !== data?.createdById
  );
  const { code } = useParams();
  const { data: transactionData, isLoad }: any = useQuery(
    ["dealtransactions", code],
    dealtransaction
  );

  return (
    <Box>
      <DealinviteSteps data={data} accountOwner={accountOwner} />
      <Divider />

      {(data?.status === "Funds Received" ||
        data?.status === "Payment Verification" ||
        data?.status === "Deal Completed" ||
        data?.status === "Funds Awaited") && (
        <>
          <EscrowAccountDetails data={data} profiledata={accountOwner} />
          <Divider />
        </>
      )}
      <DealAmountCard data={data} />
      <Divider />
      {(data?.status === "Funds Received" ||
        data?.status === "Payment Verification" ||
        data?.status === "Deal Completed" ||
        data?.status === "Funds Awaited") && (
        <>
          {transactionData?.data?.count > 0 && (
            <>
              {" "}
              <Typography
                variant="h2"
                sx={{ fontWeight: "500", px: "20px", paddingTop: "2vh" }}
              >
                Transactions
              </Typography>
              <Box sx={{ px: "20px", paddingTop: "2vh" }}>
                {isLoad ? (
                  <CircularProgress />
                ) : (
                  <TransactionTableSortable
                    dealData={data}
                    transactionData={transactionData}
                  />
                )}
              </Box>
            </>
          )}
        </>
      )}
      <Divider />
      <DealTimeline data={data} />
    </Box>
  );
}
