import { Button, Divider, Grid, styled } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Lottie from "lottie-react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getComapanyDetailsById } from "../../../api/services";
import Tick from "./Lottie.json";

function EntitySuccess() {
  const { id } = useParams();

  const { data: EntityData }: any = useQuery(
    ["entity", id],
    getComapanyDetailsById,
    {
      enabled: !!id,
    }
  );

  const data = EntityData?.data;

  // console.log(data, "success data ");
  const navigate = useNavigate();

  const StyledDfAcJc = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  }));

  const navigateHome = () => {
    navigate("/entities", { replace: true });
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        marginX: "8%",
        minHeight: "85vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box sx={{ p: "20px 50px" }}>
          <Box sx={{ pb: 4 }}>
            <Typography variant="h3" color="#7643EB">
              TRP0000{data?.id}
            </Typography>
          </Box>

          <>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography variant="body1">Entity Type</Typography>
                <Typography variant="subtitle2">
                  {data?.companyType.replaceAll("_", " ")}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1">Entity Category</Typography>
                <Typography variant="subtitle2">
                  {data?.category || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1">Firm Name</Typography>
                <Typography variant="subtitle2">
                  {data?.companyName || "NA"}
                </Typography>
              </Grid>
            </Grid>

            {/* <Grid container my={"10px"} spacing={3}>
              <Grid item xs={4}>
                <Typography variant="body1">Email Address</Typography>
                <Typography variant="subtitle2">
                  {data?.emailAddress || data?.user?.emailAddress || "NA"}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body1">Address</Typography>
                <Typography variant="subtitle2">
                  {data?.contactAddress || data?.directors[0]?.address || "NA"}
                </Typography>
              </Grid>
            </Grid> */}
          </>
        </Box>
        <Divider />
        <StyledDfAcJc>
          <Box
            maxWidth={"170px"}
            maxHeight={"170px"}
            marginBottom={-1}
            marginTop={-1}
          >
            <Lottie animationData={Tick} loop={true} autoplay />
          </Box>
          <Typography variant="h4">KYC/KYB Approved Successfully!</Typography>
          <Typography
            fontSize={"18px"}
            sx={{
              margin: "0 auto",
              paddingLeft: "20%",
              paddingRight: "20%",
              paddingTop: "15px",
              paddingBottom: "30px",
            }}
          >
            You have reviewed and approved the KYC/KYB verification documents
            submitted by {data?.user?.name || "NA"}. They have now been granted
            access to create and participate in deals on our platform
          </Typography>

          <Button
            type="submit"
            onClick={navigateHome}
            variant="contained"
            sx={{
              paddingX: 10,
              paddingY: 3,
              fontSize: "18px",
            }}
          >
            Go Back To Entities
          </Button>
        </StyledDfAcJc>
      </Box>
    </Box>
  );
}

export default EntitySuccess;
