import { Box, Card, LinearProgress, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getCouponData } from "../../api/services/deals";
import { Coupon } from "../../interfaces/coupon";
import CouponTop from "./CouponTop";

export default function ViewCoupon() {
  const { couponId } = useParams();
  // const couponId = coupon?.id;
  const { data: couponData, isLoading: couponDataLoading } = useQuery<{
    data: Coupon;
  }>(["get-Coupons", couponId], () => getCouponData({ id: couponId }), {
    enabled: !!couponId,
  });
  const coupon = couponData?.data;
  if (couponDataLoading) {
    return <LinearProgress></LinearProgress>;
  }
  return (
    <>
      <Card sx={{ mt: 3, p: 2 }}>
        <CouponTop coupon={coupon} />
        <Box>
          <Typography variant="h6">COUPON CODE - {coupon?.id}</Typography>
        </Box>
      </Card>
    </>
  );
}
