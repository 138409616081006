import { ArrowDropDown, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { newDeal } from "../../assets";
import Status from "../../components/status/Status";
import { User } from "../../interfaces/user";
import CreateUser from "./user-forms/CreateUser";
import EditUser from "./user-forms/EditUser";

interface UserTableProps {
  data: User[];
  onDelete: () => void;
}

const UserTable: React.FC<UserTableProps> = ({ data, onDelete }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [filterStatus, setFilterStatus] = useState<string[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isCreateUserOpen, setIsCreateUserOpen] = useState<boolean>(false);
  const [isEditUserOpen, setIsEditUserOpen] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const statusOptions: string[] = [
    "Kyc Submitted",
    "Verify Changes",
    "Verified",
    "Rejected",
    "In Progress",
    "Not Created",
  ];

  const clearFilters = () => {
    setFilterStatus([]);
    setSearchTerm("");
  };

  const filteredData: User[] = useMemo(() => {
    return data.filter((User: User) => {
      const userStatus =
        User.entities.length > 0 ? User.entities[0].status : "Not Created";

      return (
        (filterStatus.length === 0 || filterStatus.includes(userStatus)) &&
        (!searchTerm ||
          User.name.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    });
  }, [data, filterStatus, searchTerm]);

  const totalItems: number = filteredData.length;
  const paginatedData: User[] = filteredData.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );

  const handleStatusChange = (event: any) => {
    const value = event.target.value;
    setFilterStatus(typeof value === "string" ? value.split(",") : value);
  };

  const handleSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 500);

  useEffect(() => {
    setCurrentPage(0);
  }, [filteredData]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const cellStyle = {
    textAlign: "left",
    marginLeft: "25px",
    padding: "5px",
  };
  const hoverCellStyle = {
    textAlign: "left",
    marginLeft: "25px",
    cursor: "pointer",
    padding: "5px",
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
          padding: "10px",
          height: "84vh",
        }}
      >
        <Grid item xs={12}>
          <Paper
            sx={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #1212121A",
              borderRadius: "10px",
              opacity: 1,
              padding: "20px",
              marginBottom: "20px",
            }}
          >
            <Grid container alignItems="center" spacing={2} marginBottom="20px">
              <Grid item xs={8}>
                <Stack direction={"row"} gap={2}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <TextField
                      placeholder="Search By User Name "
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleSearch(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton size="small">
                              <Search fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          width: "500px",
                          height: "40px",
                          boxShadow: "0px 6px 14px #36408D08",
                          border: "1px solid #2B2D221C",
                          borderRadius: "10px",
                          opacity: 1,
                          fontSize: "16px",
                          fontFamily: "Mundial, sans-serif",
                        },
                        inputProps: {
                          style: {
                            fontFamily: "Mundial, sans-serif",
                            fontSize: "16px",
                          },
                        },
                      }}
                    />
                  </Box>
                  <Select
                    multiple
                    value={filterStatus}
                    onChange={handleStatusChange}
                    variant="outlined"
                    IconComponent={ArrowDropDown}
                    displayEmpty
                    renderValue={(selected) =>
                      selected.length > 0 ? (
                        <Box display="flex" alignItems="center">
                          Entity Status
                          <Box
                            component="span"
                            borderRadius="50%"
                            color="#FFFFFF"
                            fontSize="10px"
                            marginLeft="5px"
                            width="15px"
                            height="15px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            style={{
                              backgroundColor: theme.palette.primary.main,
                            }}
                          >
                            {selected.length}
                          </Box>
                        </Box>
                      ) : (
                        "Entity Status"
                      )
                    }
                    style={{
                      width: "150px",
                      height: "40px",
                      border: "1px solid #1212121A",
                      borderRadius: "10px",
                      opacity: 0.6,
                      boxShadow: "0px 6px 14px #36408D08",
                      fontSize: "14px",
                      fontFamily: "Mundial, sans-serif",
                      color: "#1D1D1D",
                      marginLeft: "10px",
                    }}
                    inputProps={{
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "14px",
                      },
                    }}
                    SelectDisplayProps={{
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "12px",
                      },
                    }}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        <Checkbox checked={filterStatus.indexOf(option) > -1} />
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                  {filterStatus.length > 0 && (
                    <Button
                      variant="outlined"
                      onClick={clearFilters}
                      sx={{
                        border: "1.5px solid #7643EB",
                        height: "38px",
                        marginLeft: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      Clear Filters
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>

            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "10px",
                overflow: "hidden",
                border: "1px solid #1212121A",
              }}
            >
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "#f3f3f3",
                    fontFamily: "Mundial",
                  }}
                >
                  <TableRow>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                        S No.
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                        User Name
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                        Mobile Number
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                        Email Address
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                        Created Date
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                        Entity Name
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                        Entity Status
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ fontFamily: "Mundial" }}>
                  {paginatedData?.length >= 1 ? (
                    paginatedData.map((User, index) => {
                      console.log("User", User);
                      return (
                        <TableRow key={User.id}>
                          <TableCell align="left" sx={{ padding: "5px" }}>
                            <Typography variant="body1" sx={cellStyle}>
                              {currentPage * itemsPerPage + index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ padding: "5px" }}>
                            <Typography variant="body1" sx={cellStyle}>
                              {User.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ padding: "5px" }}>
                            <Typography variant="body1" sx={cellStyle}>
                              {User.mobileNumber}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ padding: "5px" }}>
                            <Typography variant="body1" sx={cellStyle}>
                              {User.emailAddress}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ padding: "5px" }}>
                            <Typography variant="body1" sx={cellStyle}>
                              {moment(User.createdAt).format("DD MMM YYYY")}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ padding: "5px" }}>
                            <Typography
                              variant="body1"
                              sx={hoverCellStyle}
                              onClick={() =>
                                User?.entities[0]
                                  ? navigate(`/entities/${User.entities[0].id}`)
                                  : toast.error("Entity not created")
                              }
                            >
                              {User?.entities[0]?.companyName ||
                                User?.entities[0]?.directors[0]?.name ||
                                "ENTITY NOT CREATED"}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ padding: "5px" }}>
                            <Box sx={{ textAlign: "left", marginLeft: "25px" }}>
                              {User?.entities[0] ? (
                                <Status variant={User.entities[0].status} />
                              ) : (
                                <Status variant="Not Created" />
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <>
                      <TableCell colSpan={7} align="center">
                        <img width={"30%"} src={newDeal} alt="loading...." />
                        <Typography variant="h5">
                          No data available...
                        </Typography>
                      </TableCell>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component="div"
              count={totalItems}
              page={currentPage}
              onPageChange={handlePageChange}
              rowsPerPage={itemsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={[25, 50, 100, 150, 200, 250]}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: theme.palette.primary.main,
                  height: "27px",
                  minWidth: "27px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  margin: "0 4px",
                },
                "& .MuiPaginationItem-page.Mui-selected": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>

      <CreateUser
        open={isCreateUserOpen}
        onClose={() => setIsCreateUserOpen(false)}
      />
      <EditUser
        UserId={selectedUserId}
        open={isEditUserOpen}
        onClose={() => {
          setIsEditUserOpen(false);
          setSelectedUserId(null);
        }}
      />
    </>
  );
};

export default UserTable;
