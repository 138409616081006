import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import ApproveModel from "./DealApproveStep";
import RejectionModal from "./Reasonrejection";

export default function InvitationRecieved({ data, secondUser }: any) {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleOpen2 = () => setOpen2(true);

  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);

  return (
    <Box sx={{ px: "20px", py: "20px" }}>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Deal Invitation Received
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          The {secondUser?.userType} has sent you a business deal invitation.
          You can now decide whether to accept or reject the invitation. If you
          reject the invitation, you have to provide a reason for the rejection,
          which will be visible to the {secondUser?.userType}. This will allow
          for transparent communication and help both parties understand the
          decision.
        </Typography>
      </Stack>
      <RejectionModal
        open={open}
        onClose={handleClose}
        data={data}
        secondUser={secondUser}
      />
      <ApproveModel
        open={open2}
        onClose={handleClose2}
        data={data}
        secondUser={secondUser}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          textAlign: "left",
          gap: "20px",
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpen}
          sx={{
            width: 150,
            height: 40,
            border: "2px solid #7643EB",
          }}
        >
          Reject Deal
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen2}
          sx={{
            width: 150,
            height: 40,
            boxShadow: "0px 3px 6px #00000029",
          }}
        >
          Approve Deal
        </Button>
      </Box>
    </Box>
  );
}
