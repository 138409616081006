import { Box, FormHelperText, InputLabel, Typography } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import * as React from "react";

const BootstrapTextarea = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: "-6px",
  },

  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: "14px",
    width: "500px",
    padding: "14px 12px",
    color: "black",
    minHeight: "64px", // Minimum height for 3 rows
    lineHeight: 1.5,
  },
  "&::placeholder": {
    fontFamily: "mundial",
    fontSize: "8px",
  },
}));

type DealTextareaFieldProps = {
  title?: string;
  iD?: string;
  placeholder: string;
  errors?: any;
  sx?: any;
  field: any | null;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const DealTextareaField = ({
  iD = "",
  placeholder,
  errors,
  title = "",
  sx,
  field,
  isDisabled = false,
  onChange,
}: DealTextareaFieldProps) => {
  return (
    <Box my={"14px"}>
      <InputLabel
        shrink
        htmlFor={iD}
        sx={{
          fontSize: "0px",
          fontWeight: 300,
          "&.Mui-focused": {
            color: "#00000099",
          },
        }}
      >
        <Typography variant="subtitle2">
          {title}
          <span style={{ color: "red", marginLeft: "2px" }}>*</span>
        </Typography>
      </InputLabel>

      <BootstrapTextarea
        disabled={isDisabled}
        id={iD}
        placeholder={placeholder}
        multiline
        sx={{
          ...sx,
          p: "0",
        }}
        onChange={onChange}
        {...field}
      />

      {errors[iD]?.message && (
        <FormHelperText error sx={{ mt: "0", mb: "5px" }}>
          <Typography
            variant="caption"
            sx={{ color: "red", paddingLeft: 1, fontSize: "11px" }}
          >
            {errors[iD]?.message}
          </Typography>
        </FormHelperText>
      )}
    </Box>
  );
};

export default DealTextareaField;
