import { Card, Divider, LinearProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getEntityDetails, updateEntityStatus } from "../../../api/services";
import { CustomLoadingButton } from "../../../components/buttons/SubmitLoaderButton";
import Quill from "../../../components/quill";
import { toTitleCase } from "../../../utils/formValidations";
import DocumentCheckList from "./DocumentCheckList";

// Define types for the API response data.
interface Document {
  id: string;
  subtype: string;
}

interface BankData {
  id: string;
  name: string;
  beneficiaryName: string;
  relatedDocs: Document[];
}

interface Director {
  id: string;
  name: string;
  documents: Document[];
}

interface EntityResponse {
  data: {
    firmDocuments: Document[];
    bankingInformation: BankData[];
    signatoryDirectors: Director[];
  };
}

// Main Component
const RejectionPage: React.FC = () => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const [reason, setReason] = useState<string>("");
  const [selectedDocs, setSelectedDocs] = useState<string[]>([]);
  const [error, setError] = useState<string>("");

  // Fetch entity details with React Query
  const {
    data: EntityData,
    isLoading: isEntityLoading,
    isError,
  } = useQuery<EntityResponse>(["entity", id], () => getEntityDetails(id));

  // Update entity status mutation
  const { mutate: entityStatus, isLoading } = useMutation(updateEntityStatus, {
    onSuccess: () => {
      toast.success("Entity rejected successfully");
      navigate(`/entities/${id}`, { replace: true });
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    },
  });

  const handleParentChange = (relatedDocs: Document[]) => {
    const docIds = relatedDocs.map((doc) => doc.id);
    setSelectedDocs((prev) =>
      docIds.every((id) => prev.includes(id))
        ? prev.filter((id) => !docIds.includes(id))
        : [...prev, ...docIds]
    );
  };

  const handleChildChange = (docId: string, checked: boolean) => {
    setSelectedDocs((prev) =>
      checked ? [...prev, docId] : prev.filter((id) => id !== docId)
    );
  };

  const handleReject = () => {
    if (!reason.trim()) {
      setError("Rejection reason is required.");
      return;
    }
    const payload = {
      id,
      status: "Rejected",
      reason: reason,
      reqDocIds: selectedDocs.join(","),
    };
    entityStatus(payload);
  };

  if (isEntityLoading) return <LinearProgress />;
  if (isError) return <p>Error fetching data...</p>;

  const {
    firmDocuments = [],
    bankingInformation = [],
    signatoryDirectors = [],
  } = EntityData?.data || {};

  return (
    <Card
      sx={{
        mt: 2,
        width: "70%",
        ml: "15%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          // height: "83vh",
          backgroundColor: "#f5f5f5",
          padding: 2,
        }}
      >
        <Typography variant="h5" mt={1}>
          Select Documents to Reject (if any)
        </Typography>
        <Divider sx={{ width: "100%", mb: 2, mt: 1 }} />
        <Box ml={2} width={"96%"} mb={2}>
          <Typography variant="subtitle1">Firm Documents</Typography>
          <Divider sx={{ width: "100%", mb: 1 }} />
          <DocumentCheckList
            label="Select All"
            documents={firmDocuments}
            onParentChange={handleParentChange}
            onChildChange={handleChildChange}
            selectedDocs={selectedDocs}
          />
        </Box>

        <Box ml={2} width={"96%"} mb={2}>
          <Typography variant="subtitle1">Banking Documents</Typography>
          <Divider sx={{ width: "100%", mb: 1 }} />
          {bankingInformation.map((bank, index) => (
            <DocumentCheckList
              key={bank.id}
              label={`BANK ${index + 1} - ${toTitleCase(bank.beneficiaryName)}`}
              documents={bank.relatedDocs}
              onParentChange={handleParentChange}
              onChildChange={handleChildChange}
              selectedDocs={selectedDocs}
            />
          ))}
        </Box>

        <Box ml={2} width={"96%"} mb={2}>
          <Typography variant="subtitle1">Signatory Documents</Typography>
          <Divider sx={{ width: "100%", mb: 1 }} />
          {signatoryDirectors.map((director, index) => (
            <DocumentCheckList
              key={director.id}
              // label={director.name}
              label={`SIGNATORY ${index + 1} - ${toTitleCase(director.name)}`}
              documents={director.documents}
              onParentChange={handleParentChange}
              onChildChange={handleChildChange}
              selectedDocs={selectedDocs}
            />
          ))}
        </Box>

        {/* Reason for Rejection */}
        <Typography variant="h5" mt={1}>
          Enter the reason for Rejection
        </Typography>
        <Divider sx={{ width: "100%", mb: 2, mt: 1 }} />
        <Box ml={2} width={"96%"} mb={2}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Quill
            placeholder="Please enter the reason for rejection of every selected document"
            data={reason}
            handleChange={setReason}
            pageWidth="pageWidthExtra"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            ml: "2%",
            width: "96%",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/entities/${id}`)}
          >
            Cancel
          </Button>

          <CustomLoadingButton
            title="Submit"
            onClick={handleReject}
            submitLoader={isLoading}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default RejectionPage;
