import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  Card,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getTransactionsByid } from "../../api/services";
import Status from "../../components/status/Status";
import ApprovalBar from "./ApprovalBar";
import BeneficiaryInformation from "./BeneficiaryInformation";
import DealInformation from "./DealInformation";
import TransactionInformation from "./TransactionInformation";
import TransactionsAccordion from "./TransactionsAccordion";

const TransactionsDetails: React.FC = () => {
  const { ledgerid = "" } = useParams();
  const { data: transactionData, isLoading }: any = useQuery(
    ["transactions-by-id", ledgerid],
    getTransactionsByid
  );
  let seller = null;
  let buyer = null;

  const dealUsers = transactionData?.data?.dealUsers;

  const transactionLedger = transactionData?.data?.ledger;

  dealUsers?.forEach((user: any) => {
    if (user?.userType === "Seller") {
      seller = user;
    } else if (user?.userType === "Buyer") {
      buyer = user;
    }
  });

  const id = ledgerid;

  const navigate = useNavigate();

  if (isLoading) {
    return (
      <>
        <LinearProgress />
      </>
    );
  }

  return (
    <Box
      sx={{
        padding: "0px 20px",
        width: "98vw",
        minHeight: "83vh",
      }}
    >
      <Card
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "8px",
          minHeight: "83vh",
          py: "3vh",
          px: "1.5vw",
        }}
      >
        <Box my={2} display="flex" flexDirection="row" alignItems="center">
          <IconButton
            size="medium"
            // sx={{ mt: -0.5 }}
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
          </IconButton>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: "#7643EB", fontSize: "18px" }}
          >
            <Link underline="hover" color="#7643EB" href="/transactions">
              Transactions
            </Link>
            <Link
              underline="hover"
              color="#7643EB"
              href={`/transactions/${
                transactionLedger?.utr || transactionLedger?.crn || "NA"
              }`}
            >
              {/* {data?.code} */}
              {transactionLedger?.utr || transactionLedger?.crn || "NA"}
            </Link>
          </Breadcrumbs>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my={2}
        >
          <Typography variant="h6" ml={2}>
            {transactionLedger?.utr || transactionLedger?.crn || "NA"}
          </Typography>
          <Box>
            <Status variant={transactionLedger?.status} />
            {/* variant={data?.status} */}
          </Box>
        </Box>

        <Box>{/* <DealTabs data={data} /> */}</Box>
        <Divider sx={{ width: "calc(100% + 40px)", ml: "-20px" }} />

        <Box display="flex">
          <Box
            sx={{
              width: "25vw",
              borderRight: "1px solid #1212121A",
              marginBottom: "25px",
            }}
          >
            <TransactionsAccordion
              seller={seller}
              buyer={buyer}
              transactionLedger={transactionLedger}
            />
          </Box>
          <Box sx={{ width: "75vw" }}>
            <DealInformation data={transactionData?.data} />
            <Divider />
            <TransactionInformation
              buyer={buyer}
              transactionLedger={transactionLedger}
            />
            <Divider />
            <BeneficiaryInformation
              seller={seller}
              transactionLedger={transactionLedger}
            />

            {transactionLedger?.status === "PAYMENT VERIFICATION" && (
              <>
                {" "}
                <ApprovalBar
                  seller={seller}
                  buyer={buyer}
                  transactionLedger={transactionLedger}
                />
              </>
            )}

            {/* <DealDetails data={data} accountOwner={data?.dealUsers?.[0]} /> */}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default TransactionsDetails;
