import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  initiateRefund,
  verifyRefund,
} from "../../../../../api/services/deals";
import DealTextareaField from "../../../../../components/form-fields/DealTextareaField";
import FormInputField from "../../../../../components/form-fields/FormInputField";
import SelectDropdown from "../../../../../components/form-fields/FormInputSelect";
import FileUploadContainer from "../../../../../components/shared/FileUploadContainer";
import { Deal } from "../../../../../interfaces/deal";
import RefundOtpModal from "./RefundOtpVerification";
import RefundStatusModel from "./StatusModel";

interface RefundFormProps {
  dealData: Deal;
}

interface InitiateRefundProps {
  dealCode: string;
  refundType: string;
  transactionMode: string;
  amount: string;
  remarks: string;
  invoiceKey: string;
}
const documentList = [
  {
    label: "Supporting Document 1",
    subtype: "SUPPORTING_DOCUMENT_1",
  },
  {
    label: "Supporting Document 2",
    subtype: "SUPPORTING_DOCUMENT_2",
  },
];

const RefundForm = ({ dealData }: RefundFormProps) => {
  const queryClient = useQueryClient();
  const [isDeleted, setIsDeleted] = useState(false);
  const [isFailed, setIsFailed] = useState<any>(false);
  const [openModel, setOpenModel] = useState(false);
  const [open, setOpen] = useState(false);
  const [uploadKey, setUploadKey] = useState("");
  const validationSchema = Yup.object().shape({
    dealCode: Yup.string().required("Deal Code is required."),
    remarks: Yup.string().required("Reason for refund is required."),
    refundType: Yup.string().required("Refund type is required."),
    amount: Yup.string()
      .required("Amount is required.")
      .test(
        "validRange",
        "Amount must be more than 0 and cannot exceed Total Balance Amount",
        (value) => {
          const numericValue = parseFloat(value || "0");
          const balance = dealData?.overview?.balance || 0;
          return numericValue > 0 && numericValue <= balance;
        }
      ),
  });

  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      dealCode: dealData?.code || "",
      remarks: "",
      refundType: "",
      amount: "",
    },
  });

  const refundType = watch("refundType");
  const handleFileChange = (invoiceKey: any) => {
    setUploadKey(invoiceKey);
  };

  const BuyerBankDetails = dealData?.dealUsers?.find(
    (dealUser) => dealUser?.userType === "Buyer"
  )?.banking;

  useEffect(() => {
    if (refundType === "full") {
      setValue("amount", dealData?.overview?.balance?.toString() || "");
    } else {
      setValue("amount", "");
    }
  }, [refundType, setValue, dealData]);

  const { mutate: initiateRefundPayment, isLoading: load2 } = useMutation(
    initiateRefund,
    {
      onSuccess: (res: any) => {
        toast.success("Verification OTP sent to admin email");
        localStorage.setItem("transactionToken", res?.data?.data?.token);
        localStorage.setItem("transactionEmail", res?.data?.data?.emailAddress);
        setOpen(true);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: VerifyTransaction, isLoading: load1 } = useMutation(
    verifyRefund,
    {
      onSuccess: (res: any) => {
        toast.success("transaction successfully Completed");
        setOpenModel(true);
        setOpen(false);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const cancelTransaction = () => {
    setIsFailed(true);
    setOpen(false);
    setOpenModel(true);
  };

  const ResendOTP = () => {
    const formData = {
      ...getValues(),
      transactionMode: "NE",
      invoiceKey: uploadKey,
    };
    console.log("Resending OTP with data:", formData);
    onSubmit(formData);
  };

  const onSubmit = (data: any) => {
    const formdata = {
      ...data,
      transactionMode: "NE",
      invoiceKey: uploadKey,
    };
    console.log("Form Data:", formdata);
    initiateRefundPayment(formdata);
    // toast.success("Verification OTP sent to admin email");
    // setOpen(true);
  };

  const handleVerify = (otp: any) => {
    const verifyData = {
      emailAddress: localStorage.getItem("transactionEmail"),
      otp: otp,
      token: localStorage.getItem("transactionToken"),
      dealCode: dealData?.code,
      transactionMode: "NE",
      amount: getValues("amount")?.replace(/,/g, ""),
      remarks: getValues("remarks"),
      invoiceKey: uploadKey,
    };
    VerifyTransaction(verifyData);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction={"row"} justifyContent={"space-evenly"}>
          <Box>
            <Controller
              name="dealCode"
              control={control}
              render={({ field }) => (
                <FormInputField
                  type="text"
                  title="Deal Code"
                  field={{
                    ...field,
                    disabled: true,
                  }}
                  placeholder="Deal Code"
                  iD="dealCode"
                  errors={errors}
                />
              )}
            />

            <Controller
              name="remarks"
              control={control}
              render={({ field }) => (
                <DealTextareaField
                  title="Reason for Refund"
                  field={field}
                  placeholder="Enter reason for refund…"
                  iD="remarks"
                  errors={errors}
                />
              )}
            />

            <Box>
              <Typography variant="caption">
                Buyer Bank Details (Refund will be done to this Bank Account)
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{
                  padding: 2,
                  backgroundColor: "f9f9f9",
                  paddingTop: 1,
                }}
              >
                <Grid item xs={5}>
                  <Typography variant="caption">Beneficiary Name</Typography>
                  <Typography variant="subtitle2">
                    {BuyerBankDetails?.beneficiaryName}
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <Typography variant="caption">Bank Account Number</Typography>
                  <Typography variant="subtitle2">
                    {BuyerBankDetails?.accountNumber}
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <Typography variant="caption">Bank Name</Typography>
                  <Typography variant="subtitle2">
                    {BuyerBankDetails?.name}
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <Typography variant="caption">IFSC Code</Typography>
                  <Typography variant="subtitle2">
                    {BuyerBankDetails?.ifscCode}
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <Typography variant="caption">Branch Name</Typography>
                  <Typography variant="subtitle2">
                    {BuyerBankDetails?.branch}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box>
            <Controller
              name="refundType"
              control={control}
              defaultValue="full"
              render={({ field }) => (
                <SelectDropdown
                  title="Refund Type"
                  field={field}
                  placeholder="Select refund type…"
                  iD="refundType"
                  errors={errors}
                  options={[
                    { value: "full", label: "Full Refund" },
                    { value: "partial", label: "Partial Refund" },
                  ]}
                />
              )}
            />

            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <FormInputField
                  type="text"
                  title="Amount"
                  field={{
                    ...field,
                    onChange: (e: any) => {
                      const value = e.target.value;
                      if (!isNaN(value) && Number(value) >= 0) {
                        field.onChange(value);
                      }
                    },
                  }}
                  placeholder="Enter amount…"
                  iD="amount"
                  errors={errors}
                />
              )}
            />
            <Box my={2}>
              <Typography variant="caption" my={2} sx={{ fontWeight: 500 }}>
                Supporting Document
                <span style={{ color: "red", marginLeft: "2px" }}>*</span>
              </Typography>
              <FileUploadContainer
                onFileChange={(key) => {
                  setUploadKey(key);
                }}
                typeData="BANKING"
                data="REFUND"
                uploaded={false}
                url=""
                filetype="pdf"
                actions={null}
                status={false}
                isSupporting={"false"}
                isDeleted={setIsDeleted}
              />
            </Box>
          </Box>
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            type={"submit"}
            variant="contained"
            sx={{ borderRadius: "5px" }}
          >
            Initiate Refund
          </Button>
        </Box>
      </form>

      <RefundOtpModal
        ResendOTP={ResendOTP}
        Text={"mobile number."}
        open={open}
        handleVerify={handleVerify}
        mobileNumber={localStorage.getItem("transactionmobile")}
        isSignup={false}
        isRegister={false}
        BuyerBankDetails={BuyerBankDetails}
        setOpen={setOpen}
        isLoad={load1 || load2}
        cancelTransaction={cancelTransaction}
      />
      <RefundStatusModel
        openModel={openModel}
        setOpenModel={setOpenModel}
        amount={getValues("amount")}
        isFailed={isFailed}
        dealCode={dealData?.code}
      />
    </Box>
  );
};

export default RefundForm;
