import { Padding } from "@mui/icons-material";
import { Box, Card, CardContent, Typography } from "@mui/material";
import CountUp from "react-countup";
import { motion } from "framer-motion";
import React from "react";
import { AnimatedBox } from "./AnimatedBox";

interface StatsCardProps {
  icon: any;
  value: number;
  label: string;
}

// Styles for the purple icon box
const iconBoxStyles = {
  width: "60px",
  height: "60px",
  backgroundColor: "#F3EAFD",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "12px",
};

// Styles for the value text
const valueStyles = {
  fontWeight: 600,
  color: "#2C1E70",
};

export default function StatsCard({ icon, value, label }: StatsCardProps) {
  return (
    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
      <AnimatedBox delay={0.1}>
        <Card
          elevation={3}
          sx={{
            borderRadius: "12px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
          }}
        >
          <CardContent
            sx={{ display: "flex", alignItems: "center", py: 3, px: 2 }}
          >
            <Box sx={iconBoxStyles}>
              <img width={"60%"} src={icon} alt="loading...." />
            </Box>
            <Box sx={{ ml: 2 }}>
              <Typography variant="h4" sx={valueStyles}>
                {/* {value} */}
                <CountUp duration={2} className="counter" end={value} />
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {label}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </AnimatedBox>
    </motion.div>
  );
}
