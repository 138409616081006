import React from "react";
import { useQuery } from "react-query";
import { getAdminUsers } from "../../api/services";
import AdminUserTable from "./AdminUsersTable";
import NoAdminUser from "./NoAdminUserFound";
import LinearProgress from "@mui/material/LinearProgress";

const AdminUsers = () => {
  const {
    data: adminUserData,
    isLoading,
    refetch,
  } = useQuery("getAdminUsersData", getAdminUsers);
  const data = adminUserData?.data?.data || [];

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          {data.length === 0 ? (
            <NoAdminUser onCreate={refetch} />
          ) : (
            <AdminUserTable data={data} onDelete={refetch} onCreate={refetch} />
          )}
        </>
      )}
    </>
  );
};

export default AdminUsers;
