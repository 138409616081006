import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteMultipleCoupons } from "../../api/services/deals";
import { Icons } from "../../assets";
import ButtonWithIcon from "../../components/buttons/ButtonIcon";
import Status from "../../components/status/Status";

interface Coupon {
  id: number;
  name: string;
  code: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
}

interface CouponsTableProps {
  data: Coupon[];
  onDelete: () => void;
}

const CouponsTable: React.FC<CouponsTableProps> = ({ data, onDelete }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState<boolean | null>(null);
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedCoupons, setSelectedCoupons] = useState<Set<number>>(
    new Set()
  );
  const [selectedCouponId, setSelectedCouponId] = useState<number | null>(null);

  const filteredData: Coupon[] = useMemo(() => {
    return data.filter(
      (coupon: Coupon) =>
        (filterStatus === null || coupon.isActive === filterStatus) &&
        (!searchTerm ||
          coupon.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [data, filterStatus, searchTerm]);

  const totalItems: number = filteredData.length;
  const totalPages: number = Math.ceil(totalItems / itemsPerPage);
  const paginatedData: Coupon[] = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  console.log("paginated data", paginatedData);

  const handleSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 500);

  useEffect(() => {
    setCurrentPage(1); // Reset to first page on data change
  }, [filteredData]);

  // const handleStatusChange = (event: any) => {
  //   const value = event.target.value;
  //   setFilterStatus(value === "all" ? null : value === "true");
  // };

  const handleEditCoupon = async (id: number) => {
    if (selectedCoupons.size === 1) {
      setSelectedCouponId(id);
      console.log("selected id for edit:", id);
      navigate(`/coupons/edit/${id}`);
    } else {
      toast.error("Please select a single User to edit");
    }
  };

  const handleAddNewCoupon = () => {
    console.log("Add new coupon clicked");
    navigate("/coupons/create");
  };

  const { mutate: deleteCoupons, isLoading: deleteLoading } = useMutation(
    deleteMultipleCoupons,
    {
      onSuccess: () => {
        toast.success("Coupon updated successfully");
        setSelectedCoupons(new Set());
        queryClient.invalidateQueries("get-Coupons");
      },
      onError: (error: any) => {
        toast.error("Failed to delete Coupons");
      },
    }
  );

  const handleDeleteSelectedCoupons = async () => {
    if (selectedCoupons.size > 0) {
      deleteCoupons(Array.from(selectedCoupons));
    } else {
      toast.error("Please select at least one Coupon to delete");
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedCoupons = new Set(
        paginatedData.map((coupon) => coupon.id)
      );
      setSelectedCoupons(newSelectedCoupons);
    } else {
      setSelectedCoupons(new Set());
    }
  };

  const handleSelectCoupon = (id: number) => {
    const newSelectedCoupons = new Set(selectedCoupons);
    if (newSelectedCoupons.has(id)) {
      newSelectedCoupons.delete(id);
    } else {
      newSelectedCoupons.add(id);
    }
    setSelectedCoupons(newSelectedCoupons);
    setSelectedCouponId(id);
  };

  const cellStyle = {
    textAlign: "left",
    marginLeft: "30px",
    cursor: "pointer",
    padding: "5px",
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
          padding: "10px",
          height: "84vh",
        }}
      >
        <Grid item xs={12}>
          <Paper
            sx={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #1212121A",
              borderRadius: "10px",
              opacity: 1,
              padding: "20px",
              marginBottom: "20px",
            }}
          >
            <Grid container alignItems="center" spacing={2} marginBottom="20px">
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <TextField
                    placeholder="Search By Coupon Name"
                    variant="outlined"
                    size="small"
                    onChange={(e) => handleSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton size="small">
                            <Search fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        width: "500px",
                        height: "40px",
                        boxShadow: "0px 6px 14px #36408D08",
                        border: "1px solid #2B2D221C",
                        borderRadius: "10px",
                        opacity: 1,
                        fontSize: "16px",
                        fontFamily: "Mundial, sans-serif",
                      },
                      inputProps: {
                        style: {
                          fontFamily: "Mundial, sans-serif",
                          fontSize: "16px",
                        },
                      },
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={6} display="flex" justifyContent="flex-end">
                {selectedCoupons.size > 0 && selectedCoupons.size === 1 && (
                  <ButtonWithIcon
                    text="Edit Coupon"
                    icon={Icons.edit}
                    // onClick={handleEditCoupon}
                    onClick={() => handleEditCoupon(selectedCouponId!)}
                  />
                )}
                {/* {selectedCoupons.size > 0 && (
                  <ButtonWithIcon
                    text={
                      selectedCoupons.size === 1
                        ? "Delete Coupon"
                        : "Delete Coupons"
                    }
                    icon={Icons.deleteicon}
                    onClick={handleDeleteSelectedCoupons}
                  />
                )} */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddNewCoupon}
                >
                  Add New Coupon
                </Button>
              </Grid>
            </Grid>

            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "10px",
                overflow: "hidden",
                border: "1px solid #1212121A",
              }}
            >
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "#f3f3f3",
                    fontFamily: "Mundial",
                  }}
                >
                  <TableRow>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Checkbox
                        sx={{
                          marginLeft: "30px",
                          "& .MuiSvgIcon-root": {
                            fontSize: "20px",
                          },
                        }}
                        indeterminate={
                          selectedCoupons.size > 0 &&
                          selectedCoupons.size < paginatedData.length
                        }
                        checked={
                          paginatedData.length > 0 &&
                          selectedCoupons.size === paginatedData.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1">Name</Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1">Code</Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1">Start Date</Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1">End Date</Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1">Status</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ fontFamily: "Mundial" }}>
                  {paginatedData.length >= 1 ? (
                    paginatedData.map((coupon) => (
                      <TableRow key={coupon.id}>
                        <TableCell align="left" sx={{ padding: "5px" }}>
                          <Checkbox
                            sx={{
                              marginLeft: "30px",
                              "& .MuiSvgIcon-root": {
                                fontSize: "20px",
                              },
                            }}
                            checked={selectedCoupons.has(coupon.id)}
                            onChange={() => handleSelectCoupon(coupon.id)}
                          />
                        </TableCell>
                        <TableCell
                          sx={cellStyle}
                          align="left"
                          onClick={() => handleSelectCoupon(coupon.id)}
                        >
                          {coupon.name}
                        </TableCell>
                        <TableCell
                          sx={cellStyle}
                          align="left"
                          onClick={() => handleSelectCoupon(coupon.id)}
                        >
                          {coupon.code}
                          {/* Coupon code */}
                        </TableCell>
                        <TableCell
                          sx={cellStyle}
                          align="left"
                          onClick={() => handleSelectCoupon(coupon.id)}
                        >
                          {coupon?.startDate}
                          {/* Start date */}
                        </TableCell>
                        <TableCell
                          sx={cellStyle}
                          align="left"
                          onClick={() => handleSelectCoupon(coupon.id)}
                        >
                          {coupon?.endDate}
                          {/* End Date */}
                        </TableCell>
                        <TableCell
                          sx={cellStyle}
                          align="left"
                          onClick={() => handleSelectCoupon(coupon.id)}
                        >
                          <Box sx={{ textAlign: "left" }}>
                            <Box>
                              {coupon.isActive ? (
                                <Status variant="ACTIVE" />
                              ) : (
                                <Status variant="INACTIVE" />
                              )}
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No data available.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              marginTop="20px"
            >
              <Grid item xs={1.5}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, value) => setCurrentPage(value)}
                  shape="rounded"
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: theme.palette.primary.main,
                      height: "27px",
                      minWidth: "27px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      margin: "0 4px",
                    },
                    "& .MuiPaginationItem-page.Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                    "& .MuiPaginationItem-page.Mui-selected:hover": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                    "& .Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={5} style={{ textAlign: "right" }}>
                Rows per page:
                <Select
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(Number(e.target.value))}
                  variant="outlined"
                  style={{
                    width: "auto",
                    height: "25px",
                    background: "#FFFFFF",
                    borderRadius: "5px",
                    textAlign: "center",
                    lineHeight: "20px",
                    marginLeft: "10px",
                    outline: "none",
                    boxShadow: "none",
                  }}
                >
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={150}>150</MenuItem>
                  <MenuItem value={200}>200</MenuItem>
                  <MenuItem value={250}>250</MenuItem>
                </Select>
              </Grid>
            </Grid> */}
            <TablePagination
              component="div"
              count={totalItems}
              page={currentPage - 1} // Adjusting to zero-based index
              onPageChange={(event, newPage) => setCurrentPage(newPage + 1)} // Setting page to 1-based index
              rowsPerPage={itemsPerPage}
              onRowsPerPageChange={(event) => {
                setItemsPerPage(parseInt(event.target.value, 10));
                setCurrentPage(1); // Reset to first page on items per page change
              }}
              rowsPerPageOptions={[25, 50, 100, 150, 200, 250]}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: theme.palette.primary.main,
                  height: "27px",
                  minWidth: "27px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  margin: "0 4px",
                },
                "& .MuiPaginationItem-page.Mui-selected": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default CouponsTable;
