import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Breadcrumbs, IconButton, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../assets";
import ButtonWithIcon from "../../components/buttons/ButtonIcon";
import Status from "../../components/status/Status";
import { Coupon } from "../../interfaces/coupon";

interface CouponTopProps {
  coupon?: Coupon;
}

const CouponTop = ({ coupon }: CouponTopProps) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(`/coupons`);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Box
          display="flex"
          alignItems="center"
          color={"#7643EB"}
          onClick={handleBackClick}
          style={{ cursor: "pointer" }}
        >
          <IconButton
            size="medium"
            onClick={() => {
              navigate("/coupons", { replace: true });
            }}
          >
            <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
          </IconButton>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: "#7643EB", fontSize: "18px" }}
          >
            <Link underline="hover" color="#7643EB" href="/coupons">
              Coupons
            </Link>
            <Link
              underline="hover"
              color="#7643EB"
              href={`/coupons/view/${coupon?.id}`}
            >
              {coupon?.code}
            </Link>
          </Breadcrumbs>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          marginTop="20px"
          marginRight="20px"
        >
          <ButtonWithIcon
            text="Edit Coupon"
            icon={Icons.edit}
            // onClick={handleEditCoupon}
            onClick={() => navigate(`/coupons/edit/${coupon?.id}`)}
          />
          <Status
            variant={coupon?.isActive ? "ACTIVE" : "INACTIVE"}
            showTooltip={true}
          />
        </Box>
      </Box>
    </>
  );
};

export default CouponTop;
