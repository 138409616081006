import { Box, Button, Stack, Typography } from "@mui/material";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postNotify } from "../../../../api/services/deals";

export default function DealCompleted({ data, secondUser }: any) {
  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Deal Completed
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          Funds have been fully transferred to the Seller
        </Typography>
      </Stack>
      {/* <Button
        variant="contained"
        onClick={() =>
          navigate(`/deal-process/deals/${data?.code}/documentationSignee`, {
            replace: true,
          })
        }
        sx={{ width: "auto", mt: 2 }}
      >
        View Document
      </Button> */}

      {/* <DealSignStatus></DealSignStatus> */}
    </Box>
  );
}
