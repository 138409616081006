import { Box, Stack, Typography } from "@mui/material";

export default function InvitationIssued({ data, secondUser }: any) {
  return (
    <Box sx={{ px: "20px", py: "20px" }}>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Deal Invitation Sent
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          The {secondUser?.userType} has sent you a business deal invitation.
        </Typography>
      </Stack>
    </Box>
  );
}
