import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Status from "../../../../components/status/Status";

function DealInformation({ dealData, profiledata }: any) {
  function formatNumberToIndianSystem(number: number) {
    return new Intl.NumberFormat("en-IN").format(number);
  }
  const navigate = useNavigate();

  const sellersCompany = dealData?.data?.dealUsers?.filter(
    (user: any) => user?.userType === "Seller"
  );
  const BuyerCompany = dealData?.data?.dealUsers?.filter(
    (user: any) => user?.userType === "Buyer"
  );
  const OtherUser = dealData?.data?.dealUsers?.filter(
    (user: any) => user?.userId !== profiledata?.data?.id
  );

  const role = localStorage.getItem("Role");

  // console.log("Other user", BuyerCompany);

  const ProfileUser = dealData?.data?.dealUsers?.filter(
    (user: any) => user?.userId === profiledata?.data?.id
  );

  // console.log("this is profile data,", profiledata);

  return (
    <Box>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          paddingBottom: "10px",
        }}
      >
        <Box
          sx={{
            // width: "100%",
            height: "auto",
            backgroundColor: "#F3EFFE",
            borderRadius: "8px 8px 0 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "15px 20px",
          }}
        >
          <Grid container spacing={3} sx={{ alignItems: "center" }}>
            <Grid item xs={4}>
              <Typography
                variant="h5"
                sx={{
                  color: "#7643EB",
                }}
              >
                {dealData?.data?.code}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">
                Deal Duration : {dealData?.data?.duration} Months
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
              {/* <Box>
                {dealData?.data?.status === "Fee Pending" ? (
                  <Status variant={`Service Payment`} />
                ) : dealData?.data?.status === "Funds Awaited" ? (
                  <Status variant={"Funds Awaited"} />
                ) : dealData?.data?.status === "Payment Verification" ? (
                  role === "Admin" ? (
                    <Status variant={"Under Verification"} />
                  ) : (
                    <Status variant={"Verify Payment"} />
                  )
                ) : (
                  <>
                    {" "}
                    <Status variant={dealData?.data?.status} />
                  </>
                )}
              </Box> */}
              <Status variant={dealData?.data?.status} />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: "5px 20px",
          }}
        >
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="caption">Deal Title</Typography>
              <Typography variant="subtitle2">
                {dealData?.data?.title}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">Deal Type</Typography>
              <Typography variant="subtitle2">
                {" "}
                {dealData?.data?.type}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">Deal Amount</Typography>
              <Typography variant="subtitle2">
                {"  ₹  "}
                {formatNumberToIndianSystem(dealData?.data?.amount)}
                {/* {dealData?.data?.amount} */}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ my: "1.2vh", mx: "1vw" }} />
        <Box
          sx={{
            width: "100%",
            padding: "5px 20px",
          }}
        >
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="caption">Seller Name</Typography>
              <Typography variant="subtitle2">
                {sellersCompany?.[0]?.user?.name || ""}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">Seller Email Address</Typography>
              <Typography variant="subtitle2">
                {sellersCompany?.[0]?.user?.emailAddress || ""}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">Seller Entity Name</Typography>
              <Typography variant="subtitle2">
                {sellersCompany?.[0]?.entity?.companyName || ""}
                {/* {OtherUser?.[0]?.entity?.companyType === "INDIVIDUAL" ? (
                  <>{OtherUser?.[0]?.entity?.directors[0]?.name || ""}</>
                ) : (
                  <> </>
                )} */}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ marginTop: "1.2vh", mx: "1vw" }} />
        <Box
          sx={{
            width: "100%",
            padding: "5px 20px",
          }}
        >
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="caption">Buyer Name</Typography>
              <Typography variant="subtitle2">
                {BuyerCompany?.[0]?.user?.name || ""}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">Buyer Email Address</Typography>
              <Typography variant="subtitle2">
                {BuyerCompany?.[0]?.user?.emailAddress || ""}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">Buyer Entity Name</Typography>
              <Typography variant="subtitle2">
                {BuyerCompany?.[0]?.entity?.companyType === "INDIVIDUAL"
                  ? BuyerCompany?.[0]?.user?.name
                  : BuyerCompany?.[0]?.entity?.companyName}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
}

export default DealInformation;
