import {
  Timeline,
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Button, Modal, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { Icons } from "../../../assets";

interface TimeLineModalProps {
  open: boolean;
  handleClose: () => void;
  data: any;
}

const TimeLineModal: React.FC<TimeLineModalProps> = ({
  open,
  handleClose,
  data,
}) => {
  const activityTimeline = data?.activity || [];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          width: "80%",
          maxWidth: "350px",
          boxShadow: 24,
          p: 3,
          outline: "none",
          borderRadius: "10px",
        }}
      >
        <Typography id="modal-title" variant="h6" sx={{ mb: 2 }}>
          Timeline
        </Typography>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Timeline position="left" sx={{ flexGrow: 0, pr: 2 }}>
            {activityTimeline.map((activity: any, index: number) => (
              <TimelineItem key={activity.id}>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      backgroundColor: "transparent",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <img
                      src={Icons.checkedblue}
                      alt="Check Icon"
                      style={{
                        width: "40px",
                        height: "40px",
                        opacity: 1,
                      }}
                    />
                  </TimelineDot>
                  {index < activityTimeline.length - 1 && (
                    <TimelineConnector
                      sx={{
                        backgroundColor: "primary.main",
                        height: "18px",
                        my: 0.8,
                      }}
                    />
                  )}
                </TimelineSeparator>
              </TimelineItem>
            ))}
          </Timeline>
          <Box sx={{ flexGrow: 1, mt: 2.8 }}>
            {activityTimeline.map((activity: any) => (
              <Box sx={{ mb: 3.2 }} key={activity.id}>
                <Typography variant="subtitle2" component="span">
                  {activity.description}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {moment(activity.createdAt).format("DD/MM/YYYY") || "NA"}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Button
          onClick={handleClose}
          sx={{ mt: 2 }}
          variant="outlined"
          color="primary"
          size="small"
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default TimeLineModal;
