import React from 'react';
import OtpModal from './OtpModal';
// import PaymentSuccess from './PaymentSuccess';
// import TrusteeDetails from './TrusteeDetails';

const Test = () => {
    return (
        <OtpModal />
        // <PaymentSuccess />
        // <TrusteeDetails />
        

    );
};

export default Test;