import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import * as yup from "yup";

const schema = yup.object().shape({
  date: yup.string().required("Date is required"),
});

const TransactionsAccordion = ({
  data,
  seller,
  buyer,
  transactionLedger,
}: any) => {
  const firstUser = buyer;

  const secondUser = seller;

  const duration = data?.duration > 1 ? "Months" : "Month";

  return (
    <Box>
      <Accordion
        defaultExpanded
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="body2">Transaction Information</Typography>
        </AccordionSummary>
        <Divider sx={{ mt: -1 }} />
        <AccordionDetails>
          <Stack gap={1} sx={{ width: "100%" }}>
            <Grid>
              <Typography variant="caption">Transaction Title</Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {transactionLedger?.utr || transactionLedger?.crn || "NA"}
              </Typography>
            </Grid>

            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography variant="caption">Transaction Type</Typography>
                <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                  {transactionLedger?.type}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">
                  Transaction Start Date
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                  {moment(transactionLedger?.createdAt).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
              {/* <Grid item xs={6}>
                <Typography variant="caption">Transaction Duration</Typography>
                <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                  {data?.duration} {duration}
                </Typography>
              </Grid> */}
              {/* <Grid item xs={6}>
                <Typography variant="caption">Expected Closure Date</Typography>
                <Typography variant="body2" sx={{ marginBottom: "0px" }}>
                  {moment(data?.closureDate).format("DD/MM/YYYY")}
                </Typography>
              </Grid> */}
            </Grid>
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="body2">Seller Information</Typography>
        </AccordionSummary>
        <Divider sx={{ mt: -1 }} />
        <AccordionDetails>
          <Stack gap={1} sx={{ width: "100%" }}>
            <Grid>
              <Typography variant="caption">Seller Name</Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {secondUser?.user?.name || "Not Registered"}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption">Seller Email Address</Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {secondUser?.user?.emailAddress || "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">Seller Entity Name</Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {secondUser?.entity?.companyName || "Not Registered"}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption">Seller Address</Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {secondUser?.entity?.registeredAddress || "Not Registered"}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption">
                Seller Beneficiary Name{" "}
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {secondUser?.entity?.bankingInformation[0]?.beneficiaryName ||
                  "Not Registered"}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption">Seller Bank Branch Name</Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {secondUser?.entity?.bankingInformation[0]?.branch ||
                  "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">Seller Bank IFSC CODE</Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {secondUser?.entity?.bankingInformation[0]?.ifscCode ||
                  "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">
                Seller Bank Account Number
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {secondUser?.entity?.bankingInformation[0]?.accountNumber ||
                  "Not Registered"}
              </Typography>
            </Grid>
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="body2">Buyer Information</Typography>
        </AccordionSummary>
        <Divider sx={{ mt: -1 }} />
        <AccordionDetails>
          <Stack gap={1} sx={{ width: "100%" }}>
            <Grid>
              <Typography variant="caption">Buyer Name</Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {firstUser?.user?.name || "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">Buyer Email Address</Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {firstUser?.user?.emailAddress || "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">Buyer Entity Name</Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {firstUser?.entity?.companyType === "INDIVIDUAL" ? (
                  <>{firstUser?.user?.name}</>
                ) : (
                  <> {firstUser?.entity?.companyName || "Not Registered"}</>
                )}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption">Buyer Address</Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {firstUser?.entity?.registeredAddress || "Not Registered"}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption">Buyer Beneficiary Name </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {firstUser?.entity?.bankingInformation[0]?.beneficiaryName ||
                  "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">Buyer Bank IFSC CODE</Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {firstUser?.entity?.bankingInformation[0]?.ifscCode ||
                  "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">Buyer Bank Branch Name</Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {firstUser?.entity?.bankingInformation[0]?.branch ||
                  "Not Registered"}
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="caption">
                Buyer Bank Account Number
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                {" "}
                {firstUser?.entity?.bankingInformation[0]?.accountNumber ||
                  "Not Registered"}
              </Typography>
            </Grid>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default TransactionsAccordion;
