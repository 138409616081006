import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import DealDeclined from "./DealDeclined";

export default function DealRejected({ data, secondUser }: any) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Deal Cancelled
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          The {secondUser?.userType} has unfortunately rejected the business
          deal invitation you sent. You can view the reason provided by the
          {secondUser?.userType} for rejecting the invitation. This will allow
          you to understand their decision and determine if you would like to
          revise your offer or pursue other opportunities.
        </Typography>
      </Stack>
      <DealDeclined open={isModalOpen} onClose={handleClose} data={data} />
      <Button
        variant="contained"
        sx={{ width: "auto", my: "10px" }}
        onClick={handleOpen}
      >
        View Reason
      </Button>
    </Box>
  );
}
