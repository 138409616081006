import { yupResolver } from "@hookform/resolvers/yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { authLogin } from "../../api/services";
import { Icons } from "../../assets";
import { ContainedButton } from "../../components/buttons/Buttons";
import CustomInput from "../../components/customInput/CustomInput"; // Correct import
import { CustomLoadingButton } from "../../components/buttons/SubmitLoaderButton";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

export default function LoginForm({
  setformdata,
}: {
  setformdata: (data: string) => void;
}) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const { mutate: loginUser, isLoading } = useMutation(authLogin, {
    onSuccess: (res: any) => {
      // console.log(res, "Login");
      toast.success("Login successful");
      Cookies.set("access_token", res?.data?.access_token);
      Cookies.set("isAdmin", res?.data?.isAdmin);
      if (res?.data?.isAdmin) {
        navigate("/dashboard");
      } else {
        navigate("/deals");
      }
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Login failed");
    },
  });

  const onSubmit = (data: any) => {
    const loginData = { username: data.email, password: data.password };
    setformdata(data.email);
    loginUser(loginData);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      px={{ xs: "5%", md: "5%" }}
      py="2%"
      width="100%"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%", maxWidth: "420px" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="20px"
          width="100%"
        >
          <Box
            sx={{
              height: "60px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "25px",
            }}
          >
            <img height="100%" src={Icons.truupe} alt="Truupe Logo" />
          </Box>

          <Typography
            variant="h2"
            sx={{
              fontFamily: "Mundial, Regular",
              fontWeight: 400,
              lineHeight: "20px",
              textAlign: "center",
              letterSpacing: 0,
              color: "#000000",
              marginBottom: "20px",
            }}
          >
            Login into your TruuPe Account
          </Typography>

          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="center"
            gap="10px"
          >
            <Box
              sx={{
                width: 50,
                height: 0,
                border: "1px solid #333333",
                opacity: 0.2,
              }}
            />
            <Typography
              variant="body1"
              sx={{ color: "#333333", fontSize: "13px", textAlign: "center" }}
            >
              LOGIN USING USERNAME & PASSWORD
            </Typography>
            <Box
              sx={{
                width: 50,
                height: 0,
                border: "1px solid #333333",
                opacity: 0.2,
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "80%",
            }}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="email-input">
                    User Name
                  </InputLabel>
                  <CustomInput
                    id="email-input"
                    placeholder="Enter your User name..."
                    {...field}
                    error={!!errors.email}
                  />
                  <FormHelperText error>
                    {errors.email && errors.email.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="password-input">
                    Password
                  </InputLabel>
                  <CustomInput
                    id="password-input"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your Password..."
                    {...field}
                    error={!!errors.password}
                    endAdornment={
                      // Move the InputAdornment here
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error>
                    {errors.password && errors.password.message}
                  </FormHelperText>
                </FormControl>
              )}
            />

            <CustomLoadingButton
              title={"Login"}
              submitLoader={isLoading}
              width={"100%"}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
}
