import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  DialogContentText,
} from "@mui/material";
import { useState } from "react";
import * as yup from "yup";
import Quill from "../quill/Quill";

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (status: string, reason?: string) => void;
  text: string;
  title: string;
  showReasonInput?: boolean;
}

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  text,
  title,
  showReasonInput = false,
}: ConfirmationModalProps) => {
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");

  const handleReasonChange = (value: string) => {
    setReason(value);
    setError(""); // Clear error when user types
  };

  const schema = yup.object().shape({
    reason: yup.string().required("Reason is required for rejection"),
  });

  const handleConfirm = async () => {
    if (showReasonInput) {
      try {
        // Validate the reason input
        await schema.validate({ reason });
        onConfirm("Rejected", reason); // Proceed if validation passes
        onClose();
      } catch (error: unknown) {
        // Ensure error is a Yup.ValidationError before accessing errors[0]
        if (error instanceof yup.ValidationError) {
          setError(error.errors[0]);
        } else {
          console.error("An unexpected error occurred", error);
        }
      }
    } else {
      onConfirm("Verified");
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle color={"#7643EB"} id="confirmation-dialog-title">
        {title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="confirm-delete-dialog-description" mb={"10px"}>
          {text}
        </DialogContentText>

        {showReasonInput && (
          <Box>
            <Quill
              data={reason}
              handleChange={handleReasonChange}
              pageWidth="pageWidth"
            />
            {/* Show validation error below Quill */}
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            Confirm
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
