import { Box } from "@mui/material";
import { Icons } from "../assets";

export default function Truupeheader() {
  return (
    <Box
      sx={{
        height: "70px",
        width: "100%",
        margin: "0px",
        paddingLeft: "60px",
        paddingTop: "10px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <img src={Icons.truupe} alt="Truupe" style={{ height: "75%" }} /> {/* Adjusted image height */}
    </Box>
  );
}
