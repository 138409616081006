import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const DealInformation = ({ data }: any) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleNavigation = () => {
    navigate(`/deals/${data?.code}`);
  };

  return (
    <Box sx={{ ml: "20px", my: "20px" }}>
      <Typography variant="h4" sx={{ marginBottom: "20px" }}>
        Deal Information
      </Typography>

      <Grid container spacing={5}>
        {data?.data?.companyType === "INDIVIDUAL" ? (
          <></>
        ) : (
          <Grid item xs={3} md={3} lg={3}>
            <Typography variant="caption">Deal ID</Typography>
            <Stack direction={"row"} gap={"5px"}>
              <Typography variant="body2">{data?.code} </Typography>
              <OpenInNewIcon
                onClick={handleNavigation}
                sx={{
                  cursor: "pointer",
                  fontSize: "18px",
                  "&:hover": {
                    textDecoration: "underline",
                    textDecorationColor: theme.palette.primary.main,
                    color: "#7643EB",
                  },
                }}
              />
            </Stack>
          </Grid>
        )}

        <Grid item xs={3} md={3} lg={3}>
          <Typography variant="caption">Deal Title</Typography>
          <Typography variant="body2">{data?.title}</Typography>
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          <Typography variant="caption">Deal Amount</Typography>
          <Typography variant="body2">{data?.amount}</Typography>
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          <Typography variant="caption">Expected Closure Date</Typography>
          <Typography variant="body2">{data?.closureDate}</Typography>
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          <Typography variant="caption">Deal Description</Typography>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: data?.description }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DealInformation;
