import React from 'react';
import { Button, Typography } from '@mui/material';

export default function ButtonWithIcon({ text, icon, onClick }: any) {
    return (
        <Button
            variant="outlined"
            color="primary"
            sx={{
                width: 'auto',
                height: '37px',
                background: '#FFFFFF',
                boxShadow: "0px 3px 6px #00000029",
                border: "1.5px solid #7643EB",
                borderRadius: '8px',
                opacity: 1,
                marginRight: '10px',
                padding: '0px 30px',
                color: '#7643EB',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                '&:hover': {
                    color: 'white',
                    backgroundColor: '#7643EB', 
                    border : "1.5px solid #7643EB",
                    '& img': {
                        filter: 'brightness(0) invert(1)',
                    },
                    '& .MuiTypography-root': {
                        color: 'white',
                    },
                },
            }}
            onClick={onClick}
        >
            <img src={icon} alt={text} style={{ width: '17px', height: '17px' }} />
            <Typography variant="body1" sx={{ color: '#7643EB' }}>
                {text}
            </Typography>
        </Button>
    );
}
