import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Card, Divider, IconButton } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import moment from "moment";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getDealDetails, getUserProfile } from "../../../../api/services";
import { publishDeal } from "../../../../api/services/deals";
import DealInfo from "../deal-components/DealInfo";
import GoBack from "../deal-components/GoBack";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function DealDocumentation() {
  const { code } = useParams();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handlePublicationSuccess = () => {
    navigate(`/deals/${code}/documentationSignee`, {
      replace: true,
    });
    handleClose();
  };

  const { data: profiledata }: any = useQuery("getuser-id", getUserProfile);

  const { data: dealData }: any = useQuery(["deal", code], () =>
    getDealDetails(code)
  );

  const data = dealData?.data;

  const dealCreator = data?.dealUsers?.find(
    (user: any) => user?.userId === data?.createdBy?.id
  );

  const firstPerson = dealCreator?.userId === profiledata?.data?.id;

  const [numPages, setNumPages] = useState(0);
  const [formDate, setFormDate] = useState();

  const { mutate: publishDocument } = useMutation(publishDeal, {
    onSuccess: (res: any) => {
      toast.success("Deal Published");
      handleOpen();
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
      navigate(`/deals/${code}/documentationSignee`, {
        replace: true,
      });
    },
  });

  const handlePublish = () => {
    publishDocument({
      data: { date: moment(formDate).format("DD/MM/YYYY") },
      id: code,
    });
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  return (
    <Box
      sx={{
        padding: "0px 20px",
        width: "98vw",
        minHeight: "83vh",
      }}
    >
      <Card
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "8px",
          minHeight: "83vh",
          py: "3vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "0px 20px",
          }}
        >
          <Box my={"40"} display={"flex"} flexDirection={"row"}>
            <IconButton
              size="medium"
              sx={{ mt: -0.5 }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
            </IconButton>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ color: "#7643EB", fontSize: "18px" }}
            >
              <Link underline="hover" color="#7643EB" href="/deals">
                Deals
              </Link>
              <Link underline="hover" color="#7643EB" href={`/deals/${code}`}>
                {code}
              </Link>
              <Link underline="hover" color="#7643EB" href="/deals">
                Deal Document
              </Link>
            </Breadcrumbs>
          </Box>

          {/* <Button
            variant="contained"
            color="primary"
            onClick={handlePublish}
            disabled={!isDateSelected}
            sx={{
              mt: -2,
              boxShadow: "0px 3px 6px #00000029",
            }}
          >
            Publish Document
          </Button> */}
          <GoBack
            open={isModalOpen}
            onClose={handleClose}
            formDate={formDate}
            dealId={code}
            onSuccess={handlePublicationSuccess}
          />
        </Box>

        <Divider sx={{ width: "100vw", ml: -8 }} />

        <Box display={"flex"} padding={"0px 0px"}>
          <Box sx={{ width: "22vw", borderLeft: "1px solid #1212121A" }}>
            <DealInfo
              data={data}
              doc={true}
              accountOwner={firstPerson}
              submit={(newDate: any) => {
                setFormDate(newDate);
                setIsDateSelected(true);
              }}
            />
          </Box>

          <Box
            sx={{
              width: "80vw",
              padding: 3,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F9F9F9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  padding: "40px",
                  backgroundColor: "#F9F9F9",
                }}
              >
                <Card
                  sx={{
                    padding: "20px",
                    backgroundColor: "white",
                    overflow: "auto",
                    maxHeight: {
                      xs: "100vh", // maxHeight for extra-small screens
                      sm: "120vh", // maxHeight for small screens
                      md: "140vh", // maxHeight for medium screens
                      lg: "160vh", // maxHeight for large screens
                      xl: "180vh", // maxHeight for extra-large screens
                    },
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      height: "100%",
                      marginLeft: 12,
                      marginTop: -30,
                    }}
                  >
                    <Document
                      file={data?.unsignedDocUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <div key={index}>
                          {index !== 0 && <Divider sx={{ my: 2 }} />}

                          <Page
                            pageNumber={index + 1}
                            className="pdf-page"
                            renderMode="canvas"
                            width={800}
                            loading="..."
                          />
                        </div>
                      ))}
                    </Document>
                  </div>
                </Card>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>

      <style>{`
        .pdf-page {
          margin-bottom: -16px; 
        }
      `}</style>
    </Box>
  );
}
