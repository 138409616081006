export const formatIndianIntegers = (num: any) => {
  if (num === null || num === undefined) {
    return "";
  }

  num = num.toString();

  const [integerPart, decimalPart] = num.split(".");

  if (!integerPart) {
    return num; // Return the original value if the integer part is invalid
  }

  const lastThree = integerPart.slice(-3);
  const otherNumbers = integerPart.slice(0, -3);

  const formattedInteger =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    (otherNumbers ? "," : "") +
    lastThree;

  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

export const stripHTML = (htmlString: string) => {
  const div = document.createElement("div");
  div.innerHTML = htmlString;
  return div.textContent || div.innerText || "";
};

export const getFirmDocumentData = (
  firmDocuments: any[],
  subtype: string,
  field: string
) =>
  firmDocuments
    ?.filter((doc: any) => doc?.subtype === subtype)
    .map((doc: any) => doc[field])[0];

export function formatNumberToIndianSystem(number: number) {
  return new Intl.NumberFormat("en-IN").format(number);
}
