import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import { getAdminUserById, updateAdminUser } from "../../../api/services";
import CustomInput from "../../../components/customInput/CustomInput";

interface EditAdminUserProps {
  open: boolean;
  onClose: () => void;
  adminUserId: number | null;
}

interface AdminUserData {
  name: string;
  mobileNumber: string;
  aadharNumber: string;
  emailAddress: string;
  password: string;
}

const validationSchema = yup.object().shape({
  name: yup.string().required("Admin name is required"),
  mobileNumber: yup.string().required("Mobile number is required"),
  aadharNumber: yup.string().required("Aadhar number is required"),
  emailAddress: yup
    .string()
    .email("Invalid email address")
    .required("Email address is required"),
  password: yup.string().required("Password is required"),
});

const EditAdminUser: React.FC<EditAdminUserProps> = ({
  open,
  onClose,
  adminUserId,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AdminUserData>({
    resolver: yupResolver(validationSchema),
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    const fetchAdminUserData = async () => {
      try {
        if (adminUserId !== null) {
          const response = await getAdminUserById(adminUserId);
          const adminUserData = response.data;
          const { password, ...otherData } = adminUserData;
          reset({ ...otherData, password: "" }); // Set password to empty string
        }
      } catch (error) {
        console.error("Failed to fetch adminUser data:", error);
        toast.error("Failed to fetch adminUser data");
      }
    };

    if (open) {
      fetchAdminUserData();
    }
  }, [open, adminUserId, reset]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = async (data: AdminUserData) => {
    try {
      const formattedAadharNumber = data.aadharNumber.replace(/\s/g, "");

      if (adminUserId !== null) {
        const updatedData = {
          ...data,
          aadharNumber: formattedAadharNumber,
          countryCode: "+91",
          role: "Admin",
        };
        await updateAdminUser(adminUserId, updatedData);
        queryClient.invalidateQueries("getAdminUsersData");
        onClose();
        toast.success("Admin updated successfully");
      }
    } catch (error) {
      console.error("Failed to update admin:", error);
      toast.error("Failed to update admin");
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "380px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          opacity: 1,
          borderRadius: "0px 0px",
          border: "none",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "450px",
          height: "64px",
          background: "#7643EB 0% 0% no-repeat padding-box",
          opacity: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
          padding: "0px 16px",
        }}
      >
        <Typography variant="h4" color="white">
          Edit Admin
        </Typography>
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ padding: "20px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* AdminUser Name */}
          <Controller
  name="name"
  control={control}
  rules={{
    required: "Admin name is required",
    pattern: {
      value: /^[A-Za-z\s]+$/, // Only allow alphabets and spaces
      message: "Admin name must contain only alphabets and spaces",
    },
  }}
  render={({ field: { onChange, value = "", ...field } }) => (
    <FormControl
      variant="standard"
      fullWidth
      error={!!errors.name}
      sx={{ marginBottom: "20px" }}
    >
      <InputLabel shrink htmlFor="adminUser-name-edit-input">
        Admin Name
      </InputLabel>
      <CustomInput
        id="adminUser-name-edit-input"
        placeholder="Enter Admin Name..."
        value={value}
        onChange={(e) => {
          const input = e.target.value.replace(/[^A-Za-z\s]/g, ""); // Remove special characters
          onChange(input);
        }}
        autoComplete="off" // Disable autofill
        {...field}
      />
      <FormHelperText error>
        {errors.name && errors.name.message}
      </FormHelperText>
    </FormControl>
  )}
/>

          {/* Mobile Number */}
          <Controller
  name="mobileNumber"
  control={control}
  rules={{
    required: "Mobile number is required",
    pattern: {
      value: /^[0-9]{10}$/,
      message: "Mobile number must be exactly 10 digits",
    },
  }}
  render={({ field: { onChange, value = "", ...field } }) => (
    <FormControl
      variant="standard"
      fullWidth
      error={!!errors.mobileNumber}
      sx={{ marginBottom: "20px" }}
    >
      <InputLabel shrink htmlFor="mobile-number-create-input">
        Mobile Number
      </InputLabel>
      <CustomInput
        id="mobile-number-create-input"
        placeholder="Enter Mobile Number..."
        value={value.replace(/\D/g, "").slice(0, 10)} // Restrict input to 10 digits
        onChange={(e) => {
          const input = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
          onChange(input.slice(0, 10)); // Allow only up to 10 digits
        }}
        {...field}
      />
      <FormHelperText>
        {errors.mobileNumber && errors.mobileNumber.message}
      </FormHelperText>
    </FormControl>
  )}
/>


          {/* Aadhar Number */}

          <Controller
            name="aadharNumber"
            control={control}
            rules={{
              required: "Aadhaar number is required",
              pattern: {
                value: /^[0-9]{12}$/,
                message:
                  "Aadhaar number must be exactly 12 digits and contain only numbers",
              },
              validate: (value) =>
                value?.replace(/\s/g, "").length === 12 || // Ensure `value` is not null/undefined
                "Aadhaar number must be exactly 12 digits",
            }}
            render={(
              { field: { onChange, value = "", ...field } } // Fallback to empty string if value is undefined
            ) => (
              <FormControl
                variant="standard"
                fullWidth
                error={!!errors.aadharNumber}
                sx={{ marginBottom: "20px" }}
              >
                <InputLabel shrink htmlFor="aadhar-number-input">
                  Aadhaar Number
                </InputLabel>
                <CustomInput
                  id="aadhar-number-input"
                  placeholder="Enter Aadhaar Number..."
                  value={
                    (value || "") // Ensure value is always a string
                      .replace(/\D/g, "") // Remove all non-digit characters
                      .replace(/(\d{4})(?=\d)/g, "$1 ") // Add space after every 4 digits
                      .slice(0, 14) // Limit to 14 characters (12 digits + 2 spaces)
                  }
                  onChange={(e) => {
                    const formattedValue = e.target.value
                      .replace(/\D/g, "") // Remove all non-digit characters
                      .replace(/(\d{4})(?=\d)/g, "$1 ") // Add space after every 4 digits
                      .slice(0, 14); // Limit to 14 characters (12 digits + 2 spaces)
                    onChange(formattedValue);
                  }}
                  {...field}
                />
                <FormHelperText>
                  {errors.aadharNumber && errors.aadharNumber.message}
                </FormHelperText>
              </FormControl>
            )}
          />

          {/* Email Address */}
          <Controller
            name="emailAddress"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="standard"
                fullWidth
                error={!!errors.emailAddress}
                sx={{ marginBottom: "20px" }}
              >
                <InputLabel shrink htmlFor="email-address-edit-input">
                  Email Address
                </InputLabel>
                <CustomInput
                  id="email-address-edit-input"
                  placeholder="Enter Email Address..."
                  {...field}
                />
                <FormHelperText error>
                  {errors.emailAddress && errors.emailAddress.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          {/* Password */}
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="standard"
                fullWidth
                error={!!errors.password}
                sx={{ marginBottom: "20px" }}
              >
                <InputLabel shrink htmlFor="password-edit-input">
                  Password
                </InputLabel>
                <CustomInput
                  id="password-edit-input"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password..."
                  {...field}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText error>
                  {errors.password && errors.password.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          {/* Submit button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "20px",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{ borderRadius: "5px" }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export default EditAdminUser;
