import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography,
  } from "@mui/material";
  import { useState } from "react";
  import { useQuery, useQueryClient } from "react-query";
  import { getDirectorAddressProof } from "../../../../api/services";
  import FileUploadContainer from "../../../../components/shared/FileUploadContainer";
  import { toast } from "react-toastify";
  
  const SignatoryDocumentsForm = ({ data, close }: any) => {
    const director = data;
    const queryClient = useQueryClient();
  
    const [addressProofType, setAddressProofType] = useState<string | null>(
      director?.documents?.find((doc: any) => doc?.subtype === "ADDRESS_PROOF")
        ?.filetype || null
    );
  
    const { data: FirmAddressProof } = useQuery("FirmAddressProof", async () => {
      const response = await getDirectorAddressProof();
      return response.data;
    });
  
    const [disableAddressProof, setDisableAddressProof] = useState(false);
    const [isFileDeleted, setIsFileDeleted] = useState(false);
    const handleSelectChange = (event: SelectChangeEvent) => {
      setAddressProofType(event.target.value);
    };
  
    const stylesBefore = {
      width: "230px",
      height: "100px",
      padding: "15px 0px",
      border: `2px dashed #3333334D`,
      borderRadius: "12px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      userSelect: "none",
    };
    const stylesAfter = {
      px: "1vw",
      width: "230px",
      padding: "10px",
      border: "1px solid #1212121A",
      borderRadius: "10px",
      opacity: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: "#FBFBFB",
    };
  
    const onSubmit = () => {
      queryClient.invalidateQueries("entity");
      toast.success("Documents uploaded successfully");
      close(true);
    };
  
    return (
      <>
        <Box
          sx={{
            overflow: "auto",
            width: "100%",
            px: 2,
            mt: "1vh",
            display: "flex",
            flexDirection: "column",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Typography variant="h5" gutterBottom>
            Director Information
          </Typography>
  
          <Box key={director?.id}>
            <Typography variant="h5" gutterBottom sx={{ mt: 1 }}>
              {director?.name}
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{ marginTop: "20px" }}
            >
              Upload Aadhar Card
            </Typography>
            <Stack direction={"row"} spacing={3}>
              <Box>
                <Typography
                  variant="caption"
                  gutterBottom
                  sx={{ marginTop: "20px", marginLeft: "10px" }}
                >
                  Front <span style={{ color: "red" }}>*</span>
                </Typography>
                <FileUploadContainer
                  actions={<></>}
                  data={"AADHAR_FRONT"}
                  typeData="DIRECTOR"
                  typeId={director?.id}
                  uploaded={
                    director?.documents?.filter(
                      (i: any) => i?.subtype === "AADHAR_FRONT"
                    )?.length
                  }
                  name={director?.documents
                    ?.filter((i: any) => i?.subtype === "AADHAR_FRONT")
                    .map((i: any) => i?.originalFileName)}
                  url={director?.documents
                    ?.filter((i: any) => i?.subtype === "AADHAR_FRONT")
                    .map((i: any) => i?.url)}
                  onFileChange={(key) => {
                    if (key === "") {
                      setIsFileDeleted(true);
                    } else {
                      setIsFileDeleted(false);
                      queryClient.invalidateQueries("entity");
                    }
                  }}
                  stylesBefore={stylesBefore}
                  stylesAfter={stylesAfter}
                  shrinked={true}
                />
              </Box>
              <Box>
                <Typography
                  variant="caption"
                  gutterBottom
                  sx={{ marginTop: "20px", marginLeft: "10px" }}
                >
                  Back
                </Typography>
                <FileUploadContainer
                  actions={<></>}
                  data={"AADHAR_BACK"}
                  typeData="DIRECTOR"
                  typeId={director?.id}
                  uploaded={
                    director?.documents?.filter(
                      (i: any) => i?.subtype === "AADHAR_BACK"
                    )?.length
                  }
                  name={director?.documents
                    ?.filter((i: any) => i?.subtype === "AADHAR_BACK")
                    .map((i: any) => i?.originalFileName)}
                  url={director?.documents
                    ?.filter((i: any) => i?.subtype === "AADHAR_BACK")
                    .map((i: any) => i?.url)}
                  onFileChange={(key) => {
                    if (key === "") {
                      setIsFileDeleted(true);
                    } else {
                      setIsFileDeleted(false);
                      queryClient.invalidateQueries("entity");
                    }
                  }}
                  stylesBefore={stylesBefore}
                  stylesAfter={stylesAfter}
                  shrinked={true}
                />
              </Box>
            </Stack>
  
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{ marginTop: "20px" }}
            >
              Upload PAN Card
            </Typography>
            <Stack direction={"row"} spacing={3}>
              <Box>
                <Typography
                  variant="caption"
                  gutterBottom
                  sx={{ marginTop: "20px", marginLeft: "10px" }}
                >
                  Front <span style={{ color: "red" }}>*</span>
                </Typography>
                <FileUploadContainer
                  actions={<></>}
                  typeId={director?.id}
                  data={"PAN_FRONT"}
                  typeData="DIRECTOR"
                  uploaded={
                    director?.documents?.filter(
                      (i: any) => i?.subtype === "PAN_FRONT"
                    )?.length
                  }
                  name={director?.documents
                    ?.filter((i: any) => i?.subtype === "PAN_FRONT")
                    .map((i: any) => i?.originalFileName)}
                  url={director?.documents
                    ?.filter((i: any) => i?.subtype === "PAN_FRONT")
                    .map((i: any) => i?.url)}
                  onFileChange={(key) => {
                    if (key === "") {
                      setIsFileDeleted(true);
                    } else {
                      setIsFileDeleted(false);
                      queryClient.invalidateQueries("entity");
                    }
                  }}
                  stylesBefore={stylesBefore}
                  stylesAfter={stylesAfter}
                  shrinked={true}
                />
              </Box>
              <Box>
                <Typography
                  variant="caption"
                  gutterBottom
                  sx={{ marginTop: "20px", marginLeft: "10px" }}
                >
                  Back
                </Typography>
                <FileUploadContainer
                  actions={<></>}
                  typeId={director?.id}
                  data={"PAN_BACK"}
                  typeData="DIRECTOR"
                  uploaded={
                    director?.documents?.filter(
                      (i: any) => i?.subtype === "PAN_BACK"
                    )?.length
                  }
                  name={director?.documents
                    ?.filter((i: any) => i?.subtype === "PAN_BACK")
                    .map((i: any) => i?.originalFileName)}
                  url={director?.documents
                    ?.filter((i: any) => i?.subtype === "PAN_BACK")
                    .map((i: any) => i?.url)}
                  onFileChange={(key) => {
                    if (key === "") {
                      setIsFileDeleted(true);
                    } else {
                      setIsFileDeleted(false);
                      queryClient.invalidateQueries("entity");
                    }
                  }}
                  stylesBefore={stylesBefore}
                  stylesAfter={stylesAfter}
                  shrinked={true}
                />
              </Box>
            </Stack>
  
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{ marginTop: "20px" }}
            >
              Address Proof
            </Typography>
            <Box sx={{ width: "350px" }}>
              <FormControl fullWidth>
                <Select
                  labelId="address-proof-select-label"
                  value={addressProofType || ""}
                  onChange={handleSelectChange}
                  size="small"
                  sx={{
                    width: "350px",
                    height: "4.5vh",
                    "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select":
                      {
                        fontFamily: "mundial",
                        color: "#2B2D22",
                        fontSize: "16px",
                      },
                  }}
                  disabled={disableAddressProof}
                >
                  {FirmAddressProof?.map((proof: string) => (
                    <MenuItem key={proof} value={proof}>
                      {proof}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
  
            {addressProofType && (
              <Box>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{ marginTop: "20px" }}
                >
                  Address Proof Document
                </Typography>
                <Stack direction={"row"} spacing={3}>
                  <Box>
                    <Typography
                      variant="caption"
                      gutterBottom
                      sx={{ marginTop: "20px", marginLeft: "10px" }}
                    >
                      Front <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <FileUploadContainer
                      actions={<></>}
                      typeId={director?.id}
                      filetype={addressProofType}
                      data={"ADDRESS_PROOF_FRONT"}
                      typeData="DIRECTOR"
                      uploaded={
                        director?.documents?.filter(
                          (i: any) => i?.subtype === "ADDRESS_PROOF_FRONT"
                        )?.length
                      }
                      name={director?.documents
                        ?.filter((i: any) => i?.subtype === "ADDRESS_PROOF_FRONT")
                        .map((i: any) => i?.originalFileName)}
                      url={director?.documents
                        ?.filter((i: any) => i?.subtype === "ADDRESS_PROOF_FRONT")
                        .map((i: any) => i?.url)}
                      onFileChange={(key) => {
                        if (key === "") {
                          setIsFileDeleted(true);
                          setDisableAddressProof(false);
                        } else {
                          setIsFileDeleted(false);
                          setDisableAddressProof(true);
                          queryClient.invalidateQueries("entity");
                        }
                      }}
                      stylesBefore={stylesBefore}
                      stylesAfter={stylesAfter}
                      shrinked={true}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      gutterBottom
                      sx={{ marginTop: "20px", marginLeft: "10px" }}
                    >
                      Back
                    </Typography>
                    <FileUploadContainer
                      actions={<></>}
                      typeId={director?.id}
                      filetype={addressProofType}
                      data={"ADDRESS_PROOF_BACK"}
                      typeData="DIRECTOR"
                      uploaded={
                        director?.documents?.filter(
                          (i: any) => i?.subtype === "ADDRESS_PROOF_BACK"
                        )?.length
                      }
                      name={director?.documents
                        ?.filter((i: any) => i?.subtype === "ADDRESS_PROOF_BACK")
                        .map((i: any) => i?.originalFileName)}
                      url={director?.documents
                        ?.filter((i: any) => i?.subtype === "ADDRESS_PROOF_BACK")
                        .map((i: any) => i?.url)}
                      onFileChange={(key) => {
                        if (key === "") {
                          setIsFileDeleted(true);
                          setDisableAddressProof(false);
                        } else {
                          setIsFileDeleted(false);
                          setDisableAddressProof(true);
                          queryClient.invalidateQueries("entity");
                        }
                      }}
                      stylesBefore={stylesBefore}
                      stylesAfter={stylesAfter}
                      shrinked={true}
                    />
                  </Box>
                </Stack>
              </Box>
            )}
  
            <Typography
              variant="body1"
              sx={{
                color: "#800000",
                my: "25px",
                fontStyle: "italic",
                opacity: 0.8,
              }}
            >
              Please make sure to upload all the required KYB/KYC documents for
              the {director?.name}
            </Typography>
          </Box>
        </Box>
  
        <Box
          sx={{
            paddingY: 2,
            marginTop: "3.4vh",
            marginX: 0,
            textAlign: "end",
            alignContent: "center",
          }}
        >
          {}
          <Button
            onClick={onSubmit}
            variant="contained"
            disabled={!(director?.areDocumentsUploaded && !isFileDeleted)}
            sx={{
              marginRight: 4,
            }}
          >
            Submit
          </Button>
        </Box>
      </>
    );
  };
  
  export default SignatoryDocumentsForm;
  