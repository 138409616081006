import { ArrowDropDown, Search } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getTransactions } from "../../api/services";
import Status from "../../components/status/Status";
import NewTable from "../../components/table/NewTable";
import { formatIndianIntegers, stripHTML } from "../../utils/utils";

const Transactions: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [filterStatus, setFilterStatus] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(0);

  const { data: transactionData, isLoading } = useQuery(
    [
      "transactions",
      {
        search: searchTerm,
        status: filterStatus,
        limit: limit,
        offset: page * limit,
      },
    ],
    getTransactions
  );

  const clearFilters = () => {
    setFilterStatus([]);
  };

  const statusOptions: string[] = [
    "PENDING",
    "PROCESSED",
    "PAYMENT VERIFICATION",
    "REJECTED",
    "RETURN",
  ];

  const handleStatusChange = (event: any) => {
    // localStorage.setItem("filters", event.target.value);
    const value = event.target.value;
    setFilterStatus(typeof value === "string" ? value.split(",") : value);
    // setFilteredData(event.target.value);
  };

  const handleSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 800);

  useEffect(() => {
    setFilterStatus(["PAYMENT VERIFICATION"]);
  }, []);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    // setLimit(parseInt(event.target.value, 20));
    setLimit(parseInt(event.target.value));
    setPage(0);
  };

  const handleCellClick = (ledgerId: number) => {
    navigate(`/transactions/${ledgerId}`);
  };

  const formatDate = (isoString: string) => {
    const date = new Date(isoString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const column = [
    {
      title: "Transaction Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.id);
      },
      render: (colData: any, row: any) => {
        return formatDate(colData);
      },
    },
    {
      title: " Transaction ID",
      dataIndex: "id",
      key: "id",
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.id);
      },
      render: (colData: any, row: any) => {
        return row?.type === "Credit" ? row?.utr : row?.crn;
      },
    },
    {
      title: "Deal Code",
      dataIndex: "dealCode",
      key: "dealCode",
      render: (colData: any, row: any) => {
        return row?.deal?.code;
      },
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.id);
      },
    },
    {
      title: "Transaction Amount",
      dataIndex: "buyerNameOrCompany",
      key: "buyerNameOrCompany",
      render: (colData: any, row: any) => {
        return (
          <Box>
            <Typography
              variant="body1"
              sx={{
                color: row?.type === "Debit" ? "red" : "green",
                textAlign: "left",
                marginLeft: "30px",
                cursor: "pointer",
                padding: "5px",
              }}
            >
              {" ₹ " + formatIndianIntegers(row?.amount)}
            </Typography>
          </Box>
        );
      },
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.id);
      },
    },

    {
      title: "Transaction Type",
      dataIndex: "type",
      key: "type",
      render: (colData: any, row: any) => {
        return (
          <Box>
            <Typography
              variant="body1"
              sx={{
                color: row?.type === "Debit" ? "red" : "green",
                textAlign: "left",
                marginLeft: "30px",
                cursor: "pointer",
                padding: "5px",
              }}
            >
              {row?.type}
            </Typography>
          </Box>
        );
      },
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.id);
      },
    },
    {
      title: " Status",
      dataIndex: "amount",
      key: "amount",
      render: (colData: any, row: any) => {
        return (
          <Stack
            direction={"row"}
            gap={"5px"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"250px"}
          >
            <Status variant={row?.status || "NA"}></Status>

            <Tooltip title={stripHTML(row?.statusHistory[0]?.remarks) || "NA"}>
              <InfoOutlinedIcon />
            </Tooltip>
          </Stack>
        );
      },
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.id);
      },
    },
  ];
  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        marginTop: "20px",
        marginBottom: "20px",
        padding: "10px",
        height: "84vh",
      }}
    >
      <Grid item xs={12}>
        <Card
          sx={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #1212121A",
            borderRadius: "10px",
            opacity: 1,
            padding: "20px",
            marginBottom: "20px",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            marginBottom="20px"
          >
            <Grid item>
              <Stack flexDirection={"row"}>
                <TextField
                  placeholder="Search by Transaction ID or Deal ID..."
                  variant="outlined"
                  size="small"
                  sx={{ width: "320px" }}
                  onChange={(e) => handleSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton size="small">
                          <Search fontSize="medium" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      width: "100%",
                      height: "40px",
                      boxShadow: "0px 6px 14px #36408D08",
                      border: "1px solid #2B2D221C",
                      borderRadius: "10px",
                      opacity: 1,
                      fontSize: "16px",
                      fontFamily: "Mundial, sans-serif",
                    },
                    inputProps: {
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "16px",
                      },
                    },
                  }}
                />

                <Stack direction={"row"} gap={"10px"}>
                  <Select
                    multiple
                    value={filterStatus}
                    onChange={handleStatusChange}
                    variant="outlined"
                    IconComponent={ArrowDropDown}
                    displayEmpty
                    renderValue={(selected) =>
                      selected.length > 0 ? (
                        <Box display="flex" alignItems="center">
                          Status
                          <Box
                            component="span"
                            borderRadius="50%"
                            color="#FFFFFF"
                            fontSize="10px"
                            marginLeft="5px"
                            width="15px"
                            height="15px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            style={{
                              backgroundColor: theme.palette.primary.main,
                            }}
                          >
                            {selected.length}
                          </Box>
                        </Box>
                      ) : (
                        "Status"
                      )
                    }
                    style={{
                      width: "130px",
                      height: "40px",
                      border: "1px solid #1212121A",
                      borderRadius: "10px",
                      opacity: 0.6,
                      boxShadow: "0px 6px 14px #36408D08",
                      fontSize: "14px",
                      fontFamily: "Mundial, sans-serif",
                      color: "#1D1D1D",
                      marginLeft: "10px",
                    }}
                    inputProps={{
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "14px",
                      },
                    }}
                    SelectDisplayProps={{
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "12px",
                      },
                    }}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        <Checkbox checked={filterStatus.indexOf(option) > -1} />
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>

                  {filterStatus.length > 0 && (
                    <Button
                      variant="outlined"
                      onClick={clearFilters}
                      sx={{
                        height: "38px",
                        marginLeft: "10px",
                        borderRadius: "8px",
                        width: "140px",
                      }}
                    >
                      Clear Filters
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          {isLoading ? (
            <LinearProgress />
          ) : (
            <NewTable
              columns={column}
              headAlign={"center"}
              tableAlign={"center"}
              dataSource={transactionData?.data}
              pagination={{
                page: page,
                rowsPerPage: limit,
                count: transactionData?.count,
                onPageChange: handlePageChange,
                onRowsPerPageChange: handleRowsPerPageChange,
              }}
            />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default Transactions;
