import { Grid, LinearProgress, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { getStatistics } from "../../api/services";
import { Icons } from "../../assets";
import StatsCard from "./statsCard";

const Dashboard: React.FC = () => {
  const { data: statisticsData, isLoading } = useQuery(
    "get-statistics",
    getStatistics
  );

  const usersData = [
    {
      label: "Total Registered Users",
      value: statisticsData?.data?.totalUsers || 0,
      icon: Icons.user,
    },
    {
      label: "KYC In-Progress Entities",
      value: statisticsData?.data?.kycInProgressEntities || 0,
      icon: Icons.user,
    },
    {
      label: "KYC Submitted Entities",
      value: statisticsData?.data?.kycSubmittedEntities || 0,
      icon: Icons.user,
    },
    {
      label: "KYC Verified Entities",
      value: statisticsData?.data?.kycVerifiedEntities || 0,
      icon: Icons.user,
    },
    {
      label: "KYC Rejected Entities",
      value: statisticsData?.data?.kycRejectedEntities || 0,
      icon: Icons.user,
    },
  ];

  const dealsData = [
    {
      label: "Total Deals",
      value: statisticsData?.data?.totalDeals || 0,
      icon: Icons.dealCard,
    },
    {
      label: "Completed Deals",
      value: statisticsData?.data?.completedDeals || 0,
      icon: Icons.dealCardTick,
    },
    {
      label: "Deals In-Progress",
      value: statisticsData?.data?.dealsInProcess || 0,
      icon: Icons.dealCardPending,
    },
    {
      label: "Average Deal Value",
      value: statisticsData?.data?.averageDealValue?.toFixed(2) || 0,
      icon: Icons.dealCardTopTick,
    },
  ];

  const trusteesData = [
    {
      label: "Active Trustees",
      value: statisticsData?.data?.totalTrustees || 0,
      icon: Icons.superUser,
    },
    {
      label: "Deals under Trustee's Verification",
      value: statisticsData?.data?.dealVerification || 0,
      icon: Icons.verificationPending,
    },
    {
      label: "Payments under Trustee's Verification",
      value: statisticsData?.data?.paymentVerification || 0,
      icon: Icons.paymentProgress,
    },
  ];

  if (isLoading) return <LinearProgress />;

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
          padding: "10px",
        }}
      >
        {/* Users Data Section */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ paddingBottom: "10px" }}>
            Users Data
          </Typography>
          <Grid container spacing={2}>
            {usersData.map((card, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <StatsCard
                  icon={card.icon}
                  value={Number(card.value)}
                  label={card.label}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Deals Data Section */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ paddingBottom: "10px" }}>
            Deals Data
          </Typography>
          <Grid container spacing={2}>
            {dealsData.map((card, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <StatsCard
                  icon={card.icon}
                  value={card.value}
                  label={card.label}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Trustee's Data Section */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ paddingBottom: "10px" }}>
            Trustee's Data
          </Typography>
          <Grid container spacing={2}>
            {trusteesData.map((card, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <StatsCard
                  icon={card.icon}
                  value={card.value}
                  label={card.label}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
