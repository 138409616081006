import { Close } from "@mui/icons-material";
import {
  Box,
  ButtonProps,
  DialogTitle,
  Drawer,
  DrawerProps,
  IconButton,
  styled,
} from "@mui/material";
import { ReactNode, useState } from "react";

const StyledDrawerHeader = styled(Box)(({ theme }) => ({
  maxWidth: "600px",
  background: "#7643EB",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "10px",
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  marginLeft: "0px",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(19),
  color: "white",
}));

const StyledDrawerContent = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 64px)",
  maxWidth: "600px",
  overflowY: "auto",
  padding: theme.spacing(2),
  paddingBottom: "4rem",
  boxSizing: "border-box",
  overflow: "auto",
}));

export interface DrawerButtonProps {
  anchor: (props: { open: () => void }) => ReactNode;
  content: (props: { close: () => void }) => ReactNode;
  title: string | ReactNode;
  btnTxt?: string | ReactNode;
  btnProps?: ButtonProps;
  sx?: React.CSSProperties;
  subtype: string;
  contentStyles?: any;
}

export default function DrawerButtonAction({
  content,
  title,
  anchor,
  contentStyles,
}: DrawerButtonProps) {
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      {anchor({ open: onOpen })}
      <CustomDrawer
        content={content}
        onClose={onClose}
        open={open}
        title={title}
        contentStyles = {contentStyles}
      />
    </>
  );
}

interface CustomDrawerProps {
  content: (props: { close: () => void }) => ReactNode;
  title: string | ReactNode;
  drawerProps?: Omit<DrawerProps, "open">;
  onClose: () => void;
  open: boolean;
  contentStyles?: any;
}

export const CustomDrawer = ({
  onClose,
  drawerProps,
  title,
  content,
  open,
  contentStyles,
}: CustomDrawerProps) => {
  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: "550px",
          background: "#FFFFFF",
          opacity: 1,
          borderRadius: "0px 0px",
          border: "none",
          overflow: "hidden",
        },
      }}
    >
      <StyledDrawerHeader>
        {/* <DialogTitle></DialogTitle> */}
        <StyledDialogTitle>{title}</StyledDialogTitle>
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <Close />
        </IconButton>
      </StyledDrawerHeader>
      <StyledDrawerContent sx={{ ...contentStyles }}>{content({ close: onClose })}</StyledDrawerContent>
    </Drawer>
  );
};
