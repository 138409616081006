import { Box, Grid, Typography } from "@mui/material";
import { formatIndianIntegers } from "../../utils/utils";
import FirmDocuments from "../entities/entity-details/FirmDocuments";
import Document from "../entities/entity-details/Document";

const TransactionInformation = ({ data, buyer, transactionLedger }: any) => {
  const bankData = buyer?.entity?.bankingInformation[0];

  return (
    <Box sx={{ ml: "20px", my: "20px" }}>
      <Typography variant="h4" sx={{ marginBottom: "20px" }}>
        Transaction Information
      </Typography>

      <Grid container spacing={5}>
        {data?.data?.companyType === "SOLE_PROPRIETORSHIP" ||
        data?.data?.companyType === "INDIVIDUAL" ? (
          <></>
        ) : (
          <Grid item xs={3} md={3} lg={3}>
            <Typography variant="caption">Remitter Name</Typography>
            <Typography variant="body2">{bankData?.beneficiaryName}</Typography>
          </Grid>
        )}

        <Grid item xs={3} md={3} lg={3}>
          <Typography variant="caption">Remitter Bank Account</Typography>
          <Typography variant="body2">{bankData?.accountNumber}</Typography>
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          <Typography variant="caption">Remitter IFSC Code</Typography>
          <Typography variant="body2">{bankData?.ifscCode}</Typography>
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          <Typography variant="caption">Amount</Typography>
          <Typography variant="body2">
            {" ₹ " + formatIndianIntegers(transactionLedger?.amount) || "NA"}
          </Typography>
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          <Typography variant="caption">Remarks</Typography>
          <Typography variant="body2" sx={{ marginBottom: "5px" }}>
            {transactionLedger?.statusHistory?.[0].request?.remarks || "NA"}
          </Typography>
        </Grid>

        {transactionLedger.mediaObject && (
          <Grid item xs={6}>
            <Document
              data={transactionLedger.mediaObject}
              typeData={transactionLedger.mediaObject?.subtype}
              subtype={transactionLedger.mediaObject?.subtype}
              typeId={transactionLedger.mediaObject?.id}
              isSupporting={transactionLedger.mediaObject?.isSupporting}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default TransactionInformation;
