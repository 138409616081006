import VisibilityIcon from "@mui/icons-material/Visibility";
import { Card, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { formatDateToDDMMYYYY_HHMMSS } from "./RequestReceivedTable";

// Types
interface RequestPaymentCardProps {
  userType: "Buyer" | "Seller";
  request: {
    amount: number;
    reasonForRequest: string;
    mediaUrl?: string;
    createdAt: string;
  };
}

// Helper Functions
const formatNumberToIndianSystem = (number: number) =>
  new Intl.NumberFormat("en-IN").format(number);

// Components
const RenderCardDetails = ({
  content,
  onClick,
  style,
}: {
  content: React.ReactNode;
  onClick?: () => void;
  style?: any;
}) => (
  <Typography variant="body1" sx={style} onClick={onClick}>
    {content}
  </Typography>
);

export default function RequestPaymentCard({
  userType,
  request,
}: RequestPaymentCardProps) {
  const theme = useTheme();
  const { dealId } = useParams();
  const navigate = useNavigate();

  const cellStyle = {
    textAlign: "left",
    cursor: "pointer",
    color: theme.palette.primary.main,
    textDecoration: "underline",
  };

  const handleNavigate = () => {
    if (userType === "Buyer") {
      navigate(`/deal-process/deals/${dealId}/initiate-payment`);
    }
  };

  return (
    <Card
      sx={{
        margin: "10px",
        borderRadius: "10px",
        padding: "5px",
        maxWidth: "600px",
      }}
    >
      <Stack padding="20px">
        {/* Amount Section */}
        <Stack marginTop="10px">
          <Typography variant="subtitle2" color="gray">
            Amount
          </Typography>
          <RenderCardDetails
            content={`₹ ${formatNumberToIndianSystem(request.amount)}`}
            onClick={handleNavigate}
            style={userType === "Buyer" ? cellStyle : null}
          />
        </Stack>

        {/* Reason Section */}
        <Stack marginTop="10px">
          <Typography variant="subtitle2" color="gray">
            Reason
          </Typography>
          <RenderCardDetails
            content={
              <span
                dangerouslySetInnerHTML={{
                  __html: request.reasonForRequest,
                }}
              />
            }
            onClick={handleNavigate}
            style={userType === "Buyer" ? cellStyle : null}
          />
        </Stack>

        {/* File Section */}
        <Stack marginTop="10px">
          <Typography variant="subtitle2" color="gray">
            File
          </Typography>
          {request.mediaUrl ? (
            <IconButton onClick={() => window.open(request.mediaUrl, "_blank")}>
              <VisibilityIcon />
            </IconButton>
          ) : (
            <Typography variant="subtitle1" color="black" fontWeight="400">
              NA
            </Typography>
          )}
        </Stack>

        {/* Date and Time Section */}
        <Stack marginTop="10px">
          <Typography variant="subtitle2" color="gray">
            Date and Time
          </Typography>
          <RenderCardDetails
            content={formatDateToDDMMYYYY_HHMMSS(request.createdAt)}
            onClick={handleNavigate}
            style={userType === "Buyer" ? cellStyle : null}
          />
        </Stack>
      </Stack>
    </Card>
  );
}
