import { Box, Grid, Typography } from "@mui/material";
import { Deal } from "../../../../interfaces/deal";
import { formatNumberToIndianSystem } from "../../../../utils/utils";
import EscrowTransactionsCards from "../deal-amount/EscrowTransactionCard";

interface FundsSummaryProps {
  dealData: Deal;
}

export default function FundsSummary({ dealData }: FundsSummaryProps) {
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" align="left" sx={{}}>
          Account Funds Summary
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="caption"> Truupe Account Number</Typography>
              <Typography variant="subtitle2">
                {dealData?.bankingDetails?.accountNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="caption"> IFSC Code</Typography>
              <Typography variant="subtitle2">
                {dealData?.bankingDetails?.ifscCode}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="caption">
                {" "}
                Current Account Balance{" "}
              </Typography>
              <Typography variant="subtitle2">
                {"  ₹  "}
                {formatNumberToIndianSystem(dealData?.overview?.balance)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box>
        <EscrowTransactionsCards
          dealData={dealData}
          dealId={dealData?.code}
        ></EscrowTransactionsCards>
      </Box>
    </>
  );
}
