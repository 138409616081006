import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import DealDeclined from "./DealDeclined"; // Ensure this path is correct

export default function DealCancelled({ data, secondUser }: any) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Deal Cancelled
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          {data?.signatureAttempts === 3 ? (
            <>
              The deal has been canceled due to multiple invalid Aadhaar
              e-signing attempts during the deal documentation process.
              <br></br>
              This decision was taken to maintain the integrity of the
              documentation and ensure compliance with our verification
              protocols. We understand that this may cause inconvenience, and we
              encourage you to carefully review the information before
              attempting to sign in the future.
            </>
          ) : (
            <>
              The Trustee Authority has cancelled your deal, You can view the
              reason provided by the Trustee for cancelling the deal. This will
              allow you to understand their decision and determine if you would
              like to revise the deal and continue the process.
            </>
          )}
        </Typography>
      </Stack>
      <DealDeclined open={isModalOpen} onClose={handleClose} data={data} />
      <Button
        variant="contained"
        sx={{ width: "auto", mt: "20px" }}
        onClick={handleOpen}
      >
        View Reason
      </Button>
    </Box>
  );
}
