import { Box, Button, Card, Grid, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { Icons, clockimage } from "../../../../assets";
import Status from "../../../../components/status/Status";
import RejectionModal from "../deal-steps/Reasonrejection";
import AadharAlertModal from "../../../../components/modals/AadharAlertModal";

interface Signee {
  name?: string;
  time?: string;
  status?: string;
}

const SigneeCard = ({
  signee,
  showSignButton,
  data,
  accountOwner,
  needed,
}: {
  signee?: Signee;
  showSignButton?: boolean;
  data?: any;
  accountOwner?: any;
  needed?: any;
}) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const email = needed?.data?.emailAddress;

  const username = email?.split("@")[0];
  // Capitalize the first letter of the extracted username
  const capitalizedUsername =
    username?.charAt(0).toUpperCase() + username?.slice(1);

  const secondUser = data?.dealUsers?.find(
    (user: any) => user?.userId !== needed?.data?.id
  );
  function areAllUsersSigned(data: any) {
    // Define the list of excluded identifiers
    const excludedIdentifiers = [
      "MITCON Trustee Escrow Agent",
      "TruuPe Admin Confirming Party",
    ];

    // Check if data.dealSignatures is an array, if not, return false
    if (!Array.isArray(data?.dealSignatures)) {
      console.error(
        "Invalid data format or data.dealSignatures is not an array."
      );
      return false;
    }

    // Iterate over the array to check each record's status
    for (const record of data.dealSignatures) {
      const identifier = record.preSignResponse?.identifier;

      if (
        !excludedIdentifiers.includes(identifier) &&
        record.status !== "Completed"
      ) {
        return false; // Return false if any non-excluded identifier has a status other than "Completed"
      }
    }

    // Return true if all relevant users have a "Completed" status
    return true;
  }

  const isAllUsersSigned = areAllUsersSigned(data);

  return (
    <Card
      sx={{
        width: "32vw",
        padding: 3,
        boxShadow: "none",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#151515",
          textTransform: "capitalize",
          marginBottom: "25px",
          fontWeight: 500,
        }}
      >
        Deal Signatures{" "}
      </Typography>

      {data?.dealSignatures.map((i: any, index: any) => {
        return (
          <>
            <Typography
              variant="h6"
              sx={{
                color: "#151515",
                textTransform: "capitalize",
                marginBottom: "10px",
                fontWeight: 400,
              }}
            >
              Signee {index + 1}
            </Typography>
            <Grid
              container
              spacing={1}
              alignItems="center"
              key={i?.userId || i?.preSignResponse?.displayName}
              marginBottom="10px"
            >
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "50%",
                    width: 36,
                    height: 36,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      i?.status === "Pending" ? clockimage : Icons.checkedimage
                    }
                    alt={
                      i?.status === "Pending" ? "Clock Icon" : "Checked Icon"
                    }
                    style={{ width: "50%", height: "auto" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box>
                  <Typography variant="body2">
                    {i?.userId
                      ? i?.user?.name
                      : i?.preSignResponse?.displayName}
                  </Typography>
                  {i?.status !== "Pending" && (
                    <Typography variant="caption">
                      {moment(i?.updatedAt).format("DD/MM/YYYY, hh:mm A")}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Status
                  variant={
                    data?.status === "Deal Cancelled" && i?.status === "Pending"
                      ? "Deal Cancelled"
                      : i?.status
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <>
                  {isAllUsersSigned &&
                    i?.status === "Pending" &&
                    needed?.data?.role?.name === "Trustee" &&
                    index === 2 &&
                    data?.dealSignatures[index - 1]?.status === "Completed" &&
                    data?.status !== "Deal Cancelled" && (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setModalOpen(true)}
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          Sign
                        </Button>

                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleOpen}
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          Reject
                        </Button>
                        <AadharAlertModal
                          username={capitalizedUsername}
                          openDialogue={modalOpen}
                          closeModel={setModalOpen}
                          aadharNumber={needed?.data?.aadharNumber}
                          redirectUrl={i.signUrl}
                        />
                      </Box>
                    )}
                  {i?.status === "Pending" &&
                    needed?.data?.role?.name === "Admin" &&
                    index === 3 &&
                    data?.dealSignatures[index - 1]?.status === "Completed" &&
                    data?.status !== "Deal Cancelled" && (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          // onClick={() => window.open(i?.signUrl)}
                          onClick={() => setModalOpen(true)}
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          Sign
                        </Button>
                        <AadharAlertModal
                          username={capitalizedUsername}
                          openDialogue={modalOpen}
                          closeModel={setModalOpen}
                          aadharNumber={needed?.data?.aadharNumber}
                          redirectUrl={i.signUrl}
                        />
                      </Box>
                    )}
                </>
              </Grid>
              <RejectionModal
                open={open}
                onClose={handleClose}
                data={data}
                secondUser={secondUser}
              />
            </Grid>
          </>
        );
      })}
    </Card>
  );
};

export default SigneeCard;
