import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { newDeal } from "../../../../assets";

// Styled components
const StyledTableContainer = styled(TableContainer)({
  borderRadius: "10px",
  overflow: "hidden",
  border: "1px solid #1212121A",
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: "#F3F3F3",
  fontFamily: "Mundial",
});

const StyledTableCell = styled(TableCell)({
  padding: "6px",
});

const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    color: theme.palette.primary.main,
    height: "27px",
    minWidth: "27px",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0 4px",
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  "& .MuiPaginationItem-page.Mui-selected:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  "& .Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledSelect = styled(Select)({
  width: "auto",
  height: "25px",
  background: "#FFFFFF",
  borderRadius: "5px",
  textAlign: "center",
  lineHeight: "20px",
  marginLeft: "10px",
  outline: "none",
  boxShadow: "none",
});

// Utility function to format numbers to the Indian system
const formatNumberToIndianSystem = (number: any) =>
  new Intl.NumberFormat("en-IN").format(number);

// Utility function to format date and time
export const formatDateToDDMMYYYY_HHMMSS = (dateString: any) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
};

// Component to render table cell content
const RenderTableCell = ({ content, onClick, style }: any) => (
  <StyledTableCell align="left">
    <Typography variant="body1" sx={style} onClick={onClick}>
      {content}
    </Typography>
  </StyledTableCell>
);

const RequestReceivedTable = ({
  paginatedData,
  
  currentPage,
  totalPages,
  setCurrentPage,
  itemsPerPage,
  handleChangeItemsPerPage,
  userType,
}: any) => {
  const { dealId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const cellStyle = {
    textAlign: "left",
    marginLeft: "20px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  };

  const headers = ["Amount", "Reason", "File", "Date and Time"];

  return (
    <>
      <StyledTableContainer>
        <Table>
          <StyledTableHead>
            <TableRow>
              {headers.map((header) => (
                <StyledTableCell key={header}>
                  <Typography variant="body1" sx={{ marginLeft: "20px" }}>
                    {header}
                  </Typography>
                </StyledTableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody sx={{ fontFamily: "Mundial", fontSize: "14px" }}>
            {paginatedData.map((request: any) => (
              <TableRow key={request.id}>
                <RenderTableCell
                  content={`₹ ${formatNumberToIndianSystem(request.amount)}`}
                  onClick={
                    userType === "Buyer"
                      ? () =>
                          navigate(
                            `/deal-process/deals/${dealId}/initiate-payment`
                          )
                      : null
                  }
                  style={userType === "Buyer" ? cellStyle : null}
                />
                <RenderTableCell
                  content={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: request.reasonForRequest,
                      }}
                    />
                  }
                  onClick={
                    userType === "Buyer"
                      ? () =>
                          navigate(
                            `/deal-process/deals/${dealId}/initiate-payment`
                          )
                      : null
                  }
                  style={userType === "Buyer" ? cellStyle : null}
                />
                <StyledTableCell align="left">
                  {request.mediaUrl ? (
                    <IconButton
                      sx={{ marginLeft: "15px" }}
                      onClick={() => window.open(request.mediaUrl, "_blank")}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  ) : (
                    <Typography marginLeft={"20px"}>NA</Typography>
                  )}
                </StyledTableCell>
                <RenderTableCell
                  content={formatDateToDDMMYYYY_HHMMSS(request.createdAt)}
                  onClick={
                    userType === "Buyer"
                      ? () =>
                          navigate(
                            `/deal-process/deals/${dealId}/initiate-payment`
                          )
                      : null
                  }
                  style={userType === "Buyer" ? cellStyle : null}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {paginatedData.length === 0 && (
          <Box sx={{ width: "100vw" }}>
            <Stack alignItems={"center"} gap={"1vh"} padding={"1vh"}>
              <img width={"10%"} src={newDeal} alt="loading...." />
              <Typography variant="h3">
                It looks like you don't have any Request yet.
              </Typography>
              <Typography variant="body1"></Typography>
            </Stack>
          </Box>
        )}
      </StyledTableContainer>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        marginBottom="20px"
        marginTop="10px"
      >
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
            mt: 2,
          }}
        >
          <StyledPagination
            count={totalPages}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            shape="rounded"
          />
          <StyledSelect
            value={itemsPerPage}
            onChange={(e) => handleChangeItemsPerPage(Number(e.target.value))}
            variant="outlined"
          >
            {[50, 100, 150].map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
      </Grid>
    </>
  );
};

export default RequestReceivedTable;
