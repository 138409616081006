import LinearProgress from "@mui/material/LinearProgress";
import { useQuery } from "react-query";
import { getUsers } from "../../api/services";
import NoUserFound from "./NoUserFound";
import UserTable from "./UserTable";

const Users = () => {
  const {
    data: UsersData,
    isLoading,
    refetch,
  } = useQuery(
    ["getUsersData", { search: "", role: "User", limit: "", offset: "" }],
    getUsers
  );
  const data = UsersData?.data?.data || [];

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          {data.length === 0 ? (
            <NoUserFound />
          ) : (
            <UserTable data={data} onDelete={refetch} />
          )}
        </>
      )}
    </>
  );
};

export default Users;
