import React, { useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import OTPInput from 'otp-input-react';
import './OtpModal.css';

const OtpModal = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const { handleSubmit } = useForm();

  const handleVerifyClick = () => {
    // Implement your OTP verification logic here
    navigate('/test');
  };

  return (
    <Modal open={true} onClose={() => {}}>
      <Box
        sx={{
          width: '450px',
          height: 'auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 4,
          p: 4,
        }}
      >
        <Box sx={{ mb: 6 }}>
          <Typography variant="h2" sx={{ fontFamily: 'Mundial', color: '#111111', mb: 1 }}>
            Payment OTP Verification
          </Typography>
          <Typography variant="body1" sx={{ color: '#111111', opacity: 0.7 }}>
            Please enter the code we sent to your mobile number
          </Typography>
        </Box>

        <Box >
          <form onSubmit={handleSubmit(handleVerifyClick)} style={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
                mb: 2,
              }}
              className="otp-input-container"
            >
              <OTPInput
                value={otp}
                onChange={setOtp}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                secure={false}
                inputStyles={{
                  width: '60px',
                  height: '60px',
                  margin: '0 5px',
                  textAlign: 'center',
                  fontSize: '24px',
                  border: '1px solid #E2E2E2',
                  borderRadius: '8px',
                  backgroundColor: '#F9F9F9',
                  outline: 'none',
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 3, width: '90%', height: '50px' , borderRadius: '8px' }} 
              >
                Verify
              </Button>
            </Box>
          </form>
        </Box>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ color: 'blue', cursor: 'pointer' }}>
            Didn't Receive the OTP?{' '}
          </Typography>
          <Button
            variant="text"
            sx={{
              fontSize: '16px',
              '&:hover': {
                color: '#7643EB',
                backgroundColor: 'white',
              },
            }}
            onClick={() => {
              // Add your logic to resend OTP here
            }}
          >
            Resend OTP
          </Button>
        </Box>


      </Box>
    </Modal>
  );
};

export default OtpModal;
