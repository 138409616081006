import React from "react";
import { Controller, Control, FieldErrors } from "react-hook-form";
import InputField from "./InputField";
import {
  upperCaseValue,
  lowerCaseValue,
  onlyNumbers,
  alphanumeric,
  alphabetsOnly,
  formatAadharNumber,
  formatOtp,
  formatMobileNumber,
  formatEmail,
} from "../../utils/formValidations";

export enum Formate {
  StrictUpperCase,
  StrictLowerCase,
  OnlyNumbers,
  Alphanumeric,
  AlphabetsOnly,
  AadharNumber,
  Otp,
  MobileNumber,
  EmailAddress,
}

interface MultiInputFieldProps {
  name: string;
  control: Control<any>;
  label: string;
  disabled?: boolean;
  errors: FieldErrors<any>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rules?: any;
  type?: string;
  sx?: any;
  key: string;
  variant: Formate;
  customPattern?: RegExp; // Optional for custom pattern
}

const MultiInputField: React.FC<MultiInputFieldProps> = ({
  name,
  control,
  label,
  disabled = false,
  errors,
  rules,
  type = "text",
  sx = {},
  variant,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let formattedValue = e.target.value;

    switch (variant) {
      case Formate.StrictUpperCase:
        formattedValue = upperCaseValue(formattedValue);
        break;

      case Formate.StrictLowerCase:
        formattedValue = lowerCaseValue(formattedValue);
        break;

      case Formate.OnlyNumbers:
        formattedValue = onlyNumbers(formattedValue);
        break;

      case Formate.Alphanumeric:
        formattedValue = alphanumeric(formattedValue);
        break;

      case Formate.AlphabetsOnly:
        formattedValue = alphabetsOnly(formattedValue);
        break;

      case Formate.AadharNumber:
        formattedValue = formatAadharNumber(formattedValue);
        break;

      case Formate.Otp:
        formattedValue = formatOtp(formattedValue);
        break;

      case Formate.MobileNumber:
        formattedValue = formatMobileNumber(formattedValue);
        break;

      case Formate.EmailAddress:
        formattedValue = formatEmail(formattedValue);
        break;

      default:
        break;
    }

    return formattedValue;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <InputField
          field={{
            ...field,
            onChange: (e: any) => {
              const formattedValue = handleChange(e);
              field.onChange(formattedValue);
            },
          }}
          name={label}
          iD={name}
          isdisabled={disabled}
          errors={!!errors[name]}
          type={type}
          sx={sx}
        />
      )}
    />
  );
};

export default MultiInputField;
