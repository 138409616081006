import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { deleteMultipleTrustees, getTrusteeById } from "../../api/services";
import { Icons, newDeal } from "../../assets";
import ButtonWithIcon from "../../components/buttons/ButtonIcon";
import ConfirmDeleteDialog from "../../components/modals/ConfirmDeleteDialog";
import CreateTrustee from "./trustee-forms/CreateTrustee";
import EditTrustee from "./trustee-forms/EditTrustee";

interface Trustee {
  id: number;
  name: string;
  mobileNumber: string;
  emailAddress: string;
  status: string;
  aadharNumber: string;
}

interface TrusteeTableProps {
  data: Trustee[];
  onDelete: () => void;
  onCreate: () => void;
}

const TrusteeTable: React.FC<TrusteeTableProps> = ({
  data,
  onDelete,
  onCreate,
}) => {
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState<string[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isCreateTrusteeOpen, setIsCreateTrusteeOpen] =
    useState<boolean>(false);
  const [isEditTrusteeOpen, setIsEditTrusteeOpen] = useState<boolean>(false); // State for showing/hiding the EditTrustee component
  const [selectedTrustees, setSelectedTrustees] = useState<Set<number>>(
    new Set()
  );
  const [selectedTrusteeId, setSelectedTrusteeId] = useState<number | null>(
    null
  ); // State to store the selected trustee ID
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const statusOptions: string[] = Array.from(
    new Set(data.map((trustee: Trustee) => trustee.status))
  );

  const filteredData: Trustee[] = useMemo(() => {
    return data.filter(
      (trustee: Trustee) =>
        (filterStatus.length === 0 || filterStatus.includes(trustee.status)) &&
        (!searchTerm ||
          trustee.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [data, filterStatus, searchTerm]);

  const totalItems: number = filteredData.length;
  const totalPages: number = Math.ceil(totalItems / itemsPerPage);
  const paginatedData: Trustee[] = filteredData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 500);

  useEffect(() => {
    setCurrentPage(1); // Reset to first page on data change
  }, [filteredData]);

  const handleStatusChange = (event: any) => {
    const value = event.target.value;
    setFilterStatus(typeof value === "string" ? value.split(",") : value);
  };

  const handleEditTrustee = async (id: number) => {
    if (selectedTrustees.size === 1) {
      setSelectedTrusteeId(id);
      setIsEditTrusteeOpen(true); // Open the EditTrustee component
    } else {
      toast.error("Please select a single trustee to edit");
    }
  };

  const handleAddNewTrustee = () => {
    setIsCreateTrusteeOpen(true);
  };

  const handleCellClick = async (id: number) => {
    // Fetch trustee data by ID
    try {
      const response = await getTrusteeById(id);
      const trusteeData = response.data;
      setSelectedTrusteeId(id);
      setIsEditTrusteeOpen(true);
    } catch (error) {
      console.error("Failed to fetch trustee data:", error);
      toast.error("Failed to fetch trustee data");
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedTrustees = new Set(
        paginatedData.map((trustee) => trustee.id)
      );
      setSelectedTrustees(newSelectedTrustees);
    } else {
      setSelectedTrustees(new Set());
    }
  };

  const handleSelectTrustee = (id: number) => {
    const newSelectedTrustees = new Set(selectedTrustees);
    if (newSelectedTrustees.has(id)) {
      newSelectedTrustees.delete(id);
    } else {
      newSelectedTrustees.add(id);
    }
    setSelectedTrustees(newSelectedTrustees);
    setSelectedTrusteeId(id);
  };

  const handleDeleteSelectedTrustees = async () => {
    if (selectedTrustees.size > 0) {
      setIsDialogOpen(true); // Open confirmation dialog
    } else {
      toast.error("Please select at least one trustee to delete");
    }
  };

  const confirmDeleteTrustees = async () => {
    try {
      await deleteMultipleTrustees(Array.from(selectedTrustees));
      toast.success("Trustees deleted successfully");
      onDelete();
      setSelectedTrustees(new Set());
      setIsDialogOpen(false); // Close dialog after successful deletion
    } catch (error) {
      console.error("Error deleting trustees:", error);
      toast.error("Failed to delete trustees");
    }
  };

  const cellStyle = {
    textAlign: "left",
    marginLeft: "25px",
    cursor: "pointer",
    padding: "5px",
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
          padding: "10px",
          height: "84vh",
        }}
      >
        <Grid item xs={12}>
          <Paper
            sx={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #1212121A",
              borderRadius: "10px",
              opacity: 1,
              padding: "20px",
              marginBottom: "20px",
            }}
          >
            <Grid container alignItems="center" spacing={2} marginBottom="20px">
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <TextField
                    placeholder="Search By Trustee Name"
                    variant="outlined"
                    size="small"
                    onChange={(e) => handleSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton size="small">
                            <Search fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        width: "500px",
                        height: "40px",
                        boxShadow: "0px 6px 14px #36408D08",
                        border: "1px solid #2B2D221C",
                        borderRadius: "10px",
                        opacity: 1,
                        fontSize: "16px",
                        fontFamily: "Mundial, sans-serif",
                      },
                      inputProps: {
                        style: {
                          fontFamily: "Mundial, sans-serif",
                          fontSize: "16px",
                        },
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                {selectedTrustees.size > 0 && selectedTrustees.size === 1 && (
                  <ButtonWithIcon
                    text="Edit Trustee"
                    icon={Icons.edit}
                    onClick={() => handleEditTrustee(selectedTrusteeId!)}
                  />
                )}
                {selectedTrustees.size > 0 && (
                  <ButtonWithIcon
                    text={
                      selectedTrustees.size === 1
                        ? "Delete Trustee"
                        : "Delete Trustees"
                    }
                    icon={Icons.deleteicon}
                    onClick={handleDeleteSelectedTrustees}
                  />
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddNewTrustee}
                >
                  Add New Trustee
                </Button>
              </Grid>
            </Grid>

            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "10px",
                overflow: "hidden",
                border: "1px solid #1212121A",
              }}
            >
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "#f3f3f3",
                    fontFamily: "Mundial",
                  }}
                >
                  <TableRow>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Checkbox
                        sx={{
                          marginLeft: "30px",
                          "& .MuiSvgIcon-root": {
                            fontSize: "20px",
                          },
                        }}
                        indeterminate={
                          selectedTrustees.size > 0 &&
                          selectedTrustees.size < paginatedData.length
                        }
                        checked={
                          paginatedData.length > 0 &&
                          selectedTrustees.size === paginatedData.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                        Trustee Name
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                        Mobile Number
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                        Email Address
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ padding: "5px" }}>
                      <Typography variant="body1" sx={{ marginLeft: "30px" }}>
                        Aadhar Number
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ fontFamily: "Mundial" }}>
                  {paginatedData?.length >= 1 ? (
                    paginatedData.map((trustee) => (
                      <TableRow key={trustee.id}>
                        <TableCell align="left" sx={{ padding: "5px" }}>
                          <Checkbox
                            sx={{
                              marginLeft: "30px",
                              "& .MuiSvgIcon-root": {
                                fontSize: "20px",
                              },
                            }}
                            checked={selectedTrustees.has(trustee.id)}
                            onChange={() => handleSelectTrustee(trustee.id)}
                          />
                        </TableCell>
                        <TableCell align="left" sx={{ padding: "5px" }}>
                          <Typography
                            variant="body1"
                            sx={cellStyle}
                            onClick={() => handleCellClick(trustee.id)}
                          >
                            {trustee.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ padding: "5px" }}>
                          <Typography
                            variant="body1"
                            sx={cellStyle}
                            onClick={() => handleCellClick(trustee.id)}
                          >
                            {trustee.mobileNumber}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ padding: "5px" }}>
                          <Typography
                            variant="body1"
                            sx={cellStyle}
                            onClick={() => handleCellClick(trustee.id)}
                          >
                            {trustee.emailAddress}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ padding: "5px" }}>
                          <Typography
                            variant="body1"
                            sx={cellStyle}
                            onClick={() => handleCellClick(trustee.id)}
                          >
                            {trustee.aadharNumber || "NA"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <>
                      <TableCell colSpan={7} align="center">
                        <img width={"30%"} src={newDeal} alt="loading...." />
                        <Typography variant="h5">
                          No data available...
                        </Typography>
                      </TableCell>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              marginTop="20px"
            >
              <Grid item xs={1.5}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, value) => setCurrentPage(value)}
                  shape="rounded"
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: theme.palette.primary.main,
                      height: "27px", // Adjusted height for pagination item
                      minWidth: "27px", // Ensures a square shape
                      fontSize: "14px", // Adjust font size if needed
                      fontWeight: "bold", // Bold font weight for better readability
                      margin: "0 4px", // Adds spacing between pagination items
                    },
                    "& .MuiPaginationItem-page.Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                    "& .MuiPaginationItem-page.Mui-selected:hover": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                    "& .Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={5} style={{ textAlign: "right" }}>
                Rows per page:
                <Select
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(Number(e.target.value))}
                  variant="outlined"
                  style={{
                    width: "auto",
                    height: "25px",
                    background: "#FFFFFF",
                    borderRadius: "5px",
                    textAlign: "center",
                    lineHeight: "20px",
                    marginLeft: "10px",
                    outline: "none",
                    boxShadow: "none",
                  }}
                >
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={150}>150</MenuItem>
                  <MenuItem value={200}>200</MenuItem>
                  <MenuItem value={250}>250</MenuItem>
                </Select>
              </Grid>
            </Grid> */}
            <TablePagination
              component="div"
              count={totalItems}
              page={currentPage - 1} // Adjusting to zero-based index
              onPageChange={(event, newPage) => setCurrentPage(newPage + 1)} // Setting page to 1-based index
              rowsPerPage={itemsPerPage}
              onRowsPerPageChange={(event) => {
                setItemsPerPage(parseInt(event.target.value, 10));
                setCurrentPage(1); // Reset to first page on items per page change
              }}
              rowsPerPageOptions={[25, 50, 100, 150, 200, 250]}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: theme.palette.primary.main,
                  height: "27px",
                  minWidth: "27px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  margin: "0 4px",
                },
                "& .MuiPaginationItem-page.Mui-selected": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>

      <CreateTrustee
        open={isCreateTrusteeOpen}
        onClose={() => setIsCreateTrusteeOpen(false)}
        onCreate={onCreate}
      />
      <EditTrustee
        trusteeId={selectedTrusteeId}
        open={isEditTrusteeOpen}
        onClose={() => {
          setIsEditTrusteeOpen(false);
          setSelectedTrusteeId(null);
        }}
      />
      <ConfirmDeleteDialog
        open={isDialogOpen}
        closeDialog={() => setIsDialogOpen(false)}
        confirmDelete={confirmDeleteTrustees} // Pass the confirm function to the dialog
        role="Trustee" // Pass "trustee" as the role
      />
    </>
  );
};

export default TrusteeTable;
