import { ArrowDropDown, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getEntities } from "../../api/services";
import Status from "../../components/status/Status";
import NewTable from "../../components/table/NewTable";
import { stripHTML } from "../../utils/utils";

interface Director {
  name: string;
  emailAddress: string;
  createdAt: string;
  // Add other properties of director as needed
}

interface Entity {
  id: number;
  companyName: string;
  companyType: string;
  amount: string;
  category: string;
  status: string;
  updatedAt: string;
  user: {
    name: string;
    emailAddress: string;
    createdAt: string;
  };
  directors: Director[];
}

const Entities: React.FC = () => {
  const theme = useTheme();
  const [filterStatus, setFilterStatus] = useState<string[]>([]);
  const [filterEntityType, setFilterEntityType] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    setFilterStatus(["Kyc Submitted", "Verify Changes"]);
  }, []);

  const { data: entityData, isLoading } = useQuery(
    [
      "getEntityData",
      {
        search: searchTerm,
        status: filterStatus,
        entityType: filterEntityType,
        limit: limit,
        offset: page * limit,
      },
    ],
    getEntities
  );

  const statusOptions: string[] = [
    "Kyc Submitted",
    "Verify Changes",
    "Verified",
    "Rejected",
  ];
  const entityTypeOptions: string[] = [
    "PRIVATE_LIMITED",
    "LIMITED_LIABILITY_PARTNERSHIP",
    "PARTNERSHIP",
    "SOLE_PROPRIETORSHIP",
    "OTHER",
    "INDIVIDUAL",
  ]; // Add other entity types if necessary

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    // setLimit(parseInt(event.target.value, 20));
    setLimit(parseInt(event.target.value));
    setPage(0);
  };

  const handleSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 500); // 500ms delay

  const handleStatusChange = (event: any) => {
    const value = event.target.value;
    setFilterStatus(typeof value === "string" ? value.split(",") : value);
  };

  const clearFilters = () => {
    setFilterStatus([]);
    setFilterEntityType([]);
    setSearchTerm("");
  };

  const handleCellClick = (id: number, status: string) => {
    navigate(`/entities/${id}`);
  };

  const formatDate = (isoString: string) => {
    const date = new Date(isoString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const column = [
    {
      title: "Entity ID",
      dataIndex: "id",
      key: "id",
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.id, row?.status);
      },
      render: (colData: any, row: any) => {
        return `TRPE0000${colData}`;
      },
    },
    {
      title: "Entity Name",
      dataIndex: "companyName",
      key: "companyName",
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.id, row?.status);
      },
      render: (colData: any, row: any) => {
        return (
          row.companyName ||
          (row?.companyType === "INDIVIDUAL" && row?.directors[0]?.name)
        );
      },
    },
    {
      title: "Entity Type",
      dataIndex: "companyType",
      key: "companyType",
      render: (colData: any, row: any) => {
        return colData.replace(/_/g, " ");
      },
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.id, row?.status);
      },
    },
    {
      title: "User Name",
      dataIndex: "user",
      key: "user",
      render: (colData: any, row: any) => {
        return row?.user?.name || "NA";
      },
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.id, row?.status);
      },
    },
    {
      title: "User Email",
      dataIndex: "userEmail",
      key: "userEmail",
      render: (colData: any, row: any) => {
        return row?.user?.emailAddress || "NA";
      },
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.id, row?.status);
      },
    },
    {
      title: "Entity Created Date",
      dataIndex: "entityCreatedDate",
      key: "entityCreatedDate",
      render: (colData: any, row: any) => {
        return formatDate(row?.createdAt);
      },
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.id, row?.status);
      },
    },
    {
      title: "Entity Status",
      dataIndex: "status",
      key: "status",
      render: (colData: any, row: any) => {
        return (
          <Status
            variant={row.status}
            showTooltip={true}
            tooltipText={stripHTML(row.reason) || "NA"}
          />
        );
      },
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.id, row?.status);
      },
    },
  ];

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        marginTop: "20px",
        marginBottom: "20px",
        padding: "10px",
        height: "84vh",
      }}
    >
      <Grid item xs={12}>
        <Card
          sx={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #1212121A",
            borderRadius: "10px",
            opacity: 1,
            padding: "20px",
            marginBottom: "20px",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            marginBottom="20px"
          >
            <Grid item>
              <TextField
                placeholder="Search By Entity ID, Entity Name"
                variant="outlined"
                size="small"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small">
                        <Search fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    width: "500px",
                    height: "40px",
                    boxShadow: "0px 6px 14px #36408D08",
                    border: "1px solid #2B2D221C",
                    borderRadius: "10px",
                    opacity: 1,
                    fontSize: "16px",
                    fontFamily: "Mundial, sans-serif",
                  },
                }}
                inputProps={{
                  style: {
                    fontFamily: "Mundial, sans-serif",
                    fontSize: "16px",
                  },
                  placeholderStyle: {
                    fontSize: "12px", // Adjust placeholder font size here
                    fontFamily: "Mundial, sans-serif",
                  },
                }}
              />

              <Select
                multiple
                value={filterStatus}
                onChange={handleStatusChange}
                variant="outlined"
                IconComponent={ArrowDropDown}
                displayEmpty
                renderValue={(selected) =>
                  selected.length > 0 ? (
                    <Box display="flex" alignItems="center">
                      Status
                      <Box
                        component="span"
                        borderRadius="50%"
                        color="#FFFFFF"
                        fontSize="10px"
                        marginLeft="5px"
                        width="15px"
                        height="15px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{ backgroundColor: theme.palette.primary.main }}
                      >
                        {selected.length}
                      </Box>
                    </Box>
                  ) : (
                    "Status"
                  )
                }
                style={{
                  width: "130px",
                  height: "40px",
                  border: "1px solid #1212121A",
                  borderRadius: "10px",
                  opacity: 0.6,
                  boxShadow: "0px 6px 14px #36408D08",
                  fontSize: "14px",
                  fontFamily: "Mundial, sans-serif",
                  color: "#1D1D1D",
                  marginLeft: "10px",
                }}
                inputProps={{
                  style: {
                    fontFamily: "Mundial, sans-serif",
                    fontSize: "14px",
                  },
                }}
                SelectDisplayProps={{
                  style: {
                    fontFamily: "Mundial, sans-serif",
                    fontSize: "12px",
                  },
                }}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={filterStatus.indexOf(option) > -1} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
              <Select
                multiple
                value={filterEntityType}
                onChange={(event) => {
                  const value = event.target.value;
                  setFilterEntityType(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                variant="outlined"
                IconComponent={ArrowDropDown}
                displayEmpty
                renderValue={(selected) =>
                  selected.length > 0 ? (
                    <Box display="flex" alignItems="center">
                      Entity Type
                      <Box
                        component="span"
                        borderRadius="50%"
                        color="#FFFFFF"
                        fontSize="10px"
                        marginLeft="5px"
                        width="15px"
                        height="15px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{ backgroundColor: theme.palette.primary.main }}
                      >
                        {selected.length}
                      </Box>
                    </Box>
                  ) : (
                    "Entity Type"
                  )
                }
                style={{
                  width: "130px",
                  height: "40px",
                  border: "1px solid #1212121A",
                  borderRadius: "10px",
                  opacity: 0.6,
                  boxShadow: "0px 6px 14px #36408D08",
                  fontSize: "14px",
                  fontFamily: "Mundial, sans-serif",
                  color: "#1D1D1D",
                  marginLeft: "10px",
                }}
                inputProps={{
                  style: {
                    fontFamily: "Mundial, sans-serif",
                    fontSize: "14px",
                  },
                }}
                SelectDisplayProps={{
                  style: {
                    fontFamily: "Mundial, sans-serif",
                    fontSize: "12px",
                  },
                }}
              >
                {entityTypeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={filterEntityType.indexOf(option) > -1} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>

              {filterStatus.length > 0 && (
                <Button
                  variant="outlined"
                  onClick={clearFilters}
                  sx={{
                    border: "1.5px solid #7643EB",
                    height: "38px",
                    marginLeft: "10px",
                    borderRadius: "10px",
                  }}
                >
                  Clear Filters
                </Button>
              )}
            </Grid>

            <Grid item>{/* View Mode Toggle commented out for brevity */}</Grid>
          </Grid>

          {isLoading ? (
            <LinearProgress />
          ) : (
            <NewTable
              columns={column}
              headAlign={"center"}
              tableAlign={"center"}
              dataSource={entityData?.data}
              pagination={{
                page: page,
                rowsPerPage: limit,
                count: entityData?.count,
                onPageChange: handlePageChange,
                onRowsPerPageChange: handleRowsPerPageChange,
              }}
            />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default Entities;
