import React from "react";
import { Box, Modal, Typography, Button, Divider } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TransactionInformation from "./TransactionInformation";
import BeneficiaryInformation from "./BeneficiaryInformation";
import { Icons } from "../../assets";
import { useNavigate, useNavigation } from "react-router-dom";

interface TransactionSuccessProps {
  open: boolean;
  handleClose: () => void;
  seller: any;
  buyer: any;
  transactionLedger: any;
}

const TransactionSuccess: React.FC<TransactionSuccessProps> = ({
  open,
  handleClose,
  seller,
  buyer,
  transactionLedger,
}) => {
  const navigate = useNavigate();
  const Close = () => {
    handleClose();
    navigate("/transactions");
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="transaction-success-modal"
      aria-describedby="transaction-success-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{ bgcolor: "background.paper", p: 4, borderRadius: 2, width: 900 }}
      >
        <Box sx={{ textAlign: "center", mb: 2, mt: 2 }}>
          <img
            src={Icons.checked}
            alt="checked"
            style={{ width: 70, height: "auto" }}
          />
        </Box>
        <Typography
          variant="h3"
          id="transaction-success-modal"
          sx={{ textAlign: "center", fontWeight: 600 }}
        >
          Transaction Approved Successfully!
        </Typography>
        <Typography
          variant="body1"
          id="transaction-success-description"
          sx={{ textAlign: "center", mt: 2 }}
        >
          Your transaction has been completed successfully.
        </Typography>
        <Divider sx={{ mt: 2 }} />
        <Box>
          <TransactionInformation
            buyer={buyer}
            transactionLedger={transactionLedger}
          />
          <Divider />
          <BeneficiaryInformation
            seller={seller}
            transactionLedger={transactionLedger}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={Close}
          sx={{ mt: 2, textAlign: "center" }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default TransactionSuccess;
