import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

export default function AcceptDealSignatoryDirectors({
  directorsData,
  setSignDirector,
}: any) {
  const [selectedDirector, setSelectedDirector] = useState("");

  const handleChange = (event: any) => {
    const selectedId = event.target.value;
    setSelectedDirector(selectedId);
    setSignDirector(selectedId);
  };

  return (
    <Box my={"10px"}>
      <Typography>Signature Authority</Typography>
      <Box sx={{ mt: 3 }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selectedDirector}
            onChange={handleChange}
          >
            {directorsData?.map((director: any) => (
              <Box key={director.id} mb={2}>
                <FormControlLabel
                  value={director.id}
                  control={<Radio />}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "30px",
                      }}
                    >
                      <Box>
                        <Typography variant="caption"> Name</Typography>
                        <Typography>{director.name}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption">Email</Typography>
                        <Typography variant="body1" color="textSecondary">
                          {director.email || "N/A"}
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              </Box>
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
}
