import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../../../../assets";
import failed from "./failed.json";

export default function RefundStatusModel({
  openModel,
  setOpenModel,
  amount,
  isFailed,
  dealCode,
}: any) {
  const navigate = useNavigate();

  return (
    <Box>
      <Modal
        open={openModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box
            sx={{
              position: "relative",
              top: "25%",
              left: {
                xs: "10%",
                sm: "20%",
                md: "30%",
                xl: "30%",
                lg: "30%",
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "80%",
                  sm: "60%",
                  md: "40%",
                  xl: "40%",
                  lg: "40%",
                },
                display: "flex",
                justifyContent: "end",
                my: "1vh",
              }}
            ></Box>

            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 24,
                width: {
                  xs: "80%",
                  sm: "60%",
                  md: "40%",
                  xl: "40%",
                  lg: "40%",
                },
                p: 4,
                borderRadius: "10px",
              }}
            >
              <Stack gap={"1vh"} alignItems={"center"}>
                {isFailed ? (
                  <>
                    {" "}
                    <Lottie animationData={failed} loop={false} autoplay />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      width={"25%"}
                      src={Icons.greenCheck}
                      alt="loading...."
                    />
                  </>
                )}

                <Typography variant="h3" textAlign={"center"}>
                  {isFailed ? (
                    <>Refund Initiation Failed!</>
                  ) : (
                    <>Refund Initiated Successfully!</>
                  )}
                </Typography>
                <Typography variant="h3">{" ₹ " + amount || "NA"}</Typography>
                <Typography variant="body1" color={"grey"} textAlign={"center"}>
                  {isFailed ? (
                    <>
                      The refund initiation has failed or been canceled. Please
                      try again later. If the issue persists, contact the Truupe
                      team for further assistance.
                    </>
                  ) : (
                    <>
                      The refund has been successfully initiated to the buyer's
                      bank account. You can view the status of the refund in the
                      Transactions Table.
                    </>
                  )}
                </Typography>
                <Button
                  sx={{ marginTop: "1vh" }}
                  variant="contained"
                  onClick={() => {
                    // navigate(`/deals/${dealCode}/manage-funds`, {
                    //   replace: true,
                    // });
                    window.location.reload();
                  }}
                >
                  Go back
                </Button>
              </Stack>
            </Box>
          </Box>
        </>
      </Modal>
    </Box>
  );
}
