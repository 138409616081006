import { Box, Grid, Typography } from "@mui/material";
import { useQueryClient } from "react-query";
import FileUploadContainer from "../../../../components/shared/FileUploadContainer";

const subtypeOrder = [
  "AADHAR_FRONT",
  "AADHAR_BACK",
  "PAN_FRONT",
  "PAN_BACK",
  "ADDRESS_PROOF_FRONT",
  "ADDRESS_PROOF_BACK",
];

const compareSubtypes = (a: { subtype: string }, b: { subtype: string }) => {
  const orderA = subtypeOrder.indexOf(a.subtype);
  const orderB = subtypeOrder.indexOf(b.subtype);
  if (orderA !== -1 && orderB !== -1) {
    return orderA - orderB;
  }
  if (orderA !== -1) return -1;
  if (orderB !== -1) return 1;
  return a.subtype.localeCompare(b.subtype);
};

type SignatoryAuthorityDocumentProps = {
  data: any;
  key: any;
  currentDirectorCount: number;
  isSupporting: boolean;
  reqDocIds: string;
  status: string;
};

const SignatoryAuthorityDocument = ({
  data,
  key,
  currentDirectorCount,
  isSupporting,
  reqDocIds,
  status,
}: SignatoryAuthorityDocumentProps) => {
  const queryClient = useQueryClient();

  const onFileChange = () => {
    queryClient.invalidateQueries("entity");
  };

  const requiredDocIds = reqDocIds
    ?.split(",")
    .map((id: any) => Number(id.trim()));

  const getDocStatus = (document: any) => {
    if (requiredDocIds?.includes(document?.id)) {
      if (status === "Kyc Submitted") return "REUPLOADED";
      if (status === "Rejected") return "REJECTED";
    }
    return "";
  };

  return (
    <Box marginLeft="20px" marginBottom="25px">
      {data?.companyType === "INDIVIDUAL" ? (
        <></>
      ) : (
        <>
          <Typography variant="subtitle2" sx={{ mb: 2, mt: 3 }}>
            {`Signatory ${" "} ${currentDirectorCount} - ${data?.name}`}
          </Typography>

          <Grid container spacing={3} sx={{ marginBottom: "20px" }}>
            {data?.documents
              ?.sort(compareSubtypes)
              .map((document: any, index: number) => {
                return (
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
                    <FileUploadContainer
                      typeId={data?.id}
                      actions={<></>}
                      data={data.documents[index]}
                      typeData="DIRECTOR"
                      name={document.originalFileName}
                      url={document.url}
                      subType={document.subtype}
                      onFileChange={onFileChange}
                      isSupporting={isSupporting ? "true" : "false"}
                      isEditable={isSupporting ? false : true}
                      showIcons={isSupporting ? false : true}
                      uploaded={true}
                      docStatus={getDocStatus(document)}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default SignatoryAuthorityDocument;
