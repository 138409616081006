import { Controller } from "react-hook-form";
import FormInputField from "./FormInputField";

type DateFieldProps = {
  control: any;
  name: string;
  title: string;
  getMinDate?: string;
  getMaxDate?: string;
  width: string;
  errors: any;
};

export default function DateField({
  control,
  name,
  title,
  getMinDate,
  getMaxDate,
  width,
  errors,
}: DateFieldProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormInputField
          type="date"
          title={title}
          field={{ ...field }}
          placeholder={`Select ${title}`}
          iD={name}
          errors={errors}
          min={getMinDate}
          max={getMaxDate}
          sx={{
            width: { width },
            "-webkit-appearance": "none",
            lineHeight: "1.5",
            verticalAlign: "middle",
            "@supports (-webkit-touch-callout: none)": {
              "& input[type='date']": {
                paddingTop: "5px",
                paddingBottom: "15px",
              },
            },
          }}
        />
      )}
    />
  );
}
