import {
  Box,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: "-6px",
  },

  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: "14px",
    width: "500px",
    maxHeight: "13px",
    padding: "14px 12px",
    color: "black",
  },
  "&::placeholder": {
    fontFamily: "mundial",
    fontSize: "8px",
  },
}));

type FormInputFieldProps = {
  title?: string;
  iD?: string;
  placeholder: string;
  errors?: any;
  sx?: any;
  multiline?: boolean;
  type?: string;
  field: any | null;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  width?: any;
  Name?: string;
  rightEndAdornment?: any;
  startAdornment?: any;
  InputProps?: any;
  min?: string;
  max?: string;
  disabled?: boolean; // Added disabled prop
};

const FormInputField = ({
  iD = "",
  placeholder,
  errors,
  title = "",
  sx,
  type,
  field,
  isDisabled = false,
  onChange,
  rightEndAdornment,
  startAdornment,
  InputProps,
  min,
  max,
  disabled, // Destructure the disabled prop
}: FormInputFieldProps) => {
  type = type === "" ? "text" : type;

  return (
    <Box my={"14px"}>
      <InputLabel
        shrink
        htmlFor={iD}
        sx={{
          fontSize: "0px",
          fontWeight: 300,
          "&.Mui-focused": {
            color: "#00000099",
          },
        }}
      >
        <Typography variant="subtitle2">
          {title}
          <span style={{ color: "red", marginLeft: "2px" }}>*</span>
        </Typography>
      </InputLabel>

      <BootstrapInput
        disabled={disabled} // Use the disabled prop
        id={iD}
        placeholder={placeholder}
        sx={
          rightEndAdornment
            ? {
                "& .MuiInputBase-input": {
                  width: "470px",
                },
              }
            : sx
        }
        type={type}
        onChange={onChange}
        InputProps={InputProps}
        startAdornment={
          rightEndAdornment && (
            <InputAdornment
              sx={{
                borderTopRightRadius: 1,
                borderBottomRightRadius: 1,
                borderTop: "1px solid #E0E3E7",
                borderLeft: "1px solid #E0E3E7",
                borderBottom: "1px solid #E0E3E7",
                maxHeight: "35px",
                padding: "10px",
                margin: "0px",
                height: " 35px",
              }}
              position="start"
            >
              <Typography variant="h4">₹</Typography>
            </InputAdornment>
          )
        }
        {...field}
        inputProps={{
          min,
          max,
        }}
      />

      {errors[iD]?.message && (
        <FormHelperText error sx={{ mt: "0", mb: "5px" }}>
          <Typography
            variant="caption"
            sx={{ color: "red", paddingLeft: 1, fontSize: "11px" }}
          >
            {errors[iD]?.message}
          </Typography>
        </FormHelperText>
      )}
    </Box>
  );
};

export default FormInputField;
