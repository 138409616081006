import {
  Box,
  Button,
  Card,
  CardContent,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import { dealReject } from "../../../../assets";

interface DealDeclinedProps {
  open: boolean;
  onClose: () => void;
  data: any;
}

const DealDeclined: React.FC<DealDeclinedProps> = ({ open, onClose, data }) => {
  // Find the rejection comment from status history
  const rejectedComment = data?.statusHistory.find(
    (item: any) =>
      item?.status === "Deal Rejected" || item?.status === "Deal Cancelled"
  )?.comment;

  const title =
    data?.statusHistory[0]?.status === "Deal Rejected"
      ? "Deal Invitation Declined!"
      : "Deal Cancelled!";

  const description =
    data?.statusHistory[0]?.status === "Deal Rejected"
      ? "We're sorry, but the seller has declined your request to accept their deal invitation."
      : "The Trustee Authority has cancelled your deal. You can view the reason provided by the Trustee for cancelling the deal. This will allow you to understand their decision and determine if you would like to revise the deal and continue the process.";

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#FFFF",
          borderRadius: "8px",
          outline: "none",
          width: "60%",
        }}
      >
        <Card
          sx={{
            border: "none",
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: "30px",
          }}
        >
          <Box sx={{ textAlign: "center", mb: "3%" }}>
            <img
              src={dealReject}
              alt="Card"
              style={{ width: "50%", objectFit: "cover" }}
            />
          </Box>

          <Typography
            variant="h4"
            id="modal-title"
            sx={{ mb: "1rem", fontWeight: "600" }}
          >
            {title}
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{ mb: "1rem", opacity: 0.7, textAlign: "center" }}
          >
            {description}
          </Typography>

          {/* Display rejection comment if available */}
          {rejectedComment && (
            <Card
              sx={{
                width: "70%",
                background: "#E3DAFB",
                borderRadius: "15px",
                paddingBottom: "10px",
                mb: "1rem",
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ mb: "0px", fontWeight: "600" }}>
                  REASONS FOR REJECTION
                </Typography>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: rejectedComment }}
                />
              </CardContent>
            </Card>
          )}

          <Button
            variant="contained"
            sx={{
              width: "auto",
              backgroundColor: "#6A1B9A",
              boxShadow: "0px 6px 16px #00000029",
              borderRadius: "8px",
              color: "#FFFFFF",
              mb: "1rem",
            }}
            onClick={onClose}
          >
            Go Back
          </Button>
        </Card>
      </Box>
    </Modal>
  );
};

export default DealDeclined;
