import CloseIcon from "@mui/icons-material/Close";
import { Grid, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getUserProfile } from "../../../../api/services";
import AcceptDealSignatoryDirectors from "../deal-components/AcceptDealSignatoryDirector";
// import { acceptDeal } from "../../../api/services/deals";
import { useNavigate } from "react-router-dom";

interface ApproveModelProps {
  open: boolean;
  onClose: () => void;
  data?: any;
  secondUser?: any;
}

const ApproveModel: React.FC<ApproveModelProps> = ({
  open,
  onClose,
  data,
  secondUser,
}) => {
  const navigate = useNavigate();

  // const { mutate: accept } = useMutation(acceptDeal, {
  //   onSuccess: (res: any) => {
  //     toast.success("Deal accepted successfully");
  //     navigate(`/deals/invitation-success/${data?.code}`);
  //   },
  //   onError: (error: any) => {
  //     toast.error(error?.response?.data?.message);
  //   },
  // });

  const { data: profiledata }: any = useQuery("getuser-id", getUserProfile);
  const [signDirector, setSignDirector] = useState();

  const userBankingInformation =
    profiledata?.data?.entities[0]?.bankingInformation[0];

  const signatoryDirectors = profiledata?.data?.entities[0]?.directors?.filter(
    (item: any) => item.isSignatoryAuthority
  );

  // const handleAccept = () => {
  //   accept({ data: { signatoryId: signDirector }, id: data?.code });
  //   onClose();
  // };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 570,
            height: 400,
            background: "#FFFFFF",
            border: "1px solid #1212121A",
            borderRadius: "15px",
            opacity: 1,
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "-40px",
              right: "0px",
              zIndex: 1,
              backgroundColor: "#FFFFFF",
              borderRadius: "50%",
              height: "25px",
              width: "25px",
            }}
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>

          <Typography my={"0px"}>Banking Information</Typography>

          <Box>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <Stack>
                  <Typography variant="subtitle2" sx={{ color: "grey" }}>
                    Bank Account Number
                  </Typography>
                  <Typography variant="h6">
                    {userBankingInformation?.accountNumber}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "14px", color: "grey" }}
                  >
                    IFSC Code
                  </Typography>
                  <Typography variant="h6">
                    {userBankingInformation?.ifscCode}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "14px", color: "grey" }}
                  >
                    Bank Name
                  </Typography>
                  <Typography variant="h6">
                    {userBankingInformation?.name}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <AcceptDealSignatoryDirectors
            directorsData={signatoryDirectors}
            setSignDirector={setSignDirector}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: 250,
              height: 50,
              background: "#7643EB",
              boxShadow: "0px 3px 6px #00000029",
              opacity: 1,
            }}
            // onClick={handleAccept}
          >
            Submit
          </Button>
        </Box>
      </>
    </Modal>
  );
};

export default ApproveModel;
