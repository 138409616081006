import { Box, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { Icons } from "../../../assets";
import DrawerButtonAction from "../../../components/buttons/DrawerButtonAction";
import DocumentsForm from "./entity-forms/DocumentsForm";

interface DocumentProps {
  data: any;
  typeData?: any;
  filetype?: any;
  typeId?: any;
  subtype?: any;
  onFileChange?: any;
  date?: any;
  isSupporting?: boolean;
}

const Document: React.FC<DocumentProps> = ({
  data,
  typeData,
  filetype = null,
  typeId = null,
  subtype,
  onFileChange,
  date,
  isSupporting = false,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [uri, setUri] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileLinkClick = () => {
    window.open(data?.url, "_blank");
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const acceptedFiles = event.target.files;
    if (acceptedFiles && acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      const fileSizeInMB = selectedFile.size / (1024 * 1024);
      if (fileSizeInMB < 50) {
        setFile(selectedFile);
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("type", typeData);
        formData.append("subType", subtype);
        formData.append("filetype", filetype);
        formData.append("typeId", typeId);
        setLoading(true);
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/media-object/file-upload`,
            formData
          )
          .then((res) => {
            setUploaded(true);
            onFileChange(res?.data?.key);
            setLoading(false);
            setUri(res?.data?.storageDetails?.location);
            toast.success("File uploaded successfully");
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error("File Upload Failed");
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          });
      } else {
        toast.error("Upload file size should be less than 50MB");
      }
    }
  };

  return (
    <>
      <Typography
        variant="caption"
        sx={{
          color: "#000000",
          opacity: 0.7,
          marginBottom: "5px",
          marginLeft: "5px",
        }}
      >
        {data?.subtype.replace(/_/g, " ")}
      </Typography>
      <Box
        sx={{
          width: "100%",
          maxWidth: "450px",
          height: "80px",
          border: "1px solid #1212121A",
          borderRadius: "10px",
          opacity: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
          background: "#FBFBFB",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          onClick={handleFileLinkClick}
        >
          <img
            height="40px"
            src={Icons.pdf}
            alt="loading....."
            style={{ cursor: "pointer" }}
          />
          <Box>
            <Typography
              variant="body1"
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {data?.originalFileName.slice(0, 30)}...
            </Typography>
            <Typography variant="caption">
              Added on Date: {moment(data?.createdAt).format("DD/MM/YYYY")}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              cursor: "pointer",
              // backgroundColor: "#F2F2F2",
              padding: "7px 10px",
              borderRadius: "5px",
              marginRight: "8px",
            }}
            onClick={handleFileLinkClick}
          >
            <img
              src={Icons.view}
              alt="View Icon"
              style={{ width: "30px", height: "30px" }}
            />
          </Box>
          {/* <Button
            component="label"
            sx={{
              cursor: "pointer",
              backgroundColor: "#F2F2F2",
              padding: "7px 10px",
              borderRadius: "5px",
              marginRight: "8px",
            }}
          >
            {loading ? <CircularProgress size={24} /> : <CloudUploadIcon/>}
            <input
              type="file"
              hidden
              ref={fileInputRef}
              onChange={handleFileUpload}
              accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg,.txt"
            />
          </Button> */}

          {subtype !== "PAYMENT_INVOICE" && (
            <DrawerButtonAction
              subtype={data?.subtype.replace(/_/g, " ")}
              title={`Edit ${data?.subtype.replace(/_/g, " ")} Document`}
              anchor={({ open }) => (
                <Box
                  onClick={open}
                  sx={{
                    cursor: "pointer",
                    padding: "10px",
                    borderRadius: "5px",
                    marginRight: "8px",
                  }}
                >
                  <img
                    src={Icons.editPlain}
                    alt={"icon"}
                    style={{ width: "30px", height: "30px" }}
                  />
                </Box>
              )}
              content={({ close }) => (
                <DocumentsForm
                  typeId={typeId}
                  subType={data?.subtype.replace(/_/g, " ")}
                  close={close}
                  data={data}
                  typeData={typeData}
                />
              )}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Document;
