import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import { getUserById, updateUser } from "../../../api/services";
import CustomInput from "../../../components/customInput/CustomInput";

interface EditUserProps {
  open: boolean;
  onClose: () => void;
  UserId: number | null;
}

interface UserData {
  name: string;
  mobileNumber: string;
  emailAddress: string;
  password: string;
}

const validationSchema = yup.object().shape({
  name: yup.string().required("User name is required"),
  mobileNumber: yup.string().required("Mobile number is required"),
  emailAddress: yup
    .string()
    .email("Invalid email address")
    .required("Email address is required"),
  password: yup.string().required("Password is required"),
});

const EditUser: React.FC<EditUserProps> = ({ open, onClose, UserId }) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UserData>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (UserId !== null) {
          const response = await getUserById(UserId);
          const UserData = response.data;
          const { password, ...otherData } = UserData;
          reset({ ...otherData, password: "" });
        }
      } catch (error) {
        console.error("Failed to fetch User data:", error);
        toast.error("Failed to fetch User data");
      }
    };

    if (open) {
      fetchUserData();
    }
  }, [open, UserId, reset]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const queryClient = useQueryClient();

  const onSubmit = async (data: UserData) => {
    try {
      if (UserId !== null) {
        // Include countryCode and role in the data
        const updatedData = { ...data, countryCode: "+91", role: "User" };
        await updateUser(UserId, updatedData);
        queryClient.invalidateQueries("getUsersData");

        onClose();
        toast.success("User updated successfully");
      }
    } catch (error) {
      console.error("Failed to update User:", error);
      toast.error("Failed to update User");
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "380px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          opacity: 1,
          borderRadius: "0px 0px",
          border: "none",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "450px",
          height: "60px",
          background: "#7643EB 0% 0% no-repeat padding-box",
          opacity: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
          padding: "0px 16px",
        }}
      >
        <Typography variant="h5" color="white">
          Edit User
        </Typography>
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ padding: "20px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* User Name */}
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="standard"
                fullWidth
                error={!!errors.name}
                sx={{ marginBottom: "20px" }}
              >
                <InputLabel shrink htmlFor="User-name-edit-input">
                  User Name
                </InputLabel>
                <CustomInput
                  id="User-name-edit-input"
                  placeholder="Enter User Name..."
                  {...field}
                />
                <FormHelperText error>
                  {errors.name && errors.name.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          {/* Mobile Number */}
          <Controller
            name="mobileNumber"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="standard"
                fullWidth
                error={!!errors.mobileNumber}
                sx={{ marginBottom: "20px" }}
              >
                <InputLabel shrink htmlFor="mobile-number-edit-input">
                  Mobile Number
                </InputLabel>
                <CustomInput
                  id="mobile-number-edit-input"
                  placeholder="Enter Mobile Number..."
                  {...field}
                />
                <FormHelperText error>
                  {errors.mobileNumber && errors.mobileNumber.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          {/* Email Address */}
          <Controller
            name="emailAddress"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="standard"
                fullWidth
                error={!!errors.emailAddress}
                sx={{ marginBottom: "20px" }}
              >
                <InputLabel shrink htmlFor="email-address-edit-input">
                  Email Address
                </InputLabel>
                <CustomInput
                  id="email-address-edit-input"
                  placeholder="Enter Email Address..."
                  {...field}
                />
                <FormHelperText error>
                  {errors.emailAddress && errors.emailAddress.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          {/* Password */}
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="standard"
                fullWidth
                error={!!errors.password}
                sx={{ marginBottom: "20px" }}
              >
                <InputLabel shrink htmlFor="password-edit-input">
                  Password
                </InputLabel>
                <CustomInput
                  id="password-edit-input"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password..."
                  {...field}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText error>
                  {errors.password && errors.password.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          {/* Submit button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "20px",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{ borderRadius: "5px" }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export default EditUser;
