import { Box, Grid, Typography } from "@mui/material";
import { formatIndianIntegers } from "../../utils/utils";

const BeneficiaryInformation = ({ data, seller, transactionLedger }: any) => {
  const bankData = seller?.entity?.bankingInformation[0];
  return (
    <Box sx={{ ml: "20px", my: "20px", mb: "40px" }}>
      <Typography variant="h4" sx={{ marginBottom: "20px" }}>
        Beneficiary Information
      </Typography>

      <Grid container spacing={5}>
        {data?.data?.companyType === "SOLE_PROPRIETORSHIP" ||
        data?.data?.companyType === "INDIVIDUAL" ? (
          <></>
        ) : (
          <Grid item xs={3} md={3} lg={3}>
            <Typography variant="caption">Beneficiary Name</Typography>
            <Typography variant="body2">{bankData?.beneficiaryName}</Typography>
          </Grid>
        )}

        <Grid item xs={3} md={3} lg={3}>
          <Typography variant="caption">Beneficiary Bank Account</Typography>
          <Typography variant="body2">{bankData?.accountNumber}</Typography>
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          <Typography variant="caption">Beneficiary IFSC Code</Typography>
          <Typography variant="body2">{bankData?.ifscCode}</Typography>
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          <Typography variant="caption">Amount</Typography>
          <Typography variant="body2">
            {}
            {" ₹ " + formatIndianIntegers(transactionLedger?.amount) || "NA"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BeneficiaryInformation;
