import { Box, Button, Stack, Typography } from "@mui/material";
import DealSignStatus from "../deal-components/DealSignStatus";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
// import { postNotify } from "../../../api/services/deals";

export default function InvitaionSent({ data, secondUser }: any) {
  // const { mutate: notify, isLoading: userLoader } = useMutation(postNotify, {
  //   onSuccess: (res: any) => {
  //     toast.success("Notification sent successfully");
  //   },
  //   onError: (error: any) => {
  //     toast.error(error?.response?.data?.message);
  //   },
  // });

  // const handleNotifySeller = () => {
  //   notify({ dealCode: data?.code });
  // };

  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Invitation Sent to the {secondUser?.userType}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          Our invitation for this deal has been successfully sent to the other
          party. We are currently awaiting for their response. You will be
          notified once they review and respond to the invitation
        </Typography>
      </Stack>
      {/* <DealSignStatus></DealSignStatus> */}
    </Box>
  );
}
