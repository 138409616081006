import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { approveRejectTransaction } from "../../api/services";
import Quill from "../../components/quill";
// import { rejectDeal } from "../../../api/services/deals"; // Adjust the path as needed

interface RejectionModalProps {
  open: boolean;
  onClose: () => void;
  data?: any;
}

const RejectModal: React.FC<RejectionModalProps> = ({
  open,
  onClose,
  data,
}) => {
  const [comment, setComment] = useState("");
  const { ledgerid } = useParams();
  const queryClient = useQueryClient();

  const { mutate: rejectDeal } = useMutation(approveRejectTransaction, {
    onSuccess: () => {
      queryClient.invalidateQueries("transactions-by-id");
      toast.success("Transaction rejected successfully");
      onClose();
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleReject = () => {
    rejectDeal({
      body: {
        isVerified: false,
        comment: comment,
      },
      id: ledgerid,
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: 500,
          background: "#FFFFFF",
          border: "1px solid #1212121A",
          borderRadius: "15px",
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: "-40px",
            right: "0px",
            zIndex: 1,
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            height: "25px",
            width: "25px",
          }}
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <Typography variant="h5">Reason for Rejection</Typography>
        <Typography variant="body2">Add reason</Typography>
        {/* <TextField
          id="reasonInput"
          placeholder="Enter your text here…"
          multiline
          rows={5}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          sx={{
            width: "100%",
            outline: "none",
            fontSize: "16px",
            borderRadius: 3,
            "&:hover": {
              border: "none",
            },
            "&:focus": {
              border: "none",
            },
          }}
        /> */}
        <Box>
          <Quill
            data={comment}
            handleChange={setComment}
            pageWidth="pageWidth"
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: 160,
            height: 40,
            background: "#7643EB",
            boxShadow: "0px 3px 6px #00000029",
          }}
          onClick={handleReject}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default RejectModal;
