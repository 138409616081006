import { ReportQuery } from "../../pages/reports/UserReport";
import { http, surePassHttp } from "../http";

export const authLogin = (data: any) => {
  return http.post("/auth/login", data);
};

export const getUserProfile = () => {
  return http.get("/users/profile");
};

export const userLocation = (body: any) => {
  return http.put("/users/location", body);
};

//Entities Api
export const getEntities = ({ queryKey }: any) => {
  return http
    .get("/entity", {
      params: queryKey[1],
    })
    .then((res) => res.data);
};

export const getEntityDetails = (id: string) => {
  return http.get(`/entity/${id}`);
};

export const updateEntityStatus = ({ id, status, reason, reqDocIds }: any) => {
  return http.put(`/entity/${id}/status`, { status, reason, reqDocIds });
};

// export const updateEntityStatus = ({
//   id,
//   status,
//   reason,
// }: {
//   id: string;
//   status: "Verified" | "Rejected";
//   reason: string;
// }) => {
//   return http.put(`/entity/${id}/status`, { status, reason });
// };

//Deals
export const getDeals = ({ queryKey }: any) => {
  return http
    .get("/deals", {
      params: queryKey[1],
    })
    .then((res) => res.data);
};

export const getDealDetails = (code: any) => {
  return http.get(`/deals/${code}`);
};

export const getDealsDataById = ({ queryKey }: any) => {
  return http.get(`/deals/${queryKey[1]}`);
};

export const getSignerStatus = ({ data }: any) => {
  return http.post(`/deals/signer-status`, data);
};

export const updateStatus = ({ data }: any) => {
  return http.put(`/deals/signer-status`, data);
};

//Trustees
export const getTrustees = () => {
  return http.get(`/users?search=&role=Trustee&limit&offset`);
};

export const createTrustee = (data: any) => {
  return http.post("/users?role=Trustee", data);
};

export const getTrusteeById = (id: number) => {
  return http.get(`/users/${id}`);
};

export const updateTrustee = (id: number, data: any) => {
  return http.put(`/users/${id}`, data);
};

export const deleteTrustee = (id: number) => {
  return http.delete(`/users/${id}`);
};

export const deleteMultipleTrustees = (ids: number[]) => {
  const deletePromises = ids.map((id) => deleteTrustee(id));
  return Promise.all(deletePromises);
};

// Admin Users
export const getAdminUsers = () => {
  return http.get(`/users?search=&role=Admin&limit&offset`);
};

export const createAdminUser = (data: any) => {
  return http.post("/users?role=Admin", data);
};

export const getAdminUserById = (id: number) => {
  return http.get(`/users/${id}`);
};

export const updateAdminUser = (id: number, data: any) => {
  return http.put(`/users/${id}`, data);
};

export const deleteAdminUser = (id: number) => {
  return http.delete(`/users/${id}`);
};

export const deleteMultipleAdminUsers = (ids: number[]) => {
  const deletePromises = ids.map((id) => deleteTrustee(id));
  return Promise.all(deletePromises);
};

// Users
export const getUsers = ({ queryKey }: any) => {
  return http.get("/users", { params: queryKey[1] });
};

export const createUser = (data: any) => {
  return http.post("/users", data);
};

export const getUserById = (id: number) => {
  return http.get(`/users/${id}`);
};

export const updateUser = (id: number, data: any) => {
  return http.put(`/users/${id}`, data);
};

export const deleteUser = (id: number) => {
  return http.delete(`/users/${id}`);
};

export const deleteMultipleUsers = (ids: number[]) => {
  const deletePromises = ids.map((id) => deleteUser(id));
  return Promise.all(deletePromises);
};

// Transactions

export const getTransactions = ({ queryKey }: any) => {
  return http
    .get(`/deal-transaction`, {
      params: queryKey[1],
    })
    .then((res) => res.data);
};

export const getTransactionsByid = ({ queryKey }: any) => {
  return http.get(`/deal-transaction/ledger/${queryKey[1]}`);
};

export const approveRejectTransaction = ({ id, body }: any) => {
  // console.log(body);
  return http.put(`/deal-transaction/${id}`, body);
};

// Extra for surepass and Deals API

export const userActivity = (body: any) => {
  return http.post("/user-activity", body);
};

export const AddCompanyDetails = (body: any) => {
  return http.post("/entity", body);
};
export const MapDocuments = (body: any) => {
  return http.post("/entity/documents", body);
};
export const MapDirectorDocuments = (data: any) => {
  return http.post(`/director/documents`, data);
};
export const AddDirectorDetails = (body: any) => {
  return http.post("/director", body);
};

export const updateDirectorDetails = ({ id, body }: any) => {
  return http.put(`/director/${id}`, body);
};
export const AddbankingInformation = (body: any) => {
  return http.post("/banking", body);
};

export const updateBankingInformation = (id: string, body: any) => {
  return http.patch(`/banking/${id}`, body);
};

export const getComapanyDetailsById = ({ queryKey }: any) => {
  return http.get(`/entity/${queryKey[1]}`);
};

export const VerifyIfscCode = (ifscCode: any) => {
  return http.get(`/banking/verify/${ifscCode}`);
};

export const getStatusbyId = ({ queryKey }: any) => {
  return http.get(`/entity/status/${queryKey[1]}`);
};

export const getFirmAddressProof = () => {
  return http.get(`/entity/address-proof`);
};

export const getDirectorAddressProof = () => {
  return http.get(`/director/address-proof`);
};

export const getEntityPreview = ({ queryKey }: any) => {
  return http.get(`/entity/preview/${queryKey[1]}`);
};

export const deleteComapanyDetailsById = (id: any) => {
  return http.delete(`/entity/${id}`);
};

export const verifyBankDetails = (body: any) => {
  return surePassHttp.post("/api/v1/bank-verification/", body);
};

export const getSignatoryAuthority = ({ entityid, body }: any) => {
  return http.put(`entity/${entityid}/signatory`, body);
};

export const surepassValidations = (body: any) => {
  return http.post(`/entity/validate`, body);
};

export const updateBankingStatus = (id: string, body: any) => {
  return http.put(`/banking/${id}/status`, body);
};

export const updateDirectorStatus = (id: string, body: any) => {
  return http.put(`/director/${id}/status`, body);
};

export const getStatistics = () => {
  return http.get("/statistics");
};

export const getReport = ({ type, startDate, endDate }: ReportQuery) => {
  return http
    .get("/statistics/report", {
      params: { type, startDate, endDate },
    })
    .then((res) => res.data);
};
