import { Box, Button, Stack, Typography } from "@mui/material";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postNotify } from "../../../../api/services/deals";

export default function DealVerificationPending({
  data,
  secondUser,
  isTrusteeSigned,
  Role,
}: any) {
  const { mutate: notify, isLoading: userLoader } = useMutation(postNotify, {
    onSuccess: (res: any) => {
      toast.success("Notification sent successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const navigate = useNavigate();

  const handleNotifySeller = () => {
    notify({ dealCode: data?.code });
  };

  const OpenDocument = () => {
    navigate(`/deals/${data?.code}/documentationSignee`);
  };

  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          {isTrusteeSigned ? (
            <>
              {Role === "Trustee"
                ? "Truupe Verification Pending"
                : "Needs your Signature"}
            </>
          ) : (
            <>
              {Role === "Trustee"
                ? "Needs your Signature"
                : "Needs other Signatures"}
            </>
          )}
        </Typography>

        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          {" "}
          {isTrusteeSigned ? (
            <>
              {Role === "Trustee"
                ? "The Deal Document is now pending final verification. Our team is in the process of reviewing the signed Documents. You will be notified once the verification is complete."
                : "Sign the Deal Document to complete the signing process."}
            </>
          ) : (
            <>
              {Role === "Trustee"
                ? "Sign the Deal Document to complete the signing process."
                : "The Buyer has published the Deal Document. and we're now waiting for the remaining signees to complete the signing process."}
            </>
          )}
          {/* The deal document has been signed by the buyer, seller, and Truupe
          representative. It is now pending final verification before the deal
          can be completed. Our team is in the process of reviewing the signed
          documents. You will be notified once the verification is complete. */}
        </Typography>
      </Stack>
      <Button
        variant="contained"
        onClick={OpenDocument}
        sx={{ width: "auto", mt: 2 }}
      >
        {isTrusteeSigned ? (
          <>{Role === "Trustee" ? "View Documentation" : "Sign Document"}</>
        ) : (
          <>{Role === "Trustee" ? "Sign Document" : "View Documentation"}</>
        )}
      </Button>

      {/* <DealSignStatus></DealSignStatus> */}
    </Box>
  );
}
