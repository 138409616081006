import { Box, Button, Grid, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { registerBeneficiary } from "../../../../api/services/deals";
import { Deal } from "../../../../interfaces/deal";

interface AddBeneficiaryProps {
  dealData: Deal;
}

const AddBeneficiary = ({ dealData }: AddBeneficiaryProps) => {
  const queryClient = useQueryClient();

  const buyerBank = dealData?.dealUsers?.find(
    (dealUser) => dealUser?.userType === "Buyer"
  )?.banking;

  const { mutate: addBeneficiary, isLoading } = useMutation(
    registerBeneficiary,
    {
      onSuccess: (res: any) => {
        toast.success(res?.data?.message);
        queryClient.invalidateQueries("deal");
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleClick = () => {
    const body = {
      dealCode: dealData?.code,
    };
    addBeneficiary(body);
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2, color: "#444" }}>
        The buyer has not yet been registered as a beneficiary for this deal.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, color: "#777" }}>
        To proceed with the refund, please add the buyer as a beneficiary. If
        you have already completed this step, please wait for a few minutes and
        kindly refresh the page to see the updated status.
      </Typography>
      <Box my={3}>
        <Typography variant="h6" sx={{ mb: 2, color: "#444" }}>
          Buyer bank account details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="caption">Beneficiary Name</Typography>
            <Typography variant="subtitle2">
              {buyerBank?.beneficiaryName}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="caption">Bank Name</Typography>
            <Typography variant="subtitle2">{buyerBank?.name}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="caption">Bank Account Number</Typography>
            <Typography variant="subtitle2">
              {buyerBank?.accountNumber}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="caption">IFSC Code</Typography>
            <Typography variant="subtitle2">{buyerBank?.ifscCode}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        disabled={isLoading}
      >
        Register Beneficiary
      </Button>
    </Box>
  );
};

export default AddBeneficiary;
