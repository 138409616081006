import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateEntityStatus } from "../../../api/services";

const ReApproval = () => {
  const { id = "" } = useParams();
  const navigate = useNavigate();

  const handleReApproval = async () => {
    try {
      await updateEntityStatus({
        id: id.toString(),
        status: "Verified",
        reason: "", // Assuming re-approval doesn't need a reason
      });
      toast.success("Entity re-approved successfully"); // Toast success message
      navigate(`/entities/entity-success/${id}`, { replace: true });
    } catch (error) {
      console.error("Failed to update status", error);
      toast.error("Failed to re-approve entity"); // Toast error message
    }
  };

  return (
    <Box>
      <h2>Reason For Rejection</h2>
      <p>{"Reason for rejection will be displayed here"}</p>
      <Button variant="contained" color="primary" onClick={handleReApproval}>
        Re-Approve
      </Button>
    </Box>
  );
};

export default ReApproval;
