import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Card, Typography } from "@mui/material";
import { addDays, format, isValid, parse } from "date-fns";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  createCoupon,
  getCouponData,
  updateCoupon,
} from "../../api/services/deals";
import DealTextareaField from "../../components/form-fields/DealTextareaField";
import FormInputField from "../../components/form-fields/FormInputField";
import SelectDropdown from "../../components/form-fields/FormInputSelect";
import CustomSwitchComponent from "../../components/form-fields/Switch";

// Validation schema using yup
const validationSchema = yup.object().shape({
  name: yup.string().required("Coupon name is required"),
  description: yup.string().required("Description is required"),
  code: yup.string().required("Coupon code is required"),
  // usesPerCoupon: yup
  //   .number()
  //   .required("Uses per coupon is required")
  //   .min(1, "Minimum 1 use is required"),
  // usesPerUser: yup
  //   .number()
  //   .required("Uses per user is required")
  //   .min(1, "Minimum 1 use is required"),
  startDate: yup.date().required("Start date is required"),
  endDate: yup
    .date()
    .required("End date is required")
    .min(yup.ref("startDate"), "End date must be after start date"),
  value: yup
    .number()
    .required("Value is required")
    .min(0, "Minimum value is 0")
    .when("type", {
      is: "FIXED_PERCENTAGE",
      then: (schema) => schema.max(100, "Maximum value is 100 for percentage"),
      otherwise: (schema) => schema, // No max limit for "FIXED_AMOUNT"
    }),
  type: yup.string().required("type is required"),
  isActive: yup.boolean(),
});

interface CouponData {
  name: string;
  description: string;
  code: string;
  isActive: boolean;
  // usesPerCoupon: number;
  // usesPerUser: number;
  startDate: string;
  endDate: string;
  type: string;
  value: number;
}

export default function AddCoupons() {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDateEnabled, setEndDateEnabled] = useState(false);
  const [existingCouponData, setExistingCouponData] =
    useState<CouponData | null>(null);
  const { couponId } = useParams();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const type = useWatch({ control, name: "type" });
  const getMinDate = () => format(new Date(), "yyyy-MM-dd");

  const parseDateSafely = (dateInput: string | Date | null | undefined) => {
    if (dateInput instanceof Date && isValid(dateInput)) {
      return dateInput;
    }
    if (typeof dateInput === "string") {
      // First, try to parse with the expected incoming API format "dd-MM-yyyy"
      let parsedDate = parse(dateInput, "dd-MM-yyyy", new Date());
      if (isValid(parsedDate)) {
        return parsedDate;
      }

      // Fall back to "yyyy-MM-dd" if necessary
      parsedDate = parse(dateInput, "yyyy-MM-dd", new Date());
      if (isValid(parsedDate)) {
        return parsedDate;
      }
    }
    return new Date(); // Default to today's date if parsing fails
  };

  const { data, isLoading, refetch } = useQuery(
    ["get-Coupons", couponId],
    () => getCouponData({ id: couponId }),
    {
      enabled: !!couponId,
      onSuccess: (data) => {
        const couponDetails = {
          ...data.data,
          startDate: format(parseDateSafely(data.data.startDate), "yyyy-MM-dd"),
          endDate: format(parseDateSafely(data.data.endDate), "yyyy-MM-dd"),
        };

        Object.entries(couponDetails).forEach(([key, value]) => {
          setValue(
            key as keyof CouponData,
            value as string | number | boolean | Date | undefined
          );
        });
      },
      onError: (error) => {
        console.error("Error fetching coupon data:", error);
      },
    }
  );

  useEffect(() => {
    if (startDate) {
      setEndDateEnabled(true);
    } else {
      setEndDateEnabled(false);
    }
  }, [startDate]);

  const { mutate: addCoupon, isLoading: userLoader } = useMutation(
    createCoupon,
    {
      onSuccess: () => {
        toast.success("Coupon saved successfully");
        setTimeout(() => navigate("/coupons"), 1000);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: editCoupon, isLoading: editLoading } = useMutation(
    updateCoupon,
    {
      onSuccess: () => {
        toast.success("Coupon updated successfully");
        setTimeout(() => navigate("/coupons"), 1000);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const onSubmit = async (data: any) => {
    console.log("Original Submitted Data:", data);

    const formattedData = {
      ...data,
      startDate: format(parseDateSafely(data.startDate), "yyyy-MM-dd"),
      endDate: format(parseDateSafely(data.endDate), "yyyy-MM-dd"),
    };

    console.log("Formatted Data for API:", formattedData);

    if (couponId) {
      editCoupon({ id: couponId, data: formattedData });
    } else {
      addCoupon(formattedData);
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: "#FFFFFF",
        border: "1px solid #1212121A",
        borderRadius: "5px",
        opacity: 1,
        padding: "20px",
        my: "20px",
        width: "70%",
        marginLeft: "15%",
        minHeight: "85vh",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Coupon Information
        </Typography>

        <Controller
          name="name"
          control={control}
          defaultValue={existingCouponData?.name || ""}
          render={({ field }) => (
            <FormInputField
              type="text"
              title="Coupon Name"
              field={{
                ...field,
                onChange: (e: any) => {
                  const value = e.target.value;
                  if (value.length <= 200) {
                    field.onChange(value);
                  }
                },
              }}
              placeholder="Enter coupon name…"
              iD={"name"}
              errors={errors}
            />
          )}
        />

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <DealTextareaField
              title="Description"
              field={field}
              placeholder="Enter Here…"
              iD="description"
              errors={errors}
            />
          )}
        />

        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <FormInputField
              type="title"
              title="Coupon Code"
              field={{
                ...field,
                onChange: (e: any) => {
                  const value = e.target.value;
                  const filteredValue = value
                    .toUpperCase()
                    .replace(/[^A-Z0-9]/g, "");
                  if (filteredValue.length <= 200) {
                    field.onChange(filteredValue);
                  }
                },
              }}
              placeholder="Enter coupon code…"
              iD={"code"}
              errors={errors}
            />
          )}
        />

        <Controller
          name="isActive"
          control={control}
          defaultValue={true}
          render={({ field: { onChange, value } }) => (
            <CustomSwitchComponent
              value={value as boolean}
              onChange={(e, checked) => onChange(checked)}
              name="isActive"
              id="isActive"
              label="Active"
            />
          )}
        />

        {/* <Controller
          name="usesPerCoupon"
          control={control}
          render={({ field }) => (
            <FormInputField
              type="title"
              title="Uses per Coupon"
              field={{
                ...field,
                onChange: (e: any) => {
                  const value = e.target.value;
                  const filteredValue = value.replace(/[^0-9]/g, "");
                  if (filteredValue.length <= 200) {
                    field.onChange(filteredValue);
                  }
                },
              }}
              placeholder="Enter Uses per Coupon…"
              iD={"usesPerCoupon"}
              errors={errors}
            />
          )}
        />

        <Controller
          name="usesPerUser"
          control={control}
          render={({ field }) => (
            <FormInputField
              type="title"
              title="Uses per User"
              field={{
                ...field,
                onChange: (e: any) => {
                  const value = e.target.value;
                  const filteredValue = value.replace(/[^0-9]/g, "");
                  if (filteredValue.length <= 200) {
                    field.onChange(filteredValue);
                  }
                },
              }}
              placeholder="Enter Uses per User…"
              iD={"usesPerUser"}
              errors={errors}
            />
          )}
        /> */}

        <Controller
          name="startDate"
          control={control}
          render={({ field }) => (
            <FormInputField
              type="date"
              title="Start Date"
              field={{
                ...field,
                onChange: (e: any) => {
                  const value = e.target.value;
                  setStartDate(value);
                  field.onChange(value);
                },
              }}
              placeholder="Enter Start Date…"
              iD={"startDate"}
              errors={errors}
              min={getMinDate()}
            />
          )}
        />

        <Controller
          name="endDate"
          control={control}
          render={({ field }) => (
            <FormInputField
              type="date"
              title="End Date"
              field={field}
              placeholder="Enter End Date…"
              iD={"endDate"}
              errors={errors}
              min={
                startDate
                  ? format(addDays(new Date(startDate), 1), "yyyy-MM-dd")
                  : ""
              }
              // disabled={!endDateEnabled}
            />
          )}
        />

        <hr
          style={{ marginTop: "2rem", marginBottom: "1rem", opacity: "0.4" }}
        />

        <Typography variant="h4" sx={{ mb: 1 }}>
          Actions
        </Typography>

        <Controller
          name="type"
          control={control}
          defaultValue="FIXED_PERCENTAGE"
          render={({ field }) => (
            <SelectDropdown
              title="type"
              field={field}
              placeholder="Select type…"
              iD={"type"}
              errors={errors}
              options={[
                { value: "FIXED_PERCENTAGE", label: "Fixed Percentage" },
                { value: "FIXED_AMOUNT", label: "Fixed Amount" },
              ]}
            />
          )}
        />
        <Controller
          name="value"
          control={control}
          render={({ field }) => (
            <FormInputField
              type="title"
              title="Value"
              field={{
                ...field,
                onChange: (e: any) => {
                  const value = e.target.value;
                  const filteredValue = value.replace(/[^0-9]/g, "");
                  field.onChange(filteredValue);
                },
              }}
              placeholder="Enter value…"
              iD={"value"}
              errors={errors}
            />
          )}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 4 }}>
          <Button
            type="submit"
            variant="contained"
            sx={{ borderRadius: "5px" }}
          >
            Save
          </Button>
        </Box>
      </form>
    </Card>
  );
}
