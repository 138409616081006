import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Link, useNavigate } from "react-router-dom";
import { Icons } from "../../assets/index";
import AccountMenu from "../menu/Menu";
import Cookies from "js-cookie";

type HeaderProps = {
  toggleDrawer: (newOpen: boolean) => () => void;
  profiledata: any;
};

function Header({ toggleDrawer, profiledata }: HeaderProps) {
  const isAdmin = Cookies.get("isAdmin") === "true";

  return (
    <AppBar
      elevation={0}
      position="static"
      sx={{
        backgroundColor: "white",
        boxShadow: "none",
        borderBottom: "1px solid #cacaca",
        borderRadius: 0,
        height: "55px",
      }}
    >
      <Toolbar
        disableGutters
        sx={{ justifyContent: "space-between", marginX: 7.5 }}
      >
        <Link to={isAdmin ? "/dashboard" : "/deals"}>
          <Box
            sx={{
              height: "45px",
              width: "100%",
            }}
          >
            <img src={Icons.truupe} alt="Truupe" style={{ height: "80%" }} />
          </Box>
        </Link>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <AccountMenu profiledata={profiledata}></AccountMenu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
