import { Box, Card, Divider, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getEntityDetails } from "../../../api/services";
import ApprovalBar from "../entity-components/ApprovalBar";
import BankingInformation from "./banking-information/BankingInformation";
import EntityDocuments from "./entity-information/EntityDocuments";
import EntityInformation from "./entity-information/EntityInformation";
import EntityTop from "./entity-information/EntityTop";
import Signatories from "./signatories-information/Signatories";
import SignatoryAuthorityDocument from "./signatories-information/SignatoryAuthorityDocument";
import SignatoryDocumentInformation from "./signatories-information/SignatoryDocumentInformation";

const EntityDetails: React.FC = () => {
  const { id = "" } = useParams();
  const { data, isLoading, isError } = useQuery(["entity", id], () =>
    getEntityDetails(id)
  );
  const [directors, setDirectors] = useState(0);
  const entityStatus = data?.data?.entityStatus;
  const reqDocIds = data?.data?.statusHistory[0]?.reqDocIds;

  useEffect(() => {
    if (data?.data?.signatoryDirectors) {
      setDirectors(data?.data?.signatoryDirectors?.length);
    }
  }, [data]);

  //   useEffect(() => {
  //     if (data?.data?.firmDocuments) {
  //       setFirmDocuments(data?.data?.firmDocuments?.length);
  //     }
  //   }, [data]);

  if (isLoading) return <LinearProgress />;
  if (isError) return <p>Error fetching data...</p>;

  return (
    <>
      <Card
        sx={{
          margin: "0 auto",
          padding: "20px",
          borderRadius: "10px",
          marginTop: "20px",
          width: "calc(100% - 40px)",
        }}
      >
        <EntityTop data={data} />
        <EntityInformation data={data} />
        <Divider sx={{ my: 3, width: "calc(100% + 40px)", ml: "-20px" }} />
        {/* <DirectorInformation data={directors} mainData={data} /> */}
        {data?.data?.companyType === "INDIVIDUAL" ? (
          <></>
        ) : (
          <>
            <Signatories
              mainData={data}
              signatoryDirectors={data?.data?.signatoryDirectors}
            />
            <Divider sx={{ my: 3, width: "calc(100% + 40px)", ml: "-20px" }} />
          </>
        )}

        <BankingInformation
          data={data}
          status={entityStatus}
          reqDocIds={reqDocIds}
        />

        <Divider sx={{ my: 3, width: "calc(100% + 40px)", ml: "-20px" }} />

        {data?.data?.companyType === "INDIVIDUAL" ? (
          <></>
        ) : (
          <>
            <EntityDocuments
              data={data?.data?.firmDocuments}
              mainData={data?.data}
              title={"Firm Documents"}
              status={entityStatus}
              reqDocIds={reqDocIds}
            />
          </>
        )}

        {data?.data?.companyType === "INDIVIDUAL" ? (
          <></>
        ) : (
          <>
            {data?.data?.supportingFirmDocuments?.length > 0 && (
              <>
                <Divider sx={{ my: 3 }} />
                <EntityDocuments
                  data={data?.data?.supportingFirmDocuments}
                  mainData={data?.data}
                  title={"Supporting Firm Documents"}
                  isSupporting={true}
                  status={entityStatus}
                  reqDocIds={reqDocIds}
                />
              </>
            )}
          </>
        )}

        <SignatoryDocumentInformation
          data={directors}
          mainData={data}
          isSupporting={false}
        />
        {Array.from({ length: directors }).map((_, index) => (
          <React.Fragment key={index}>
            <SignatoryAuthorityDocument
              key={index}
              data={data?.data?.signatoryDirectors[index]}
              currentDirectorCount={index + 1}
              isSupporting={false}
              status={entityStatus}
              reqDocIds={reqDocIds}
            />
            {index !== directors - 1 && <Divider sx={{ marginY: 2 }} />}
          </React.Fragment>
        ))}

        {data?.data?.supportingSignatoryDirectors?.some(
          (director: any) => director?.documents?.length > 0
        ) && (
          <>
            <SignatoryDocumentInformation
              data={directors}
              mainData={data}
              isSupporting={true}
            />
            {Array.from({ length: directors }).map((_, index) => (
              <React.Fragment key={index}>
                <SignatoryAuthorityDocument
                  key={index}
                  data={data?.data?.supportingSignatoryDirectors?.[index]}
                  currentDirectorCount={index + 1}
                  isSupporting={true}
                  status={entityStatus}
                  reqDocIds={reqDocIds}
                />
                {index !== directors - 1 && <Divider sx={{ marginY: 2 }} />}
              </React.Fragment>
            ))}
          </>
        )}

        <Divider sx={{ my: 3, width: "calc(100% + 40px)", ml: "-20px" }} />

        {data?.data?.entityStatus?.toLowerCase() === "verified" ||
        data?.data?.entityStatus?.toLowerCase() === "rejected" ||
        data?.data?.entityStatus?.toLowerCase() === "in_progress" ? null : (
          <Box sx={{ height: "100px" }} />
        )}
      </Card>

      {data?.data?.entityStatus === "Verified" ||
      data?.data?.entityStatus === "Verify Changes" ? null : (
        <ApprovalBar
          entityId={id}
          status={data?.data?.entityStatus?.toLowerCase()}
          reason={data?.data?.reason}
        />
      )}
    </>
  );
};

export default EntityDetails;
