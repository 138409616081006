import { InfoOutlined } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

type StatusProps = {
  variant: string;
  showTooltip?: boolean;
  tooltipText?: any;
};

const Status: React.FC<StatusProps> = ({
  variant,
  showTooltip = false,
  tooltipText = "",
}) => {
  let style: { backgroundColor: string; color: string };

  const variantcheck = variant?.toLowerCase();

  switch (variantcheck) {
    case "invitation sent":
      style = { backgroundColor: "#4CAF501A", color: "#4CAF50" };
      tooltipText =
        "Deal invitation for this deal has been sent to other party. We are currently awaiting for their response";
      break;
    case "pending":
      style = { backgroundColor: "#FFA5001A", color: "#FFA500" };
      break;
    case "service payment":
      style = { backgroundColor: "#607D8B1A", color: "#607D8B" };
      break;
    case "under verification":
      style = { backgroundColor: "#B3C4131A", color: "#B3C413" };
      break;
    case "awaiting escrow funds":
      style = { backgroundColor: "#7955481A", color: "#795548" };
      break;
    case "invitation accepted":
      style = { backgroundColor: "#00BCD41A", color: "#00BCD4" };
      tooltipText = "Deal Invitation accepted";
      break;
    case "invitation rejected":
    case "deal cancelled":
      style = { backgroundColor: "#FF57221A", color: "#FF5722" };
      tooltipText = "Deal Invitation Cancelled";
      break;
    case "signature pending":
    case "truupe signature pending":
      style = { backgroundColor: "#B8A9201A", color: "#B8A920" };
      tooltipText = "Signature Pending";
      break;
    case "needs other signatures":
      style = { backgroundColor: "#B8A9201A", color: "#B8A920" };
      // tooltipText = "Signature Pending";
      break;
    case "pending verification":
      style = { backgroundColor: "#FFC1071A", color: "#FFC107" };
      break;
    case "fee pending":
      style = { backgroundColor: "#9C27B01A", color: "#9C27B0" };
      break;
    case "buyer to pay platform fee":
      style = { backgroundColor: "#9C27B01A", color: "#9C27B0" };
      break;
    case "funds awaited":
      style = { backgroundColor: "#7955481A", color: "#795548" };
      break;
    case "funds yet to be credited":
      style = { backgroundColor: "#7955481A", color: "#795548" };
      break;
    case "funds received":
      style = { backgroundColor: "#8BC34A1A", color: "#8BC34A" };
      break;
    case "account credited":
      style = { backgroundColor: "#8BC34A1A", color: "#8BC34A" };
      break;
    case "processed":
      style = { backgroundColor: "#8BC34A1A", color: "#8BC34A" };
      break;
    case "success":
      style = { backgroundColor: "#8BC34A1A", color: "#8BC34A" };
      break;
    case "completed":
      style = { backgroundColor: "#8BC34A1A", color: "#8BC34A" };
      break;
    case "verification pending":
    case "truupe verification pending":
      style = { backgroundColor: "#FFC1071A", color: "#FFC107" };
      break;
    case "needs your signature":
      style = { backgroundColor: "#FFC1071A", color: "#FFC107" };
      break;
    case "invitation issued":
      style = { backgroundColor: "#4CAF501A", color: "#4CAF50" };
      tooltipText = "Deal Invitation Issued";
      break;
    case "deal invitation sent":
      style = { backgroundColor: "#4CAF501A", color: "#4CAF50" };
      // tooltipText = "Deal Invitation Issued";
      break;
    case "in_progress":
      style = { backgroundColor: "#B8A9201A", color: "#B8A920" };
      break;
    case "verified":
      style = { backgroundColor: "#00BCD41A", color: "#00BCD4" };
      tooltipText = "Entity Verified";
      break;
    case "deal accepted":
      style = { backgroundColor: "#00BCD41A", color: "#00BCD4" };
      tooltipText = "Deal Invitation accepted";
      break;
    case "rejected":
      style = { backgroundColor: "#FF57221A", color: "#FF5722" };
      tooltipText = tooltipText || "Deal Invitation Rejected";
      break;
    case "deal rejected":
      style = { backgroundColor: "#FF57221A", color: "#FF5722" };
      tooltipText = "Deal Invitation Rejected";
      break;
    case "deal canceled":
      style = { backgroundColor: "#F443361A", color: "#F44336" };
      break;
    case "payment request":
      style = { backgroundColor: "#607D8B1A", color: "#607D8B" };
      break;
    case "payment verification":
      style = { backgroundColor: "#B3C4131A", color: "#B3C413" };
      break;
    case "payment under verification":
      style = { backgroundColor: "#B3C4131A", color: "#B3C413" };
      break;
    case "verify payment":
      style = { backgroundColor: "#B3C4131A", color: "#B3C413" };
      break;
    case "payment completed":
      style = { backgroundColor: "#E8F5E91A", color: "#E8F5E9" };
      break;
    case "deal completed":
      style = { backgroundColor: "#2196F31A", color: "#2196F3" };
      break;
    case "verify changes":
      style = { backgroundColor: "#FFC1071A", color: "#FFC107" };
      tooltipText = "Verify New Changes";
      break;
    case "in progress":
      style = { backgroundColor: "#B3C4131A", color: "#B3C413" };
      tooltipText = "In-Progress";
      break;
    case "not created":
      style = { backgroundColor: "#7955481A", color: "#795548" };
      tooltipText = "Not-Created";
      break;
    case "kyc submitted":
      style = { backgroundColor: "#9C27B01A", color: "#9C27B0" };
      break;
    case "active":
      style = { backgroundColor: "#8BC34A1A", color: "#8BC34A" };
      tooltipText = "Active";
      break;
    case "inactive":
      style = { backgroundColor: "#F443361A", color: "#F44336" };
      tooltipText = "In-Active";
      break;
    default:
      style = { backgroundColor: "#0000001A", color: "#000000" }; // Default style if variant is not matched
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "4px",
        height: "35px",
        width: "max-content",
        px: "13px",

        ...style,
      }}
    >
      <Typography variant="body1" sx={{ color: style.color }}>
        {variant?.replace(/_/g, " ")}
      </Typography>
      {showTooltip && (
        <Tooltip title={tooltipText} arrow>
          <IconButton size="small" sx={{ color: style.color }}>
            <InfoOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

Status.propTypes = {
  variant: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
};

export default Status;
