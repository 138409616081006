import Cookies from "js-cookie";
import AdminTabs from "./AdminTabs";
import TrusteeTabs from "./TrusteeTabs";

const DashboardTabs = () => {
  const isAdmin = Cookies.get("isAdmin") === "true";

  return isAdmin ? <AdminTabs /> : <TrusteeTabs />;
};

export default DashboardTabs;
