import { Grid, Typography } from "@mui/material";
import FileUploadContainer from "../../../../components/shared/FileUploadContainer";

interface BankDetailsProps {
  bankData: any;
  mainData: any;
  reqDocIds: string;
  status: string;
}

const BankDetails = ({
  bankData,
  mainData,
  reqDocIds,
  status,
}: BankDetailsProps) => {
  const requiredDocIds = reqDocIds
    ?.split(",")
    .map((id: any) => Number(id.trim()));

  const getDocStatus = (document: any) => {
    if (requiredDocIds?.includes(document?.id)) {
      if (status === "Kyc Submitted") return "REUPLOADED";
      if (status === "Rejected") return "REJECTED";
    }
    return "";
  };

  return (
    <Grid container spacing={5}>
      {/* {mainData?.companyType === "SOLE_PROPRIETORSHIP" ||
      mainData?.companyType === "INDIVIDUAL" ? (
        <></>
      ) : (
        <Grid item xs={3} md={3} lg={3}>
          <Typography variant="caption">Beneficiary Name</Typography>
          <Typography variant="subtitle2">
            {bankData?.beneficiaryName}
          </Typography>
        </Grid>
      )} */}

      <Grid item xs={3} md={3} lg={3}>
        <Typography variant="caption">Beneficiary Name</Typography>
        <Typography variant="subtitle2">{bankData?.beneficiaryName}</Typography>
      </Grid>

      <Grid item xs={3} md={3} lg={3}>
        <Typography variant="caption">Bank Name</Typography>
        <Typography variant="subtitle2">{bankData?.name}</Typography>
      </Grid>

      <Grid item xs={3} md={3} lg={3}>
        <Typography variant="caption">Bank Account Number</Typography>
        <Typography variant="subtitle2">{bankData?.accountNumber}</Typography>
      </Grid>

      <Grid item xs={3} md={3} lg={3}>
        <Typography variant="caption">IFSC Code</Typography>
        <Typography variant="subtitle2">{bankData?.ifscCode}</Typography>
      </Grid>

      <Grid item xs={3} md={3} lg={3}>
        <Typography variant="caption">Branch Name</Typography>
        <Typography variant="subtitle2">{bankData?.branch}</Typography>
      </Grid>

      {bankData?.relatedDocs?.map((document: any, index: number) => (
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
          <FileUploadContainer
            typeId={bankData?.id}
            actions={<></>}
            data={document.subtype}
            typeData={document.type}
            name={document.originalFileName}
            url={document.url}
            subType={document.subtype}
            onFileChange={() => {}}
            isSupporting={document.isSupporting ? "true" : "false"}
            showIcons={false}
            uploaded={true}
            docStatus={getDocStatus(document)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default BankDetails;
